import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
	MessagesTitle,
	Message,
	Link,
	Gauge,
	SkeletonBlock,
	useStore,
	Button,
	Preloader,
	f7,
	Checkbox,
} from "framework7-react";
import {
	DocumentText,
	Microphone2,
	PauseCircle,
	Profile,
	Sms,
	VolumeHigh,
} from "iconsax-react";
import {
	unixTimestampToTime,
	unixTimestampToDate,
	isJson,
	formatBytes,
	isLanguageSupported,
	removeMD,
} from "../../utils/functions";
import AudioPlayer from "../misc/audio-player";
import userimage from "../../assets/images/placeholders/user.png";
import image_placeholder from "../../assets/images/placeholders/image.png";
import blank_placeholder from "../../assets/images/placeholders/blank.svg";
import MsgStatusIcon from "../misc/msgStatusIcon";
import MessageReactions from "../misc/messageReactions";
import LightboxThumbnail from "../misc/lightboxThumbnail";
import reactStringReplace from "react-string-replace";
import rehypeSanitize from "rehype-sanitize";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import TimerIcon from "../../assets/images/icons/timer_icon.svg?react";
import useSpeechSynthesis from "../../hooks/useSpeechSynthesis";
import usePoshTranslation from "../../hooks/usePoshTranslation";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "../misc/materialIcon";
import { useTranslation } from "react-i18next";
import LazyLoaderWraper from "../misc/lazyloaderWraper";
import { db } from "../../js/db";
import audioCallReceived from "../../assets/images/placeholders/audio-in-blue.svg";
import audioCallOutgoing from "../../assets/images/placeholders/audio-out-green.svg";
import audioCallIncomingMissed from "../../assets/images/placeholders/audio-in-red.svg";
import audioCallOutgoingMissed from "../../assets/images/placeholders/audio-out-block.svg";
import videoCallReceived from "../../assets/images/placeholders/video-in-blue.svg";
import videoCallOutgoing from "../../assets/images/placeholders/video-out-green.svg";
import videoCallIncomingMissed from "../../assets/images/placeholders/video-in-red.svg";
import videoCallOutgoingMissed from "../../assets/images/placeholders/video-out-block.svg";
import DownloaderDialog from "../misc/downloaderDialog";
import { makeCancellable, post } from "../../axios/axiosClient";
import { SocketContext } from "../../socket";
import { ENDPOINTS } from "../../constants/socket";
import { useLiveQuery } from "dexie-react-hooks";
import { userProfileService } from "../../redux/features/userSlice/profile";
import { BASE_URL } from "../../constants";
import useLocalStorageState from "use-local-storage-state";
import useParsedMessage from "../../hooks/useParsedMessage";

const RenderMessage = ({
	message,
	reactions,
	reactionsOpen,
	members,
	type,
	displayMenu,
	infoOpened,
	fullWidth = false,
	setSelectedMessage,
	conditionalScrollToBottom,
	callStart,
	selected = false,
	setMyExistingReactions,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const socket = useContext(SocketContext);
	const { speak, supported, speaking, cancel } = useSpeechSynthesis();
	const { translating, translate } = usePoshTranslation();
	const uploadIdentifier = useStore("uploadIdentifier");
	const uploadProgress = useStore("uploadProgress");
	const [showDownloader, setShowDownloder] = useState(false);
	const unseenMessages = useStore("unseenMessages");
	const selectedMessages = useStore("selectedMessages");
	const [messageContext, setMessageContext] =
		useLocalStorageState("messageContext");

	const colors = [
		"kt-pink",
		"kt-orange",
		"kt-yellow",
		"kt-green",
		"kt-blue",
		"kt-purple",
	];

	const CallIcons = () => {
		return {
			audioCallReceived,
			audioCallOutgoing,
			audioCallIncomingMissed,
			audioCallOutgoingMissed,
			videoCallReceived,
			videoCallOutgoing,
			videoCallIncomingMissed,
			videoCallOutgoingMissed,
		};
	};

	const chat = useLiveQuery(
		() =>
			db.chats.get({
				chat_id: Array.isArray(message) ? message[0].chat_id : message.chat_id,
			}),
		[message]
	);

	const _profile = useLiveQuery(
		() => (chat ? db.users.get({ id: chat?.user_id }) : {}),
		[message, chat],
		{}
	);

	const profile = useMemo(() => _profile, [_profile]);

	const { loginResponse } = useSelector((state) => state.loginSlice);

	var is_sent_by_me = Array.isArray(message)
		? parseInt(message[0].sender_id) == loginResponse.data.id
		: parseInt(message.sender_id) == loginResponse.data.id;

	const getMessageType = (id) => {
		return loginResponse.data.id == id ? "sent" : "received";
	};

	const messageRef = useRef(null);
	const [isIntersecting, setIsIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.5, // Adjust this threshold as needed
			}
		);
		messageRef.current?.el && observer.observe(messageRef.current?.el);

		return () => {
			messageRef.current?.el && observer.unobserve(messageRef.current?.el);
		};
	}, []);

	useEffect(() => {
		if (isIntersecting) {
			const markAsRead = (_message) => {
				if (
					Object.keys(unseenMessages).includes(_message.chat_id) &&
					unseenMessages[_message.chat_id]?.includes(_message.id)
				) {
					f7.store.dispatch("removeUnseenMessages", {
						messageId: _message.id,
						chatId: _message.chat_id,
					});
				}

				if (
					_message.sender_id !== loginResponse.data.id &&
					_message.is_read < 2
				) {
					db.chats.where({ chat_id: _message.id }).modify((ref, value) => {
						ref.value = {
							...value,
							un_read_count:
								value.un_read_count > 0 ? value.un_read_count - 1 : 0,
						};
					});
					socket.emit(
						ENDPOINTS.MESSAGE_SEEN,
						JSON.stringify({
							message_id: _message.id,
							chat_id: _message.chat_id,
							sender_id: parseInt(_message.sender_id),
						}),
						(data) => {
							console.log("socket MESSAGE_SEEN ", data);
							db.messages.where({ id: _message.id }).modify((ref, value) => {
								ref.value = { ...value, is_read: 2 };
							});
						}
					);
				}
			};

			Array.isArray(message) ? message.map(markAsRead) : markAsRead(message);
		}
	}, [isIntersecting, unseenMessages]);

	const cancelUpload = (identifier, message) => {
		makeCancellable(identifier);
		f7.store.dispatch("setUploadIdentifier", null);
		db.messages.delete(message.id).then(() => {
			db.messages
				.where({ chat_id: message.chat_id })
				.toArray()
				.then((msgs) => {
					const lastMsg = msgs[msgs.length - 1];
					db.chats.get(lastMsg.chat_id).then((chat) => {
						const chatData = {
							message: lastMsg.message,
							...(chat.type === "group" && {
								firstname: lastMsg.sender_name,
							}),
							message_sender_id: parseInt(lastMsg.sender_id),
							unix_time: lastMsg.unix_time,
							msg_type: lastMsg.type,
						};

						db.chats
							.where({ chat_id: lastMsg.chat_id })
							.modify(chatData)
							.then((id) => {
								console.log("modified chat", id);
							})
							.catch((ex) => {
								console.log(ex);
							});
					});
				});
		});
	};

	const renderGroupedMessages = (groupedMessages) => {
		const memberIndex = members?.findIndex(
			(member) => member.id === groupedMessages[0].sender_id
		);
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(groupedMessages, e);
				}}
				className={`media-message grouped-message ${
					fullWidth ? "full-width" : ""
				} ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={groupedMessages[0].group_id}
				type={getMessageType(groupedMessages[0].sender_id)}
				first={true}
				last={true}>
				{selectedMessages.length === 0 && !infoOpened && (
					<Button
						className="context-btn absolute right-1.5 top-1.5"
						onClick={(e) => displayMenu(groupedMessages, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(groupedMessages, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								type !== "single" &&
								groupedMessages[0].sender_id !== loginResponse.data.id
									? "#5D6980"
									: "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				<div
					slot="image"
					className="relative grid grid-flow-row grid-cols-2 auto-rows-[minmax(0,_2fr)] gap-1">
					{groupedMessages.map((message, index) => {
						if (index <= 3) {
							return (
								<div
									key={message.id}
									onContextMenu={(e) => {
										e.preventDefault();
										displayMenu(message, e);
									}}
									className="relative flex items-center justify-center max-w-[179px] max-h-[130px]">
									{/* {message.type === "image" && ( */}
									<LazyLoaderWraper
										id={message.id}
										src={message.thumbnail}
										placeholder={message.thumbnail}
										height={130}
										width={179}
										alt=""
										className="cursor-pointer max-h-[130px] object-cover p-0.5"
										onClick={() => {
											f7.store.dispatch("setSelectedMessageId", message.id);
											setSelectedMessage(message.id);
										}}
										onContextMenu={(e) => {
											e.preventDefault();
											displayMenu(message, e);
										}}
									/>
									{/* )} */}
									{/* {message.type === "video" && (
										<LazyLoaderWraper
											id={message.id}
											src={message.thumbnail}
											placeholder={image_placeholder}
											height={130}
											width={179}
											alt=""
											className="cursor-pointer w-full max-h-[130px] object-cover p-0.5"
											onClick={() => {
												setSelectedMessage(message.id);
												
											}}
										/>
									)} */}
									{index === 3 && groupedMessages.length > 4 && (
										<div className="absolute top-[2px] left-[2px] right-[2px] bottom-[2px]  bg-black/[0.4] rounded-[5px] flex items-center justify-center pointer-events-none">
											<span className="text-white text-[40px] font-bold">
												+{groupedMessages.length - 3}
											</span>
										</div>
									)}
								</div>
							);
						}
					})}
					{uploadIdentifier &&
						uploadIdentifier === groupedMessages[0].identifier && (
							<div
								className={`message-loader-bg bg-black/[0.25] absolute left-0 right-0 bottom-0 flex items-center justify-center ${
									type !== "single" &&
									groupedMessages[0].sender_name &&
									groupedMessages[0].sender_id !== loginResponse.data.id
										? "top-[19px]"
										: "top-0"
								}`}>
								{uploadProgress > -1 ? (
									<>
										<Gauge
											value={(uploadProgress / 100) //change this latter to calculate all files in group
												.toFixed(2)}
											size={50}
											bgColor="rgba(0,0,0,0.5)"
											borderWidth={5}
											borderBgColor="rgba(255,255,255,0.5)"
											borderColor="#0e99fe"></Gauge>
										<Button
											className="absolute w-11 h-11 rounded-full p-0"
											onClick={() => {
												cancelUpload(uploadIdentifier, message);
											}}>
											<MaterialIcon
												icon="close"
												fill
												color="white"
												size={19}
												grad={-25}
												className="text-[36px] rounded-full"
											/>
										</Button>
									</>
								) : (
									<Preloader color="#0e99fe" size={50} />
								)}
							</div>
						)}
				</div>

				{type !== "single" &&
					groupedMessages[0].sender_name &&
					groupedMessages[0].sender_id !== loginResponse.data.id && (
						<span
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold`}>
							{groupedMessages[0].sender_name}
						</span>
					)}
				<span slot="text-footer">
					{unixTimestampToTime(
						groupedMessages[groupedMessages.length - 1].unix_time
					)}{" "}
					{groupedMessages[0].sender_id === loginResponse.data.id &&
						groupedMessages[0].chat_id !== "posh_ai" && (
							<MsgStatusIcon
								status={groupedMessages[groupedMessages.length - 1].is_read}
							/>
						)}
				</span>
			</Message>
		);
	};
	function containsMarkdown(str) {
		if (message.sender_id === "posh_ai") return true;

		const markdownPatterns = [
			/[*_]{1,3}.+[*_]{1,3}/, // Bold, italic, bold italic (e.g., *text*, **text**, ***text***)
			/(^|\s)#+\s.+/, // Headers (e.g., # Header)
			/(^|\s)>\s.+/, // Blockquotes (e.g., > quote)
			// /(^|\s)(-|\+|\*)\s.+/, // Unordered lists (e.g., - item)
			// /(^|\s)\d+\.\s.+/, // Ordered lists (e.g., 1. item)
			/!\[.*\]\(.*\)/, // Images (e.g., ![alt text](url))
			/\[.*\]\(.*\)/, // Links (e.g., [link text](url))
			/```*\n[^```]*\n```*/, // Code blocks (e.g., ``` code ```)
			/`[^`]+`/, // Inline code (e.g., `code`)
		];

		return markdownPatterns.some((pattern) => pattern.test(str));
	}

	const parsedMessage = useParsedMessage(
		message.type === "text" ? message.message : message.caption,
		members,
		containsMarkdown
	);

	const processMMS = (data) => {
		try {
			if (!isJson(data)) return data;
			data = JSON.parse(data);
			const files = data.files || [];
			let parsedString = data.message.trim();
			files.map((file, index) => {
				if (file.type === "image") {
					parsedString = reactStringReplace(parsedString, file.tag, (match) => {
						return (
							<LazyLoaderWraper
								key={"mms" + message.id + index}
								src={file.url}
								placeholder={image_placeholder}
								alt=""
								className="cursor-pointer rounded-md max-h-[170px] object-cover p-0.5"
								onClick={() => {
									f7.store.dispatch("setSelectedMessageId", message.id);
									setSelectedMessage(message.id);
								}}
							/>
						);
					});
				} else if (file.type === "video") {
					parsedString = reactStringReplace(parsedString, file.tag, (match) => {
						return (
							<LazyLoaderWraper
								src={file.url}
								placeholder={image_placeholder}
								height={130}
								width={179}
								alt=""
								className="cursor-pointer max-h-[130px] object-cover p-0.5"
								onClick={() => {
									f7.store.dispatch("setSelectedMessageId", message.id);
									setSelectedMessage(message.id);
								}}
							/>
						);
					});
				}
			});

			for (var index in parsedString) {
				if (
					typeof parsedString[index] === "string" &&
					containsMarkdown(parsedString[index])
				) {
					parsedString[index] = reactStringReplace(
						parsedString,
						parsedString[index],
						(match) => {
							return (
								<MarkdownPreview
									source={match}
									rehypePlugins={[rehypeSanitize]}
								/>
							);
						}
					);
				}
			}

			return parsedString;
		} catch (error) {
			console.log("mms parse error", error);
			return data;
		}
	};
	const repliedMessage = Array.isArray(message)
		? message[0].reply_id && message[0].reply && isJson(message[0].reply)
			? JSON.parse(message[0].reply)
			: false
		: message.reply_id && message.reply && isJson(message.reply)
		? JSON.parse(message.reply)
		: false;
	if (message instanceof Array) {
		return renderGroupedMessages(message);
	}

	let metaData =
		message.preview && isJson(message.preview)
			? JSON.parse(message.preview)
			: null;
	if (metaData) {
		metaData.url = metaData.url || metaData.link;
	}

	let storyData =
		message.story && isJson(message.story) ? JSON.parse(message.story) : null;
	if (storyData) {
		storyData.thumbnail = storyData.thumbnail || storyData.file_url;
	}

	const memberIndex = members?.findIndex(
		(member) => member.id === parseInt(message.sender_id)
	);
	const replyToMember =
		repliedMessage.sender_id === loginResponse.data.id
			? "You"
			: repliedMessage.sender_name;

	const hasReaction = useMemo(
		() =>
			reactions
				? Object.values(reactions).some(
						(value) => value === 1 || value.toString().length > 1
				  )
				: null,
		[reactions]
	);
	const countReaction = useMemo(
		() =>
			reactions
				? Object.values(reactions).reduce((total, value) => {
						if (typeof value === "number") return total + parseInt(value);
						else return total;
				  }, 0)
				: 0,
		[reactions]
	);

	useEffect(() => {
		message.id === 63578821 && console.log("reactionsChange", hasReaction, countReaction, reactions);
	}, [hasReaction, countReaction, reactions, message.id]);

	const handleSenderNameClick = () => {
		const member = members.find(
			(member) => member.id === parseInt(message.sender_id)
		);
		console.log("testinghere", member);
		if (member) {
			f7?.emit("openMemberPopup", member);
		}
	};

	const handleTagname = (message) => {
		const mentionRegex = /@\[([^\]]+)\]\((\d+)\)/g; // Matches @[name](id)
		let parsedString = message.message.replace(
			mentionRegex,
			(match, name, id) => {
				return `@${id}`; // Replaces @[name](id) with @id
			}
		);

		parsedString = reactStringReplace(parsedString, /\s@(\d+)/g, (match, i) => {
			const member = members?.find((member) => member.id === parseInt(match));
			if (member) {
				return (
					<Link
						key={match + i}
						href="#"
						className="text-primary"
						onClick={() => {
							dispatch(
								userProfileService({
									user_id: parseInt(match),
								})
							);
							f7.store.dispatch("setSelectedUserId", parseInt(match));
						}}>
						{`@${member.nickname || member.firstname + " " + member.lastname}`}
					</Link>
				);
			}
			return match; // Return the original match if member not found
		});

		return parsedString;
	};

	if (message.type === "text") {
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`${metaData ? "media-message" : "text-message"} ${
					selected ? "selected" : ""
				} ${selectedMessages.length > 0 ? "message-padding" : ""}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				// image={message.image}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className="context-btn absolute right-1.5 top-1.5"
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								(!repliedMessage && !metaData) ||
								(type !== "single" && !is_sent_by_me)
									? "#5D6980"
									: "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" && message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => {
									setMessageContext(message);
								}}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							slot="bubble-start"
							className={`message-sender-name cursor-pointer text-${
								colors[memberIndex % colors.length]
							} font-bold username`}
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.story && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Replied to story")}
					</span>
				)}
				{storyData && (
					<div
						slot="image"
						className="relative max-h-[345px]  highlight cursor-pointer items-center justify-between p-0 m-0"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<div className="top-corner-overlay"></div>
						<div
							className={`max-w-[345px] max-h-[345px] flex ${
								(storyData.type === "image") === "banner"
									? "flex-col justify-start items-start"
									: "justify-center items-center"
							} bg-cover bg-center `}>
							{storyData.type === "image" && (
								<LazyLoaderWraper
									src={storyData.thumbnail}
									placeholder={blank_placeholder}
									height={storyData.thumbnail === "banner" ? 350 : ""}
									width={storyData.thumbnail === "banner" ? 350 : ""}
									className={`object-cover ${
										(storyData.type === "image") === "favicon"
											? "ml-1 p-0 bg-[#e7e7e7]"
											: ""
									}`}
									onClick={() => {
										if (!storyData.id) {
											return;
										}
										f7.tab.show("#storiesTab");
										f7.views.main.router.navigate(
											`/stories/${storyData.id}/stories/:id`
										);
										console.log("storyData.id", storyData.id);

										post("/user/profile", {
											type: "application/x-www-form-urlencoded",
											// ID REQUIRED FOR GET USER INFO FROM API
											data: storyData.user_id,
										}).then((e) => console.log(e));
									}}
								/>
							)}
						</div>
						{storyData.type === "text" && (
							<div
								style={{
									backgroundColor: storyData.color || "",
									padding: "10px",
									borderRadius: "10px",
								}}>
								{storyData.message && (
									<p
										className="text-[#f8f8f8] flex justify-center"
										onClick={() => {
											if (!storyData.id) {
												return;
											}
											f7.tab.show("#storiesTab");
											f7.views.main.router.navigate(
												`/stories/${storyData.id}/stories/:id`
											);
											console.log("storyData.id", storyData.id);

											post("/user/profile", {
												type: "application/x-www-form-urlencoded",
												// ID REQUIRED FOR GET USER INFO FROM API
												data: storyData.user_id,
											}).then((e) => console.log(e));
										}}>
										{storyData.message}
									</p>
								)}
							</div>
						)}
						<div
							className={`max-w-[345px] max-h-[345px] flex ${
								(storyData.type === "image") === "banner"
									? "flex-col justify-start items-start"
									: "justify-center items-center"
							} bg-cover bg-center bg-transparent`}>
							{storyData.type === "video" && (
								<LazyLoaderWraper
									src={storyData.thumbnail}
									placeholder={storyData.thumbnail}
									height={250}
									width={250}
									className={`object-cover ${
										(storyData.type === "image") === "favicon"
											? "ml-1 p-0 bg-[#e7e7e7]"
											: ""
									}`}
									onClick={() => {
										if (!storyData.id) {
											return;
										}
										f7.tab.show("#storiesTab");
										f7.views.main.router.navigate(
											`/stories/${storyData.id}/stories/:id`
										);
										console.log("storyData.id", storyData.id);

										post("/user/profile", {
											type: "application/x-www-form-urlencoded",
											// ID REQUIRED FOR GET USER INFO FROM API
											data: storyData.user_id,
										}).then((e) => console.log(e));
									}}
								/>
							)}
						</div>
						{/* {storyData.type === "video" && (
							<p className=" flex justify-center bg-white">
								{storyData.firstname}
							</p>
						)} */}
					</div>
				)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{metaData && (
					<div
						slot="image"
						className="relative max-h-[345px] flex highlight cursor-pointer items-center justify-between p-0 m-0"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() =>
							window.open(metaData.url, "_blank", "noopener noreferrer")
						}>
						<div className="top-corner-overlay"></div>
						<div
							className={`max-w-[345px] max-h-[345px] flex ${
								metaData.imgType === "banner"
									? "flex-col justify-start items-start"
									: "justify-center items-center"
							} bg-cover bg-center bg-transparent`}>
							{metaData.image && (
								<LazyLoaderWraper
									src={metaData.image}
									placeholder={blank_placeholder}
									height={metaData.imgType === "banner" ? 215 : 50}
									width={metaData.imgType === "banner" ? 345 : 50}
									className={`object-cover ${
										metaData.imgType === "favicon"
											? "ml-1 p-0 bg-[#e7e7e7]"
											: ""
									}`}
								/>
							)}
							<div
								className={`flex flex-col items-start justify-start p-2.5 gap-[2px]`}>
								<span className="text-sm leading-[19px] w-full  color-[#302F36] url-meta-info truncate max-w-[300px]">
									{metaData.title}
								</span>
								<span className="text-xs color-[#5D6980] url-meta-info text-justify">
									{metaData.description}
								</span>
								<span className="text-xs color-[#5D6980] url-meta-info">
									{new URL(metaData.url).hostname}
								</span>
							</div>
						</div>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => {
							f7.emit("searchMessage", repliedMessage.id, false);
						}}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : ""
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : repliedMessage.sender_name &&
							  repliedMessage.sender_id !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs username`}>
									{repliedMessage.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type === "audio" && (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								{repliedMessage.type === "contact" && (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								{repliedMessage.type === "mms" && (
									<Sms
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								{repliedMessage.type !== "text" &&
									repliedMessage.type !== "contact" &&
									repliedMessage.type !== "audio" &&
									repliedMessage.type !== "mms" && (
										<DocumentText
											size="15"
											color="#5D6980"
											variant="Bold"
											className="inline-block align-middle mr-[2px] -mt-[4px]"
										/>
									)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text" &&
											repliedMessage?.type !== "mms"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.type === "mms"
											? isJson(repliedMessage.message) &&
											  JSON.parse(repliedMessage?.message).message
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" &&
							repliedMessage?.type !== "mms" && (
								<div
									className={`w-[88px] h-[69px] rounded-r-[10px] ${
										repliedMessage.type !== "image" &&
										repliedMessage.type !== "video"
											? "pr-[10px]"
											: null
									}`}>
									{repliedMessage?.type !== "contact" &&
									repliedMessage?.type !== "audio" &&
									repliedMessage?.type !== "location" ? (
										<LightboxThumbnail
											item={repliedMessage}
											className={`${
												repliedMessage.type !== "image" &&
												repliedMessage.type !== "video"
													? "scale-[1.4] mt-[18px]"
													: "w-[88px] h-[69px] rounded-r-[10px]"
											}`}
										/>
									) : repliedMessage?.type === "contact" ? (
										<div className="flex flex-col items-end justify-center h-full w-full">
											<LazyLoaderWraper
												src={userimage}
												placeholder={userimage}
												height={45}
												width={45}
												alt=""
												className="rounded-full"
												wrapperclassname="rounded-full"
											/>
										</div>
									) : repliedMessage?.type === "audio" ? (
										<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px] float: right" />
									) : repliedMessage?.type === "location" ? (
										<MapContainer
											zoomControl={false}
											dragging={false}
											keyboard={false}
											scrollWheelZoom={false}
											center={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}
											zoom={20}
											placeholder={
												<SkeletonBlock effect="wave"></SkeletonBlock>
											}>
											<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
											<Marker
												position={repliedMessage.original_message
													.split("...")
													.map((coord) => parseFloat(coord.trim()))}></Marker>
										</MapContainer>
									) : null}
								</div>
							)}
					</div>
				)}
				{message.message && (
					<span
						slot="text"
						className={`${metaData ? "pl-2.5" : ""}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => handleTagname(message)}>
						{parsedMessage}
					</span>
				)}

				<div
					slot="text-footer"
					className={`${metaData ? "pl-2.5 pt-1" : ""}`}
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{supported &&
						speaking !== message.id &&
						isLanguageSupported(message.language) && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={() => {
									speak({
										text: message.message,
										id: message.id,
										lang: message.language,
									});
								}}>
								<VolumeHigh
									size={18}
									color="#0E99FE"
									className="align-text-bottom cursor-pointer"
								/>
							</Button>
						)}
					{speaking && speaking === message.id && (
						<Button
							className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
							onClick={cancel}>
							<PauseCircle
								size={18}
								color="#0E99FE"
								className="align-text-bottom  cursor-pointer"
							/>
						</Button>
					)}
					{loginResponse.data.language && loginResponse.data.language != message.language &&
						!translating.includes(message.id) && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									translate(message, (response) => {
										db.translatedMessages.put({
											...message,
											message: response.translated_message,
											original_language: message.language,
											language: loginResponse.data.language,
										});
									});
								}}>
								{t("View Translated")}
							</span>
						)}
					{translating.includes(message.id) && (
						<span className="inline-block cursor-pointer select-none">
							{t("Translating...")}
						</span>
					)}
					{message.original_language && (
						<span
							className="inline-block cursor-pointer underline select-none"
							onClick={() => {
								db.translatedMessages.delete(message.id);
							}}>
							{t("View Original")}
						</span>
					)}
				</div>
				<span
					slot="text-footer"
					className={`${metaData ? "pr-2.5 pt-1" : ""}`}
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{message.edited ? (
						<MaterialIcon
							icon="edit"
							color="#302f36"
							size={18}
							weight={300}
							className="text-[14px] mr-1 mt-0 align-sub"
						/>
					) : null}
					<span>{unixTimestampToTime(message.unix_time)}</span>
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "member" && isJson(message.message)) {
		var msgjson = JSON.parse(message.message);
		if (msgjson.type === "create")
			return (
				<MessagesTitle
					id={message.id}
					className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						{msgjson.id == loginResponse.data.id ? t("You") : msgjson.firstname}{" "}
						{t("created this group")}
					</div>
				</MessagesTitle>
			);
		if (msgjson.type === "remove")
			return (
				<MessagesTitle
					id={message.id}
					className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						{parseInt(message.sender_id) == loginResponse.data.id
							? t("You")
							: message.sender_name}{" "}
						{t("removed")}{" "}
						{msgjson?.id == loginResponse.data.id
							? t("You")
							: msgjson.firstname}
					</div>
				</MessagesTitle>
			);
		if (msgjson.type === "add")
			return (
				<MessagesTitle
					id={message.id}
					className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						{parseInt(message.sender_id) == loginResponse.data.id
							? t("You")
							: message.sender_name}{" "}
						{t("added")}{" "}
						{msgjson?.id == loginResponse.data.id
							? t("You")
							: msgjson.firstname}
					</div>
				</MessagesTitle>
			);
		if (msgjson.type === "left")
			return (
				<MessagesTitle
					id={message.id}
					className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						{msgjson?.id == loginResponse.data.id
							? t("You")
							: msgjson.firstname}{" "}
						{t("left from the group")}
					</div>
				</MessagesTitle>
			);
	} else if (message.type === "image") {
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`media-message ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className={`context-btn absolute right-1.5  z-[1111] ${
							message.forwarded == 1 ? " icon_postion" : ""
						}`}
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								type !== "single" && !is_sent_by_me ? "#5D6980" : "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				<div
					slot="image"
					className="relative max-h-[345px]"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{type === "single" && <div className="top-corner-overlay"></div>}
					{type !== "single" && is_sent_by_me && (
						<div className="top-corner-overlay"></div>
					)}
					<LazyLoaderWraper
						src={message.audio_url}
						placeholder={image_placeholder}
						height={345}
						width={345}
						alt=""
						className="cursor-pointer"
						onClick={() => {
							f7.store.dispatch("setSelectedMessageId", message.id);
							setSelectedMessage(message.id);
						}}
					/>
					<div className="bottom-overlay"></div>
					{uploadIdentifier && uploadIdentifier === message.identifier && (
						<div
							className={`message-loader-bg bg-black/[0.25] absolute left-0 right-0 bottom-0 flex items-center justify-center ${
								type !== "single" &&
								message.sender_name &&
								parseInt(message.sender_id) !== loginResponse.data.id
									? "top-[19px]"
									: "top-0"
							}`}>
							{uploadProgress > -1 ? (
								<>
									<Gauge
										value={(uploadProgress / 100).toFixed(2)}
										size={50}
										bgColor="rgba(0,0,0,0.5)"
										borderWidth={5}
										borderBgColor="rgba(255,255,255,0.5)"
										borderColor="#0e99fe"></Gauge>
									<Button
										className="absolute w-11 h-11 rounded-full p-0"
										onClick={() => {
											cancelUpload(uploadIdentifier, message);
										}}>
										<MaterialIcon
											icon="close"
											fill
											color="white"
											size={19}
											grad={-25}
											className="text-[36px] rounded-full"
										/>
									</Button>
								</>
							) : (
								<Preloader color="#0e99fe" size={50} />
							)}
						</div>
					)}
				</div>
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				{message.caption && (
					<span
						slot="text"
						className="pl-[12px]"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{parsedMessage}
					</span>
				)}
				{message.caption && (
					<div
						slot="text-footer"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{supported &&
							speaking !== message.id &&
							isLanguageSupported(message.language) && (
								<Button
									className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
									onClick={() => {
										speak({
											text: message.caption,
											id: message.id,
											lang: message.language,
										});
									}}>
									<VolumeHigh
										size={18}
										color="#0E99FE"
										className="align-text-bottom cursor-pointer"
									/>
								</Button>
							)}
						{speaking && speaking === message.id && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={cancel}>
								<PauseCircle
									size={18}
									color="#0E99FE"
									className="align-text-bottom  cursor-pointer"
								/>
							</Button>
						)}
						{loginResponse.data.language &&
							loginResponse.data.language != message.language &&
							!translating.includes(message.id) && (
								<span
									className="inline-block cursor-pointer underline select-none"
									onClick={() => {
										translate(message, (response) => {
											db.translatedMessages.put({
												...message,
												caption: response.translated_message,
												original_language: message.language,
												language: loginResponse.data.language,
											});
										});
									}}>
									{t("View Translated")}
								</span>
							)}
						{translating.includes(message.id) && (
							<span className="inline-block cursor-pointer select-none">
								{t("Translating...")}
							</span>
						)}
						{message.original_language && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									db.translatedMessages.delete(message.id);
								}}>
								{t("View Original")}
							</span>
						)}
					</div>
				)}
				<span
					className={`image_span ${
						message.forwarded == 1 ? " forwardedbox" : ""
					}`}
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "video") {
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`media-message ${
					message.caption ? "message-with-caption" : null
				} ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className={`context-btn absolute right-1.5  z-[1111] ${
							message.forwarded == 1 ? " icon_postion" : ""
						}`}
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								type !== "single" && !is_sent_by_me ? "#5D6980" : "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				<div
					slot="image"
					className="relative max-h-[345px]"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{type === "single" && <div className="top-corner-overlay"></div>}
					{type !== "single" && is_sent_by_me && (
						<div className="top-corner-overlay"></div>
					)}
					<LazyLoaderWraper
						src={message.thumbnail}
						placeholder={image_placeholder}
						height={345}
						width={345}
						alt=""
						className="cursor-pointer"
						onClick={() => {
							setSelectedMessage(message.id);
						}}
					/>
					<div className="bottom-overlay"></div>
					<div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
						<Button
							className="w-full h-full rounded-none"
							onClick={() => {
								setSelectedMessage(message.id);
							}}>
							<MaterialIcon
								fill
								icon="play_arrow"
								size={24}
								color="#FFF"
								className="text-[78px]"
							/>
						</Button>
					</div>
					{uploadIdentifier && uploadIdentifier === message.identifier && (
						<div
							className={`message-loader-bg bg-black/[0.25] absolute left-0 right-0 bottom-0 flex items-center justify-center ${
								type !== "single" &&
								message.sender_name &&
								parseInt(message.sender_id) !== loginResponse.data.id
									? "top-[19px]"
									: "top-0"
							}`}>
							{uploadProgress > -1 ? (
								<>
									<Gauge
										value={(uploadProgress / 100).toFixed(2)}
										size={50}
										bgColor="rgba(0,0,0,0.5)"
										borderWidth={5}
										borderBgColor="rgba(255,255,255,0.5)"
										borderColor="#0e99fe"></Gauge>
									<Button
										className="absolute w-11 h-11 rounded-full p-0"
										onClick={() => {
											cancelUpload(uploadIdentifier, message);
										}}>
										<MaterialIcon
											icon="close"
											fill
											color="white"
											size={19}
											grad={-25}
											className="text-[36px] rounded-full"
										/>
									</Button>
								</>
							) : (
								<Preloader color="#0e99fe" size={50} />
							)}
						</div>
					)}
				</div>
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				{message.caption && (
					<span
						slot="text"
						className="pl-[12px]"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{parsedMessage}
					</span>
				)}
				{message.caption && (
					<div
						slot="text-footer"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{supported &&
							speaking !== message.id &&
							isLanguageSupported(message.language) && (
								<Button
									className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
									onClick={() => {
										speak({
											text: message.caption,
											id: message.id,
											lang: message.language,
										});
									}}>
									<VolumeHigh
										size={18}
										color="#0E99FE"
										className="align-text-bottom cursor-pointer"
									/>
								</Button>
							)}
						{speaking && speaking === message.id && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={cancel}>
								<PauseCircle
									size={18}
									color="#0E99FE"
									className="align-text-bottom  cursor-pointer"
								/>
							</Button>
						)}
						{loginResponse.data.language &&
							loginResponse.data.language != message.language &&
							!translating.includes(message.id) && (
								<span
									className="inline-block cursor-pointer underline select-none"
									onClick={() => {
										translate(message, (response) => {
											db.translatedMessages.put({
												...message,
												caption: response.translated_message,
												original_language: message.language,
												language: loginResponse.data.language,
											});
										});
									}}>
									{t("View Translated")}
								</span>
							)}
						{translating.includes(message.id) && (
							<span className="inline-block cursor-pointer select-none">
								{t("Translating...")}
							</span>
						)}
						{message.original_language && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									db.translatedMessages.delete(message.id);
								}}>
								{t("View Original")}
							</span>
						)}
					</div>
				)}
				<span
					className={`image_span ${
						message.forwarded == 1 ? " forwardedbox" : ""
					}`}
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "contact" && isJson(message.message)) {
		let msgjson = JSON.parse(message.message);
		let name = msgjson.name || msgjson.contactName;
		let number = "";
		if (msgjson.contactTypeInfoList) {
			const contactInfo = msgjson.contactTypeInfoList
				.map((info) => info.phoneNumber || info.email)
				.filter((value) => value);
			number = contactInfo.length > 0 ? contactInfo[0] : "";
		} else {
			number = msgjson.phone;
		}
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`contactlist ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className="context-btn absolute right-1.5 top-1.5"
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon icon="expand_more" size={22} color="#5D6980" />
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				<div
					slot="bubble-start"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}
					className="flex flex-col highlight px-0 pb-0">
					<div className="flex gap-[15px] items-center cursor-pointer px-[15px] pb-[10px]">
						<LazyLoaderWraper
							src={userimage}
							placeholder={userimage}
							height={45}
							width={45}
							alt=""
							className="rounded-full"
							wrapperclassname="rounded-full"
						/>
						<div className="d-flex flex-col">
							<p className="text-body text-justify font-bold text-base leading-5">
								{name}
							</p>
							<p className="text-body font-normal leading-5 text-sm">
								{number}
							</p>
						</div>
					</div>
					<div
						className={`flex justify-evenly border-t ${
							parseInt(message.sender_id) !== loginResponse.data.id
								? "border-white"
								: "border-[--f7-message-sent-bg-color]"
						}`}>
						<Button
							text="Message"
							className="h-[35px] text-body w-1/2 text-base font-semibold"></Button>
						<span
							className={`border-r h-[35px] w-[1px] ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "border-white"
									: "border-[--f7-message-sent-bg-color]"
							}`}></span>
						<Button
							text="Add Contact"
							className="h-[35px] text-body w-1/2 text-base font-semibold"></Button>
					</div>
				</div>
				<span
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "audio") {
		return (
			<Message
				ref={messageRef}
				id={message.id}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`audioplay ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className="context-btn absolute right-1.5 top-1.5"
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon icon="expand_more" size={22} color="#5D6980" />
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				<span
					slot="text"
					className="mt-[10px] px-[12px]"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					<AudioPlayer AudioURL={message.audio_url} />
				</span>
				<span
					slot="text-footer"
					className="mt-[-20px] pr-[8px]"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "call") {
		var is_missed_call = !message.call_duration;
		var is_audio_call = message.message == "voice call";
		var is_video_call = message.message == "video call";
		var validColor =
			parseInt(message.sender_id) === loginResponse.data.id
				? "bg-sentColor"
				: "bg-receivedColor";
		var call_icon = "";
		var call_text = "";
		var call_duration_text = "";

		if (is_audio_call && is_missed_call) {
			if (is_sent_by_me) {
				call_icon = CallIcons().audioCallOutgoingMissed;
				call_text = t("Voice call");
			} else {
				call_icon = CallIcons().audioCallIncomingMissed;
				call_text = t(`Missed audio call`);
			}
		} else if (is_audio_call && !is_missed_call) {
			if (is_sent_by_me) {
				call_icon = CallIcons().audioCallOutgoing;
				call_text = t(`audio call`);
			} else {
				call_icon = CallIcons().audioCallReceived;
				call_text = t(`audio call`);
			}
		}

		if (is_video_call && is_missed_call) {
			if (is_sent_by_me) {
				call_icon = CallIcons().videoCallOutgoingMissed;
				call_text = t("Video call");
			} else {
				call_icon = CallIcons().videoCallIncomingMissed;
				call_text = t(`Missed audio call`);
			}
		} else if (is_video_call && !is_missed_call) {
			if (is_sent_by_me) {
				call_icon = CallIcons().videoCallOutgoing;
				call_text = t(`Video call`);
			} else {
				call_icon = CallIcons().videoCallReceived;
				call_text = t(`Video call`);
			}
		}

		// // Set call_duration_text based on call_duration and sender
		if (!message.call_duration || message.call_duration === 0) {
			if (is_sent_by_me) {
				call_duration_text = t(`No answer`);
			} else {
				call_duration_text = t(`Tap to call back`);
			}
		}
		// else {
		// 	call_duration_text = `${message.call_duration} secs`;
		// }

		return (
			<>
				<Message
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}
					className={`callMessage ${selected ? "selected" : ""} ${
						selectedMessages.length > 0 ? "message-padding" : ""
					}`}
					id={message.id}
					type={getMessageType(parseInt(message.sender_id))}
					first={true}
					last={true}>
					{!infoOpened && selectedMessages.length === 0 && (
						<Button
							className="context-btn absolute right-1.5 top-1.5"
							onClick={(e) => displayMenu(message, e)}
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}>
							<MaterialIcon
								icon="expand_more"
								size={22}
								color={
									(!repliedMessage && !metaData) ||
									(type !== "single" && !is_sent_by_me)
										? "#5D6980"
										: "#FFFFFF"
								}
							/>
						</Button>
					)}
					{selectedMessages.length > 0 && (
						<div
							slot="start"
							className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
							<Checkbox checked={selected} />
						</div>
					)}
					{selectedMessages.length === 0 &&
						message.chat_id !== "posh_ai" &&
						message.chat_id !== 11 &&
						!infoOpened && (
							<div
								slot="content-start"
								className={`flex justify-center items-center ${
									hasReaction ? "h-[calc(100%-21px)]" : "h-full"
								} w-auto absolute ${
									parseInt(message.sender_id) !== loginResponse.data.id
										? "-right-[37px]"
										: "-left-[37px]"
								}`}>
								<Button
									round
									small
									className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
										message.id === messageContext?.id &&
										reactionsOpen &&
										"force-visible-flex"
									}`}
									{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
									popoverClose={reactionsOpen}
									onClick={() => {
										setMessageContext(message);
									}}>
									<MaterialIcon
										fill
										icon="sentiment_satisfied"
										size={22}
										color="#FFF"
									/>
								</Button>
							</div>
						)}
					{type !== "single" &&
						message.sender_name &&
						parseInt(message.sender_id) !== loginResponse.data.id && (
							<span
								slot="bubble-start"
								className={`message-sender-name cursor-pointer text-${
									colors[memberIndex % colors.length]
								} font-bold username`}
								onContextMenu={(e) => {
									e.preventDefault();
									displayMenu(message, e);
								}}
								onClick={handleSenderNameClick}>
								{message.sender_name}
							</span>
						)}

					<div
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						className={`flex ${validColor} ${
							message.call_duration === 0 && !is_sent_by_me
								? "cursor-pointer"
								: ""
						}`}
						onClick={() => {
							if (message.call_duration === 0 && !is_sent_by_me) {
								callStart(is_audio_call ? "audio" : "video", chat.type);
								f7.popup.open(".callscreen-popup");
							}
						}}>
						<LazyLoaderWraper
							className="w-[30px] h-[30px]"
							src={call_icon}
							alt={call_text}
						/>{" "}
						&nbsp; &nbsp;
						<div className="flex flex-col">
							<span className="text-sm font-medium leading-[19.1px] text-left text-[#302F36]">
								{call_text}
							</span>
							<p className="text-[10px] font-normal leading-[15px] text-left text-[#5D6980]">
								{call_duration_text}
							</p>

							<span
								className="timespan w-[60px] h-[15px] gap-0 opacity-[0px] text-[11px] font-normal leading-[15px] text-right text-[#616163] absolute right-[10px] bottom-[10px]"
								slot="text-footer">
								{unixTimestampToTime(message.unix_time)}
							</span>
						</div>
					</div>

					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				</Message>
			</>
		);
	} else if (message.type === "document") {
		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`media-message ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className={`context-btn absolute right-1.5  z-[1111] ${
							message.forwarded == 1 ? " icon_postion" : ""
						}`}
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								type !== "single" && !is_sent_by_me ? "#5D6980" : "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				<div
					slot="image"
					className="relative"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{type === "single" && (
						<div
							className={`top-corner-overlay ${
								!message.thumbnail ? "no-thumb" : null
							}`}></div>
					)}
					{type !== "single" && is_sent_by_me && (
						<div
							className={`top-corner-overlay ${
								!message.thumbnail ? "no-thumb" : null
							}`}></div>
					)}
					{message.thumbnail && (
						<img
							src={message.thumbnail}
							className="cursor-pointer h-[215px] w-[380px] bg-white"
							onClick={() => {
								window.open(message.audio_url, "_blank");
								f7.store.dispatch("setSelectedMessageId", message.id);
							}}
						/>
					)}
					<div
						className={`${
							!message.thumbnail ? "highlight m-0" : "absolute bottom-0 "
						} file-info h-[65px] w-full rounded-b-[8px] flex items-center justify-between px-2.5 gap-[9px] ${
							is_sent_by_me ? "bg-[#9FD4FF]" : "bg-[#F6F6F6]"
						}`}>
						<LightboxThumbnail
							item={message}
							className="shrink-0 scale-[1.4]"
						/>
						<div className="flex flex-col items-start justify-center max-w-[235px] text-left">
							<h1 className="w-full overflow-hidden text-body text-[16px] whitespace-nowrap text-ellipsis">
								{message.message
									? message.message
									: message.audio_url.split("/").pop()}
							</h1>
							{message.file_size && message.message && (
								<span className="text-body text-[12px]">
									{message.file_size ? formatBytes(message.file_size) : null} •{" "}
									{message.message.split(".").pop().toUpperCase()}
								</span>
							)}
						</div>
						<Link
							className={`h-[30px] w-[30px] rounded-full ${
								is_sent_by_me ? "bg-[#AFD4F0]" : "bg-[#E9E9E9]"
							}`}
							onClick={() => setShowDownloder(true)}>
							<MaterialIcon
								icon="download"
								size={24}
								className="text-[20px] text-secondary"
							/>
						</Link>
						<DownloaderDialog
							open={showDownloader}
							url={message.audio_url}
							filename={message.message || message.audio_url.split("/").pop()}
							onClose={() => setShowDownloder(false)}
						/>
					</div>

					{uploadIdentifier && uploadIdentifier === message.identifier && (
						<div
							className={`message-loader-bg bg-black/[0.25] absolute left-0 right-0 bottom-0 flex items-center justify-start pl-[4px] ${
								type !== "single" &&
								message.sender_name &&
								parseInt(message.sender_id) !== loginResponse.data.id
									? "top-[19px]"
									: "top-0"
							}`}>
							{uploadProgress > -1 ? (
								<>
									<Gauge
										value={(uploadProgress / 100).toFixed(2)}
										size={50}
										bgColor="rgba(0,0,0,0.5)"
										borderWidth={5}
										borderBgColor="rgba(255,255,255,0.5)"
										borderColor="#0e99fe"
										className="m-0"></Gauge>
									<Button
										className="absolute w-[50px] h-11 rounded-full p-0"
										onClick={() => {
											cancelUpload(uploadIdentifier, message);
										}}>
										<MaterialIcon
											icon="close"
											fill
											color="white"
											size={19}
											grad={-25}
											className="text-[36px] rounded-full"
										/>
									</Button>
								</>
							) : (
								<Preloader color="#0e99fe" size={50} />
							)}
						</div>
					)}
				</div>
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							slot="bubble-start"
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				{message.caption && (
					<span
						slot="text"
						className="pl-[12px]"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{parsedMessage}
					</span>
				)}
				{message.caption && (
					<div
						slot="text-footer"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{supported &&
							speaking !== message.id &&
							isLanguageSupported(message.language) && (
								<Button
									className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
									onClick={() => {
										speak({
											text: message.caption,
											id: message.id,
											lang: message.language,
										});
									}}>
									<VolumeHigh
										size={18}
										color="#0E99FE"
										className="align-text-bottom cursor-pointer"
									/>
								</Button>
							)}
						{speaking && speaking === message.id && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={cancel}>
								<PauseCircle
									size={18}
									color="#0E99FE"
									className="align-text-bottom  cursor-pointer"
								/>
							</Button>
						)}
						{loginResponse.data.language &&
							loginResponse.data.language != message.language &&
							!translating.includes(message.id) && (
								<span
									className="inline-block cursor-pointer underline select-none"
									onClick={() => {
										translate(message, (response) => {
											db.translatedMessages.put({
												...message,
												caption: response.translated_message,
												original_language: message.language,
												language: loginResponse.data.language,
											});
										});
									}}>
									{t("View Translated")}
								</span>
							)}
						{translating.includes(message.id) && (
							<span className="inline-block cursor-pointer select-none">
								{t("Translating...")}
							</span>
						)}
						{message.original_language && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									db.translatedMessages.delete(message.id);
								}}>
								{t("View Original")}
							</span>
						)}
					</div>
				)}
				<span
					className=""
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "disappear") {
		const disappear = JSON.parse(message.message);
		if (disappear.disappear === "0" || disappear.disappear === 0) {
			return (
				<MessagesTitle className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						<TimerIcon
							stroke="#5d6980"
							fill="#5d6980"
							height={16}
							width={16}
							className="inline align-bottom mr-1"
						/>
						{msgjson?.id == loginResponse.data.id
							? t("You turned off disappearing messages")
							: disappear.firstname}{" "}
						{t("turned off disappearing messages")}
					</div>
				</MessagesTitle>
			);
		} else if (disappear.disappear === "1" || disappear.disappear === 1) {
			return (
				<MessagesTitle className="flex items-center justify-center">
					<div className="text-secondary message-title-content">
						<TimerIcon
							stroke="#5d6980"
							fill="#5d6980"
							height={16}
							width={16}
							className="inline align-bottom mr-1"
						/>
						{msgjson?.id === loginResponse.data.id ? (
							<>
								{t("You")}
								{t(
									"turned on disappearing messages. New messages will disappear from this chat"
								)}{" "}
								{moment.duration(disappear.duration, "minutes").humanize()}{" "}
								{t("after they're sent, except when kept")}
							</>
						) : (
							<>
								{disappear.firstname}{" "}
								{t(
									"turned on disappearing messages. New messages will disappear from this chat"
								)}{" "}
								{moment.duration(disappear.duration, "minutes").humanize()}{" "}
								{t("after they're sent, except when kept")}
							</>
						)}
					</div>
				</MessagesTitle>
			);
		}
	} else if (message.type === "location") {
		const coordinates = message.message
			.split("...")
			.map((coord) => parseFloat(coord.trim()));
		const googleMapsLink = `https://maps.google.com/?q=${coordinates[0]},${coordinates[1]}`;
		const liveLocation = message.duration
			? t(`Live until {{time}}`, {
					time: moment
						.unix(message.unix_time + message.duration * 60)
						.format("h:mm A"),
			  })
			: false;

		console.log("livelocation", liveLocation);

		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`media-message ${selected ? "selected" : ""} ${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className={`context-btn absolute right-1.5 z-[1111] ${
							message.forwarded == 1 ? " icon_postion" : ""
						}`}
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								type !== "single" && !is_sent_by_me ? "#5D6980" : "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => setMessageContext(message)}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				<div
					slot="image"
					className="relative max-h-[345px]"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{type === "single" && (
						<div className="top-corner-overlay z-[1111]"></div>
					)}
					{type !== "single" && is_sent_by_me && (
						<div className="top-corner-overlay z-[1111]"></div>
					)}

					<Link
						noLinkClass
						href={liveLocation ? googleMapsLink : "#"}
						popupOpen={liveLocation ? ".live-location-popup" : true}
						target={liveLocation ? "_blank" : ""}
						className={liveLocation ? "" : "external"}
						external={!liveLocation}
						onClick={() => {
							liveLocation &&
								f7.store.dispatch("setSelectedLocationMessage", message);
						}}>
						<MapContainer
							zoomControl={false}
							dragging={false}
							keyboard={false}
							scrollWheelZoom={false}
							center={coordinates}
							zoom={20}
							placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
							<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
							<Marker position={coordinates}></Marker>
						</MapContainer>
					</Link>
					{liveLocation ? (
						<div className="absolute w-full h-[32px] leading-[32px] px-1.5 bg-[#ffffff]/50 backdrop-blur-xl bottom-0 left-0 z-[1111] rounded-b-[var(--f7-message-image-border-radius)]">
							{liveLocation}
						</div>
					) : (
						<div className="bottom-overlay z-[1111]"></div>
					)}
				</div>

				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							slot="bubble-start"
							className={`text-${
								colors[memberIndex % colors.length]
							} font-bold px-[12px] cursor-pointer username`}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : message.sender_name &&
							  parseInt(message.sender_id) !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{message.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type !== "text" &&
								repliedMessage.type !== "contact" &&
								repliedMessage.type !== "audio" ? (
									<DocumentText
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-middle mr-[2px] -mt-[4px]"
									/>
								) : repliedMessage.type === "contact" ? (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								) : (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				{message.caption && (
					<span
						slot="text"
						className="pl-[12px]"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{parsedMessage}
					</span>
				)}
				{message.caption && (
					<div
						slot="text-footer"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{supported &&
							speaking !== message.id &&
							isLanguageSupported(message.language) && (
								<Button
									className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
									onClick={() => {
										speak({
											text: message.caption,
											id: message.id,
											lang: message.language,
										});
									}}>
									<VolumeHigh
										size={18}
										color="#0E99FE"
										className="align-text-bottom cursor-pointer"
									/>
								</Button>
							)}
						{speaking && speaking === message.id && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={cancel}>
								<PauseCircle
									size={18}
									color="#0E99FE"
									className="align-text-bottom  cursor-pointer"
								/>
							</Button>
						)}
						{loginResponse.data.language &&
							loginResponse.data.language != message.language &&
							!translating.includes(message.id) && (
								<span
									className="inline-block cursor-pointer underline select-none"
									onClick={() => {
										translate(message, (response) => {
											db.translatedMessages.put({
												...message,
												caption: response.translated_message,
												original_language: message.language,
												language: loginResponse.data.language,
											});
										});
									}}>
									{t("View Translated")}
								</span>
							)}
						{translating.includes(message.id) && (
							<span className="inline-block cursor-pointer select-none">
								{t("Translating...")}
							</span>
						)}
						{message.original_language && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									db.translatedMessages.delete(message.id);
								}}>
								{t("View Original")}
							</span>
						)}
					</div>
				)}
				<span
					className={`image_span z-[1111] ${
						liveLocation ? "text-secondary" : ""
					} ${message.forwarded == 1 ? " forwardedbox" : ""}`}
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{unixTimestampToTime(message.unix_time)}{" "}
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "mms") {
		const content = processMMS(message.message);

		return (
			<Message
				ref={messageRef}
				onContextMenu={(e) => {
					e.preventDefault();
					displayMenu(message, e);
				}}
				className={`text-message ${selected ? "selected" : ""}${
					selectedMessages.length > 0 ? "message-padding" : ""
				}`}
				id={message.id}
				type={getMessageType(parseInt(message.sender_id))}
				// image={message.image}
				first={true}
				last={true}>
				{!infoOpened && selectedMessages.length === 0 && (
					<Button
						className="context-btn absolute right-1.5 top-1.5"
						onClick={(e) => displayMenu(message, e)}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="expand_more"
							size={22}
							color={
								!repliedMessage || (type !== "single" && !is_sent_by_me)
									? "#5D6980"
									: "#FFFFFF"
							}
						/>
					</Button>
				)}
				{selectedMessages.length > 0 && (
					<div
						slot="start"
						className={`message-checkbox h-full w-[45px] flex items-center justify-center`}>
						<Checkbox checked={selected} />
					</div>
				)}
				{selectedMessages.length === 0 &&
					message.chat_id !== "posh_ai" &&
					message.chat_id !== 11 &&
					!infoOpened && (
						<div
							slot="content-start"
							className={`flex justify-center items-center ${
								hasReaction ? "h-[calc(100%-21px)]" : "h-full"
							} w-auto absolute ${
								parseInt(message.sender_id) !== loginResponse.data.id
									? "-right-[37px]"
									: "-left-[37px]"
							}`}>
							<Button
								round
								small
								className={`react-btn w-[32px] p-[5px] bg-[#5D698033] ${
									message.id === messageContext?.id &&
									reactionsOpen &&
									"force-visible-flex"
								}`}
								{...(reactionsOpen ? {} : { popoverOpen: ".reaction-menu" })}
								popoverClose={reactionsOpen}
								onClick={() => {
									setMessageContext(message);
								}}>
								<MaterialIcon
									fill
									icon="sentiment_satisfied"
									size={22}
									color="#FFF"
								/>
							</Button>
						</div>
					)}
				{type !== "single" &&
					message.sender_name &&
					parseInt(message.sender_id) !== loginResponse.data.id && (
						<span
							slot="bubble-start"
							className={`message-sender-name cursor-pointer text-${
								colors[memberIndex % colors.length]
							} font-bold username`}
							onContextMenu={(e) => {
								e.preventDefault();
								displayMenu(message, e);
							}}
							onClick={handleSenderNameClick}>
							{message.sender_name}
						</span>
					)}
				{message.forwarded && (
					<span
						slot="bubble-start"
						className="text-xs text-start"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						<MaterialIcon
							icon="reply"
							size={12}
							className="mr-1 text-sm transform align-sub -scale-x-[1] scale-y-[1] h-[12px]"
						/>
						{t("Forwarded")}
					</span>
				)}
				{message.forwarded && (
					<div
						slot="bubble-end"
						className="w-full flex items-center justify-between">
						<span className="text-[0.65rem] text-secondary">
							{t("By")} {message.owner_name},
						</span>
						<span className="text-[0.65rem] text-secondary">
							{unixTimestampToDate(message.forwarded_unix) !== "Today" &&
								unixTimestampToDate(message.forwarded_unix)}{" "}
							{unixTimestampToTime(message.forwarded_unix)}
						</span>
					</div>
				)}
				{repliedMessage && (
					<div
						slot="bubble-start"
						className={`relative flex highlight border-l-4 border-solid cursor-pointer ${
							type === "single"
								? repliedMessage.sender_id !== loginResponse.data.id
									? "border-[#0e99fe]"
									: "border-[#2ad421]"
								: `border-${colors[memberIndex % colors.length]}`
						} items-center justify-between ${
							repliedMessage.type !== "text" ? "p-0" : null
						}`}
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}
						onClick={() => f7.emit("searchMessage", repliedMessage.id, false)}>
						<div className="top-corner-overlay"></div>
						<div
							className={`flex flex-col w-full ${
								repliedMessage.type !== "text" ? "px-[15px] py-[10px]" : null
							}`}>
							{type === "single" ? (
								<span
									className={`${
										repliedMessage.sender_id === loginResponse.data.id
											? "text-[#2ad421]"
											: "text-[#0e99fe]"
									} font-semibold text-base text-left`}>{`${
									replyToMember.split(" ")[0]
								}`}</span>
							) : repliedMessage.sender_name &&
							  repliedMessage.sender_id !== loginResponse.data.id ? (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} font-bold text-xs cursor-pointer username`}
									onClick={handleSenderNameClick}>
									{repliedMessage.sender_name}
								</span>
							) : (
								<span
									className={`text-${
										colors[memberIndex % colors.length]
									} text-base text-left font-semibold`}>
									You
								</span>
							)}
							<span className="text-secondary text-ellipsis text-base text-left max-h-[44px] whitespace-nowrap overflow-hidden w-full inline-block">
								{repliedMessage.type === "audio" && (
									<Microphone2
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								{repliedMessage.type === "contact" && (
									<Profile
										size="15"
										color="#5D6980"
										variant="Bold"
										className="inline-block align-text-top mr-[2px] mt-[2px]"
									/>
								)}
								{repliedMessage.type !== "text" &&
									repliedMessage.type !== "contact" &&
									repliedMessage.type !== "audio" && (
										<DocumentText
											size="15"
											color="#5D6980"
											variant="Bold"
											className="inline-block align-middle mr-[2px] -mt-[4px]"
										/>
									)}
								<MarkdownPreview
									source={removeMD(
										repliedMessage?.type !== "text"
											? repliedMessage?.caption?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  ) ||
													repliedMessage?.message?.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
											: repliedMessage?.message?.replace(
													/@\[([^\]]+)\]\((\d+)\)/g,
													"@$1"
											  )
									)}
									rehypePlugins={[rehypeSanitize]}
									className="align-bottom"
								/>
							</span>
						</div>
						{repliedMessage?.type !== "text" && (
							<div
								className={`w-[88px] h-[69px] rounded-r-[10px] ${
									repliedMessage.type !== "image" &&
									repliedMessage.type !== "video"
										? "pr-[10px]"
										: null
								}`}>
								{repliedMessage?.type !== "contact" &&
								repliedMessage?.type !== "audio" ? (
									<LightboxThumbnail
										item={repliedMessage}
										className={`${
											repliedMessage.type !== "image" &&
											repliedMessage.type !== "video"
												? "scale-[1.4] mt-[18px]"
												: "w-[88px] h-[69px] rounded-r-[10px]"
										}`}
									/>
								) : repliedMessage?.type === "contact" ? (
									<div className="flex flex-col items-end justify-center h-full w-full">
										<LazyLoaderWraper
											src={userimage}
											placeholder={userimage}
											height={45}
											width={45}
											alt=""
											className="rounded-full"
											wrapperclassname="rounded-full"
										/>
									</div>
								) : repliedMessage?.type === "audio" ? (
									<i className="kt-ft record scale-[1.4] mt-[18px] mr-[8px]" />
								) : repliedMessage?.type === "location" ? (
									<MapContainer
										zoomControl={false}
										dragging={false}
										keyboard={false}
										scrollWheelZoom={false}
										center={repliedMessage.original_message
											.split("...")
											.map((coord) => parseFloat(coord.trim()))}
										zoom={20}
										placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
										<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
										<Marker
											position={repliedMessage.original_message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}></Marker>
									</MapContainer>
								) : null}
							</div>
						)}
					</div>
				)}
				{message.message && (
					<span
						slot="text"
						onContextMenu={(e) => {
							e.preventDefault();
							displayMenu(message, e);
						}}>
						{parsedMessage}
					</span>
				)}

				<div
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{supported &&
						speaking !== message.id &&
						isLanguageSupported(message.language) && (
							<Button
								className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
								onClick={() => {
									speak({
										text: message.message,
										id: message.id,
										lang: message.language,
									});
								}}>
								<VolumeHigh
									size={18}
									color="#0E99FE"
									className="align-text-bottom cursor-pointer"
								/>
							</Button>
						)}
					{speaking && speaking === message.id && (
						<Button
							className="h-[18px] w-[18px] min-w-[18px] mr-1.5 inline-block p-0"
							onClick={cancel}>
							<PauseCircle
								size={18}
								color="#0E99FE"
								className="align-text-bottom  cursor-pointer"
							/>
						</Button>
					)}
					{loginResponse.data.language &&
						loginResponse.data.language != message.language &&
						!translating.includes(message.id) && (
							<span
								className="inline-block cursor-pointer underline select-none"
								onClick={() => {
									translate(message, (response) => {
										db.translatedMessages.put({
											...message,
											message: response.translated_message,
											original_language: message.language,
											language: loginResponse.data.language,
										});
									});
								}}>
								{t("View Translated")}
							</span>
						)}
					{translating.includes(message.id) && (
						<span className="inline-block cursor-pointer select-none">
							{t("Translating...")}
						</span>
					)}
					{message.original_language && (
						<span
							className="inline-block cursor-pointer underline select-none"
							onClick={() => {
								db.translatedMessages.delete(message.id);
							}}>
							{t("View Original")}
						</span>
					)}
				</div>
				<span
					slot="text-footer"
					onContextMenu={(e) => {
						e.preventDefault();
						displayMenu(message, e);
					}}>
					{message.edited ? (
						<MaterialIcon
							icon="edit"
							color="#302f36"
							size={18}
							weight={300}
							className="text-[14px] mr-1 mt-0 align-sub"
						/>
					) : null}
					<span>{unixTimestampToTime(message.unix_time)}</span>
					{parseInt(message.sender_id) === loginResponse.data.id &&
						message.chat_id !== "posh_ai" && (
							<MsgStatusIcon status={message.is_read} />
						)}
				</span>
				{hasReaction && (
					<MessageReactions
						slot="content-end"
						id={message.id}
						count={countReaction}
						visible={hasReaction}
						reactionPickerOpen={reactionsOpen}
						setMyExistingReactions={setMyExistingReactions}
					/>
				)}
			</Message>
		);
	} else if (message.type === "block") {
		return (
			<MessagesTitle
				id={message.id}
				className="flex items-center justify-center">
				<div className="text-secondary message-title-content">
					{t("You blocked this contact")}{" "}
					{profile?.blocked ? (
						<Link className="text-primary" onClick={() => f7.emit("unblock")}>
							Unblock
						</Link>
					) : null}
				</div>
			</MessagesTitle>
		);
	} else if (message.type === "unblock") {
		return (
			<MessagesTitle
				id={message.id}
				className="flex items-center justify-center">
				<div className="text-secondary message-title-content">
					{t("You unblocked this contact")}
				</div>
			</MessagesTitle>
		);
	}
};

export default RenderMessage;
