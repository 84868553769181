import { SkeletonAvatar, SkeletonBlock } from "framework7-react";
import React from "react";

const ChatShimmer = ({ owner = false, height = "", width = "" }) => {
	return (
		<div
			className={`max-w-[${owner ? "490px" : "425px"}] flex justify-start gap-2.5 ${
				owner ? "self-end flex-row-reverse items-end text-right" : ""
			}`}>
			<SkeletonAvatar
				size={54}
				effect="wave"
				color={owner ? "#F8F8F8" : "#E8E8E8"}
				showIcon={false}
			/>
			<SkeletonBlock
				tag="p"
				height={height}
				width={width}
				borderRadius="10px"
				effect="wave"
				className={`${owner ? "bg-[#F8F8F8]" : "bg-[#E8E8E8]"}`}
			/>
		</div>
	);
};

export default ChatShimmer;
