const MaterialIcon = ({ icon, fill, weight, grad, size, color, className }) => {
	fill = fill ? 1 : 0;
	weight = weight % 100 == 0 && weight >= 100 && weight <= 700 ? weight : 400;
	grad = grad >= -25 && grad <= 200 ? grad : 0;
	size = size >= 24 && size <= 48 ? size : 20;

	return (
		<i
			className={`material-symbols-rounded ${className}`}
			style={{
				fontVariationSettings: `'FILL' ${fill}, 'wght' ${weight}, 'GRAD' ${grad}, 'opsz' ${size}`,
                ...(color && {color: color})
			}}>
			{icon}
		</i>
	)
};

export default MaterialIcon;

