import { useLiveQuery } from "dexie-react-hooks";
import { Button, Page, PageContent, Sheet, f7 } from "framework7-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactInstaStories from "react-insta-stories";
import { useDispatch, useSelector } from "react-redux";
import EmptyBlock from "../../components/StoriesPage/EmptyBlock";
import MakeStory from "../../components/StoriesPage/MakeStory";
import StoryFooter from "../../components/StoriesPage/StoryFooter";
import StoryHeader from "../../components/StoriesPage/StoryHeader";
import Linkify from "../../components/StoriesPage/components/Linkfy";
import TextStoryPreview from "../../components/StoriesPage/feathers/TextStoryPreview";
import VideoStoryPreview from "../../components/StoriesPage/feathers/VideoStoryPreview";
import MaterialIcon from "../../components/misc/materialIcon";
import { ENDPOINTS } from "../../constants/socket";
import useStory from "../../hooks/useStory";
import { db } from "../../js/db";
import { fetchMeta } from "../../redux/features/metaSlice";
import { ShareSocial } from "react-share-social";
import {
	getUserStories,
	updateShareURL,
	updateSingleStoryStatus,
} from "../../redux/features/storiesSlice";
import {
	updateAllStories,
	updateStoriesById,
	updateStoriesUsers,
} from "../../redux/features/storiesSlice/currentStories";
import {
	updateStoryType,
} from "../../redux/features/storiesSlice/userStory";
import { SocketContext } from "../../socket";
import { calculateLastseen, createOpenDialog } from "../../utils/functions";
import "./style.css";

function StoriesPage() {
	const { inputRef, t } = useStory();
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
	const [storyCreateTime, setStoryCreateTime] = useState("");
	const [playStoryId, setPlayStoryId] = useState(null);
	const [isPlaying, setisplaying] = useState(true);
	const [isPaused, setIsPaused] = useState(false);
	const [currentFile, setCurrentFile] = useState(null);
	const profile = useSelector(
		(store) => store?.loginSlice?.loginResponse?.data
	);
	const [selfStories, setSelfStories] = useState({
		stories: [],
		info: { userName: "", id: 0 },
	});
	const dispatch = useDispatch();
	const userStories = useSelector((store) => store?.currentStories?.stories);
	const allStories = useSelector((store) => store?.stories?.stories);
	const userStory = useSelector((store) => store.userStory);
	const { shareStoryURL, stories } = useSelector((store) => store?.stories);
	const metaDatas = useLiveQuery(() => db.metaDatas.toArray(), [], []);
	const socket = useContext(SocketContext);
	const storyContent = useRef(null);
	useEffect(() => {
		dispatch(getUserStories());
	}, []);

	useEffect(() => console.log("shareStoryURL", shareStoryURL), [shareStoryURL]);
	useEffect(() => {
		if (!currentFile) return;

		dispatch(updateStoryType("file"));
		f7.store.dispatch("setStoryFile", currentFile);
		inputRef.current.remove();
		f7.tab.show("#makeStoryTab");
	}, [currentFile]);

	useMemo(() => {
		setCurrentVideoIndex(0);
		dispatch(updateAllStories(allStories));
		dispatch(updateStoriesUsers());

		let filteredTempArr = [];
		if (userStories.length > 0) {
			userStories?.forEach((story) => {
				if (story.type === "text") {
					const urlRegex = /(https?:\/\/[^\s]+)/g;
					const parts = story.message?.split(urlRegex);
					parts.forEach((part) => {
						if (part.match(urlRegex)) {
							const url = part;
							let urlParts = url.split("/");
							let finalURL = urlParts?.[0] + "//" + urlParts?.[2] + "/";
							const linkExist = metaDatas.find((meta) => meta.url === finalURL);
							if (!linkExist) {
								dispatch(fetchMeta({ url: finalURL }));
							}
						}
					});
					filteredTempArr.push({
						content: () => (
							<div
								style={{ backgroundColor: story?.color }}
								className="w-[100%] p-[1rem] h-[100%] flex justify-center items-center">
								<Linkify text={story.message} fontSize={32} />
							</div>
						),
						createdAt: story?.unix_timestamp,
						storyId: story?.id,
					});
				} else {
					filteredTempArr.push({
						url: story?.file_url,
						type: story?.type,
						createdAt: story?.unix_timestamp,
						storyId: story?.id,
					});
				}
			});
		}

		if (userStories?.[0]) {
			setSelfStories({
				stories: filteredTempArr,
				info: {
					userName: userStories?.[0]?.firstname,
					createdAt: userStories?.[0]?.unix_timestamp,
					avatar: userStories?.[0]?.profile_image,
					id: userStories?.[0]?.user_id,
				},
			});
		}
		setStoryCreateTime(
			calculateLastseen(new Date(userStories?.[0]?.unix_timestamp * 1000))
		);
	}, [userStories]);

	useEffect(() => {
		setPlayStoryId(userStories?.[currentVideoIndex]?.id);
	}, [userStories]);

	useEffect(() => {
		if (selfStories?.stories?.[currentVideoIndex]?.createdAt) {
			setStoryCreateTime(
				calculateLastseen(
					new Date(selfStories?.stories?.[currentVideoIndex]?.createdAt * 1000)
				)
			);
		} else {
			setStoryCreateTime("");
		}
		setIsPaused(false);
		setisplaying(true);
	}, [currentVideoIndex, userStories]);

	const viewStoryhandler = (id) => {
		socket.emit(
			ENDPOINTS.VIEW_STORY,
			JSON.stringify({
				story_id: id,
			}),
			(response) => {
				console.log("Story Seen Status", response);
				dispatch(updateSingleStoryStatus(id));
			}
		);
	};

	return (
		<Page
			name="StoriesPage"
			onPageInit={() => {
				dispatch(updateStoryType(null));
				dispatch(updateStoriesById({}));
				dispatch(updateShareURL({ id: 0 }));
			}}>
			{userStories?.length > 0 ? (
				<div className="h-[100%] w-[100%] flex justify-center items-center gap-[1.5rem] bg-[#1F2634]">
					<Button
						className={`${
							!currentVideoIndex ||
							(currentVideoIndex && currentVideoIndex === 0)
								? "opacity-0 pointer-events-none"
								: ""
						}  bg-[#494949] w-[55px] h-[55px]`}
						onClick={() => {
							if (currentVideoIndex) {
								setCurrentVideoIndex(currentVideoIndex - 1);
								setPlayStoryId(
									selfStories?.stories?.[currentVideoIndex]?.storyId
								);
							} else {
								setCurrentVideoIndex(0);
							}
						}}>
						<MaterialIcon
							icon="chevron_left"
							className="text-[32px] text-white"
						/>
					</Button>
					<div
						ref={storyContent}
						className="h-[90%] w-[476px] rounded-[10px] overflow-hidden relative z-[1]">
						<StoryHeader
							translate={t}
							isPaused={isPaused}
							playPausehandler={() => setIsPaused(!isPaused)}
							info={selfStories?.info}
							storyCreateTime={storyCreateTime}
							profile={profile}
							storyId={playStoryId}
						/>
						{profile?.user_id !== selfStories?.info?.id && (
							<StoryFooter
								stories={userStories}
								userData={userStories?.[0]}
								storyId={playStoryId}
								setIsPaused={setIsPaused}
								profile={profile}
							/>
						)}
						<div className="videoParent h-full w-full">
							<ReactInstaStories
								loop={true}
								stories={selfStories?.stories}
								defaultInterval={5000}
								width="100%"
								height="100%"
								preventDefault
								currentIndex={currentVideoIndex}
								preloadCount={2}
								storyContainerStyles={{ zIndex: 2 }}
								onStoryStart={() => {
									setPlayStoryId(
										selfStories?.stories?.[currentVideoIndex]?.storyId
									);
									viewStoryhandler(playStoryId);
								}}
								onStoryEnd={() => {
									setCurrentVideoIndex(
										(prevIndex) =>
											prevIndex !== selfStories?.stories?.length &&
											prevIndex + 1
									);
									dispatch(updateShareURL({ id: 0 }));
									setIsPaused(true);
									f7.popover.close();
								}}
								storyInnerContainerStyles={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
								onNext={() => {
									setCurrentVideoIndex(
										(prevIndex) =>
											prevIndex !== selfStories?.stories?.length &&
											prevIndex + 1
									);
								}}
								onPrevious={() =>
									setCurrentVideoIndex(
										(prevIndex) => prevIndex !== 0 && prevIndex - 1
									)
								}
								onAllStoriesEnd={() => {
									viewStoryhandler(userStories?.[userStories.length - 1]?.id);
									f7.panel.close();
									dispatch(updateStoriesById({}));
								}}
								isPaused={isPaused}
							/>
						</div>
					</div>
					<Button
						onClick={() => {
							if (currentVideoIndex) {
								setCurrentVideoIndex(currentVideoIndex + 1);
							} else {
								setCurrentVideoIndex(1);
							}
						}}
						className={`${
							(currentVideoIndex &&
								currentVideoIndex === selfStories?.stories?.length - 1) ||
							userStories.length === 1
								? "opacity-0 pointer-events-none"
								: ""
						} bg-[#494949] w-[55px] h-[55px]`}>
						<MaterialIcon
							icon="chevron_right"
							className="text-[32px] text-white"
						/>
					</Button>
				</div>
			) : !userStory.type ? (
				<EmptyBlock translate={t} />
			) : userStory.type === "text" ? (
				<TextStoryPreview {...userStory} />
			) : userStory.type === "file" ? (
				<VideoStoryPreview currentFile={currentFile} />
			) : (
				<MakeStory
					onTextStory={() => {
						dispatch(updateStoryType("text"));
						f7.tab.show("#makeStoryTab");
					}}
					onPhotoStory={() => {
						inputRef.current = createOpenDialog();
						inputRef.current.onchange = (_) => {
							const file = inputRef.current.files[0];
							if (file) {
								setCurrentFile(file);
							}
							inputRef.current.remove();
						};
						inputRef.current.click();
					}}
				/>
			)}

			{storyContent.current && (
				<Sheet
					containerEl={storyContent.current}
					swipeToClose
					backdrop
					opened={shareStoryURL?.length > 0}
					onSheetClosed={() => dispatch(updateShareURL({ id: 0 }))}>
					<div className="swipe-handler"></div>
					<PageContent>
						<ShareSocial
							url={shareStoryURL}
							socialTypes={[
								"facebook",
								"twitter",
								"line",
								"linkedin",
								"whatsapp",
								"viber",
								"telegram",
								"reddit",
								"instapaper",
								"livejournal",
								"mailru",
								"ok",
								"hatena",
								"email",
								"workspace",
							]}
							onSocialButtonClicked={() => dispatch(updateShareURL({ id: 0 }))}
						/>
					</PageContent>
				</Sheet>
			)}
		</Page>
	);
}

export default StoriesPage;
