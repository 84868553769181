import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from "../../assets/images/icons/Forgot.png";
import { Eye, EyeSlash, Lock } from "iconsax-react";
import { createPasswordService } from "../../redux/features/passwordResetSlice/requestpwdToken";

import {
	Page,
	BlockTitle,
	Block,
	f7,
	List,
	ListInput,
	Button,
	PageContent,
} from "framework7-react";
import { useUpdateEffect } from "react-use";
import { STATUS_CODE } from "../../constants";
import { useTranslation } from "react-i18next";

const CreateNewPasswordNumber = ({ f7router }) => {
	const dispatch = useDispatch();
	const deviceInfo = f7.device;
	const { t, i18n } = useTranslation();
	const [username, setUsername] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [confirmpasswordType, setConfirmPasswordType] = useState("password");

	const [password, setPassword] = useState("");
	const [validpassword, setValidPassword] = useState(true);
	const [confirmPassword, setConfrimPassword] = useState("");
	const [validConfirmPassword, setVaildConfirmPassword] = useState(true);
	const [name, setName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [errorapi, setErrorApi] = useState("");
	const [message, setMessage] = useState("");

	const { pwdResetToken, restOTP, createPwdToken, FRGTEmailPWD, error } =
		useSelector((state) => state.pwdResetTokenSlice);
	// console.log('createPasswordService', restOTP.data.password_reset_token, error);
	// console.log('FRGTEmailPWD', FRGTEmailPWD.data.password_reset_token, error);
	const handlepasswordChanged = (e) => {
		const newPassword = e.target.value;
		const isValid = newPassword.lenght >= 8;
		setPassword(newPassword);
		setValidPassword(isValid);
		if (confirmPassword) {
			const isConfirmPassword = newPassword === confirmPassword;
			setVaildConfirmPassword(isConfirmPassword);
		}
	};

	const handleConfirmPasswordChanged = (e) => {
		const newConfirmPassword = e.target.value;
		const isConirmValid = newConfirmPassword === password;
		setConfrimPassword(newConfirmPassword);
		setVaildConfirmPassword(isConirmValid);
	};

	const handleResetPassword = () => {
		dispatch(
			createPasswordService({
				password: password,
				confirm_password: confirmPassword,
				password_reset_token: restOTP.data.password_reset_token,
			})
		);
	};
	useUpdateEffect(() => {
		if (createPwdToken?.code === STATUS_CODE.SUCCESS) {
			f7.toast
				.create({ closeTimeout: 5000, text: t(createPwdToken?.message) })
				.open();

			//after token received do processing here
			f7router.navigate("/ResetPassword/");
		} else {
			///f7 toast here  error
			f7.toast
				.create({ closeTimeout: 5000, text: t(createPwdToken?.message) })
				.open();
		}
	}, [createPwdToken, error]);

	const Reset_Password =
		!validpassword ||
		!validConfirmPassword ||
		password.length < 8 ||
		confirmPassword !== password;

	return (
		<Page name="CreateNewPasswordNumber" pageContent={false}>
			<AuthNavbar />

			<PageContent className=" md:overflow-hidden flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[345px] pb-[10px]">
							<p className="text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								{t("Create new password")}
							</p>
							<p className="leading-5 text-bases  text-color-[--f7-chat-heading-color] font-[--f7-font-family]">
								{t(
									"Set the new password for your account so you can login and access all the features"
								)}
							</p>
						</div>

						<BlockTitle className="w-[345px] leading-5 text-color-[--f7-heading-color] font-bold text-sm">
							{t("New Password")}
						</BlockTitle>
						<List className="w-[400px] mb-[0px]">
							<ListInput
								type={passwordType}
								value={password}
								onChange={handlepasswordChanged}
								placeholder={t("Enter new password")}
								className={`input-field-normal mt-5 ${
									!validpassword &&
									password.length > 0 &&
									password.lenght < 8
										? "invalid"
										: ""
								}`}
								required>
								<div
									slot="content-end"
									className=" cursor-pointer">
									{passwordType === "password" ? (
										<Eye
											onClick={() =>
												setPasswordType("text")
											}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									) : (
										<EyeSlash
											onClick={() =>
												setPasswordType("password")
											}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									)}
								</div>
								<div slot="media">
									<Lock
										color="#5d6980"
										variant="Bold"
										size={22}
									/>
								</div>
							</ListInput>
							{!validpassword &&
								password.length > 0 &&
								password.length < 8 && (
									<p className="w-[345px] ml-[40px] mt-[10px] error-text">
										{" "}
										{t("Your password must contain:")}:
										<br />.{t("8 character minimum")}
										<br />
										{t(
											"Uppercase and lowercase letters and numbers"
										)}
										<br />
										{t(
											"Inclusion of at least one special character"
										)}
									</p>
								)}
						</List>
						<BlockTitle className="w-[345px] leading-5 text-color-[--f7-heading-color] font-bold text-sm">
							{t("Confirm Password")}
						</BlockTitle>
						<List className="w-[400px] pt-[0px]">
							<ListInput
								type={confirmpasswordType}
								value={confirmPassword}
								onChange={handleConfirmPasswordChanged}
								placeholder={t("Confirm new password")}
								className={`input-field-normal mt-5 ${
									!validConfirmPassword &&
									confirmPassword.length > 0
										? "invalid"
										: ""
								}`}
								required>
								<div
									slot="content-end"
									className=" cursor-pointer">
									{confirmpasswordType === "password" ? (
										<Eye
											onClick={() =>
												setConfirmPasswordType("text")
											}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									) : (
										<EyeSlash
											onClick={() =>
												setConfirmPasswordType(
													"password"
												)
											}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									)}
								</div>
								<div slot="media">
									<Lock
										color="#5d6980"
										variant="Bold"
										size={22}
									/>
								</div>
							</ListInput>
							{!validConfirmPassword &&
								confirmPassword.length > 0 && (
									<p className="w-[345px] ml-[40px] mt-[10px] error-text">
										{" "}
										{t("password do not match")}
									</p>
								)}
							<Button
								onClick={handleResetPassword}
								type="Reset Password"
								fill
								className="w-full mt-[20px] ml-[18px]"
								disabled={Reset_Password}>
								{t("Reset Password")}
							</Button>
						</List>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default CreateNewPasswordNumber;
