import { Block, Button, Popover } from "framework7-react";
import {
  Add,
  AddCircle,
  ArrowDown2,
  TextalignCenter,
  TextalignLeft,
  TextalignRight,
} from "iconsax-react";
import React, { useRef } from "react";
import { CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useEyeDropper from "use-eye-dropper";
import {
  updateStorytextAlign,
  updateTextColor,
} from "../../../redux/features/storiesSlice/userStory";
import MaterialIcon from "../../misc/materialIcon";
import FontSizeMenu from "./FontSizeMenu";

function AddTextMenu() {
  const { open, close, isSupported } = useEyeDropper();
  const { t: translate } = useTranslation();
  const inputColorRef = useRef();
  const dispatch = useDispatch();
  const { fontSize, textAlign } = useSelector((store) => store?.userStory);

  return (
    <Popover
      className="addTextMenuOs2 min-w-[350px] ml-[200px] mt-[00px] bg-[#F1F1F1] p-4"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "409px" }}
      arrow={false}
      backdrop={false}
      closeByOutsideClick={false}
    >
      <Block className="flex flex-col w-[auto] gap-4 p-0 m-0">
        <Block className="flex justify-between items-center w-[100%] m-0 p-0  ">
          <p className="text-[18px] font-medium m-0">{translate("Text")}</p>
          <Button className="w-[50px] h-[50px]" popoverClose=".addTextMenuOs2">
            <Add size={40} color="#5D6980" className="transform rotate-45" />
          </Button>
        </Block>
        <Block className="flex justify-between p-0 flex-wrap m-0">
          <Button
            onClick={() => dispatch(updateStorytextAlign("l"))}
            style={{ backgroundColor: textAlign === "l" ? "#0E99FE" : "#FFF" }}
            className="border border-[#E0E7EC] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            <TextalignLeft
              size="24"
              color={textAlign === "l" ? "#FFF" : "#302F36"}
              variant="Bold"
            />
          </Button>
          <Button
            style={{ backgroundColor: textAlign === "c" ? "#0E99FE" : "#FFF" }}
            onClick={() => dispatch(updateStorytextAlign("c"))}
            className="border border-[#E0E7EC] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            <TextalignCenter
              size="24"
              color={textAlign === "c" ? "#FFF" : "#302F36"}
              variant="Bold"
            />
          </Button>
          <Button
            style={{ backgroundColor: textAlign === "r" ? "#0E99FE" : "#FFF" }}
            onClick={() => dispatch(updateStorytextAlign("r"))}
            className="border border-[#E0E7EC] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
          >
            <TextalignRight
              size="24"
              color={textAlign === "r" ? "#FFF" : "#302F36"}
              variant="Bold"
            />
          </Button>
          <Button
            popoverOpen=".fontSizeMenu"
            className="border border-[#E0E7EC] bg-[#F6F6F6] w-[150px]  h-[45px] flex justify-between px-[1rem] items-center"
          >
            <p className="text-[14px] font-normal text-[#302F36]">{fontSize}</p>
            <ArrowDown2 size="22" color="#5D6980" variant="Bold" />
            <FontSizeMenu />
          </Button>
        </Block>
        <Block className="border p-4 w-[100%] rounded-[20px] m-0 border-[#E0E7EC] bg-[#F6F6F6]">
          <p className="text-[14px] font-bold mb-2">
            {translate("Font Color")}
          </p>
          <CirclePicker onChange={(e) => dispatch(updateTextColor(e.hex))} />
          <p className="text-[14px] font-bold mt-2">{translate("Custom")}</p>
          <Block className="flex justify-start items-center gap-2 m-0 p-0 mt-1">
            <AddCircle
              className="cursor-pointer"
              size="20"
              color="#5D6980"
              variant="Outline"
              onClick={() => inputColorRef.current.click()}
            />
            <div
              className="cursor-pointer flex justify-center items-center"
              onClick={async () => {
                if (isSupported()) {
                  try {
                    const pickedColor = await open();
                    dispatch(updateTextColor(pickedColor.sRGBHex));
                  } catch (error) {}
                } else {
                  console.log("Feature not available in this browser");
                }
              }}
            >
              <MaterialIcon
                className={"text-[28px]"}
                icon="colorize"
                fill
                color="#5D6980"
              />
            </div>
            <input
              ref={inputColorRef}
              onChange={(e) => dispatch(updateTextColor(e.target.value))}
              type="color"
              className="absolute top-0 left-0 opacity-0 z-[-1]"
            />
          </Block>
        </Block>
      </Block>
    </Popover>
  );
}

export default AddTextMenu;
