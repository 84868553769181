import React from "react";
import { Button, Link, NavRight, Navbar, Page, Popup, f7 } from "framework7-react";
import Iframe from "react-iframe";
import MaterialIcon from "../materialIcon";

const ExternalPagePopup = ({ opened, url, onClosed }) => {
	return (
		<Popup
			opened={opened}
			tabletFullscreen
			onPopupClosed={onClosed}
			className="external-page-popup">
			<Page>
				<Navbar>
					<NavRight>
						<Button onClick={() => f7.popup.close()}>
							<MaterialIcon icon="close" size={32} className="text-[32px]" />
						</Button>
					</NavRight>
				</Navbar>
				<Iframe
					url={url}
					width="100%"
					height="100%"
					id=""
					className=""
					display="block"
					position="relative"
				/>
			</Page>
		</Popup>
	);
};

export default ExternalPagePopup;
