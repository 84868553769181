import { CountDownTimer } from "./timer";
import { COOKIE_PREFIX } from "../constants";
import { extension } from "mime-db";
import moment from "moment";

export const getCookieAsync = (cname) => {
	return new Promise(function (resolve, reject) {
		var match = document.cookie.match(
			new RegExp("(^| )" + COOKIE_PREFIX + cname + "=([^;]+)")
		);
		if (match) resolve(match[2]);
		else resolve(null);
	});
};
export const getCookie = (cname) => {
	var match = document.cookie.match(
		new RegExp("(^| )" + COOKIE_PREFIX + cname + "=([^;]+)")
	);
	if (match) return match[2];
	else return null;
};
export const setCookie = (cname, cvalue, exdays = 365) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = COOKIE_PREFIX + cname + "=" + cvalue + ";" + expires;
};
export const deleteCookie = (cname) => {
	document.cookie =
		COOKIE_PREFIX + cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const isJson = (str) => {
	if (str.length === 0) return false;

	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return isNaN(str) ? true : false;
};

export const addPronoun = (str) => {
	if (!str) return "";
	return str.match(/^[aeiou]/i) ? "an " + str : "a " + str;
};

export const isTodayGreaterThanDatetime = (datetime) => {
	var date = new Date();
	var todate = new Date(datetime);
	return date > todate;
};

export const timer = (duration) => {
	return new CountDownTimer(duration);
};

export const removeIndex = (obj, key) => {
	var propertyArray = Object.values(obj);
	for (var i = 0; i < propertyArray.length; i++)
		if (Object.keys(obj)[i] === key) delete obj[Object.keys(obj)[i]];
	return obj;
};

export const toDataURL = (url) => {
	return fetch(url)
		.then((response) => response.blob())
		.then(
			(blob) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
		);
};
export const imageURLtoBlobGenerator = (img_url, callback) => {
	let blobs;
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	const img = document.createElement("img");
	img.crossOrigin = "anonymous";
	img.addEventListener("load", async () => {
		canvas.width = img.naturalWidth;
		canvas.height = img.naturalHeight;
		ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
		blobs = await Promise.all(
			["image/jpeg", "image/webp", "image/png"].map(async (type) => {
				return new Promise((resolve) => {
					canvas.toBlob((blob) => {
						resolve(blob);
					}, type);
				});
			})
		);
		callback(blobs);
	});
	img.src = img_url;
};
export const imageURLtoBlobGeneratorAsync = (img_url) => {
	return new Promise((resolve, reject) => {
		let blobs;
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const img = document.createElement("img");
		img.crossOrigin = "anonymous";
		img.addEventListener("load", async () => {
			canvas.width = img.naturalWidth;
			canvas.height = img.naturalHeight;
			ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
			blobs = await Promise.all(
				["image/jpeg", "image/webp", "image/png"].map(async (type) => {
					return new Promise((resolve) => {
						canvas.toBlob((blob) => {
							resolve(blob);
						}, type);
					});
				})
			);
			resolve(blobs);
		});
		img.src = img_url;
	});
};

export const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = window.atob(arr[arr.length - 1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const blobToFile = (blob, filename = "screenshot.png") => {
	blob.lastModifiedDate = new Date();
	blob.name = filename;
	return blob;
};

export const removeBG = (canvas, imgurl, size = null) => {
	var width = size ? size.width : 100;
	var height = size ? size.height : 100;

	var img = new Image();
	toDataURL(imgurl).then((dataUrl) => {
		img.src = dataUrl;
		// var canvas = document.getElementById('canvas');
		var ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0, 150, 150);

		var imgd = ctx.getImageData(0, 0, 150, 150),
			pix = imgd.data,
			newColor = { r: 0, g: 0, b: 0, a: 0 };
		for (var i = 0, n = pix.length; i < n; i += 4) {
			var r = pix[i],
				g = pix[i + 1],
				b = pix[i + 2];

			// If its white then change it
			if (r >= 220 && g >= 220 && b >= 220) {
				// Change the white to whatever.
				pix[i] = newColor.r;
				pix[i + 1] = newColor.g;
				pix[i + 2] = newColor.b;
				pix[i + 3] = newColor.a;
			}
		}

		ctx.putImageData(imgd, 0, 0);
	});
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceCharactersAsXExceptLastFour = (string) => {
	return string.replace(/.(?=.{4,}$)/g, "X");
};

export const makeId = (length = 15, prefix = null) => {
	var result = prefix ? prefix + "_" : "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
};

export const isNumber = (string) => {
	return isNaN(string) ? false : true;
};

export const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const createDistribution = (array, weights, size) => {
	const distribution = [];
	const sum = weights.reduce((a, b) => a + b);
	const quant = size / sum;
	for (let i = 0; i < array.length; ++i) {
		const limit = quant * weights[i];
		for (let j = 0; j < limit; ++j) {
			distribution.push(i);
		}
	}
	return distribution;
};

export const ImageToBase64 = async (data) => {
	let blob;
	if (typeof data === "string") {
		const data = await fetch(url);
		blob = await data.blob();
	} else if (typeof data === "object") {
		blob = data;
	} else return new Promise((reject) => reject("invalid data type"));

	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};

export const getFileTypeByName = (filename) => {
	const fileExtension = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : null;

	if (!fileExtension) return undefined;

	// Define mapping of file extensions to file types
	const fileTypes = {
		bmp: "image",
		csv: "document",
		odt: "document",
		doc: "document",
		docx: "document",
		gif: "image",
		htm: "document",
		html: "document",
		jpg: "image",
		jpeg: "image",
		pdf: "pdf",
		png: "image",
		ppt: "document",
		pptx: "document",
		tiff: "image",
		txt: "text",
		xls: "document",
		xlsx: "document",
		mp4: "video",
		webp: "image",
		// Add more file types as needed
	};

	const fileType = fileTypes[fileExtension];

	return fileType || "undefined";
};

export const getFileType = (file) => {
	const mimeTypes = {
		image: /^image\/.*$/,
		audio: /^audio\/.*$/,
		video: /^video\/.*$/,
		document:
			/^(application\/pdf|application\/msword|application\/vnd.openxmlformats-officedocument.wordprocessingml.document|text\/plain|application\/json|application\/xml)$/,
		archive:
			/^(application\/zip|application\/x-tar|application\/x-gzip|application\/x-bzip2)$/,
	};

	const mimeType = file.type;

	for (const type in mimeTypes) {
		if (mimeTypes[type].test(mimeType)) {
			return type;
		}
	}

	return "file";
};

export const randomIndex = (distribution) => {
	const index = Math.floor(distribution.length * Math.random());
	return distribution[index];
};

export const absoluteUrl = (url) => {
	const r = new RegExp("^(?:[a-z]+:)?//", "i");
	return r.test(url);
};

export const isValidUrl = (url) => {
	return /^((http|https):\/\/)/.test(url);
};
export const isValidDataUrl = (url) => {
	return /^data:image/.test(url);
};

export const createOpenDialog = (
	type = "image",
	mode = "file",
	multiple = false
) => {
	let input = document.createElement("input");
	input.type = mode;
	if (type instanceof Array) {
		input.accept = type.map((t) => `${t}/*`).join(",");
	} else {
		input.accept = `${type}/*`;
	}
	input.multiple = multiple;
	return input;
};

export const isDuplicateFile = (file, filesArray) => {
	return new Promise(function (resolve, reject) {
		let result = false;
		if (filesArray?.length > 0) {
			filesArray.forEach((item, index) => {
				if (
					item?.name === file?.name &&
					item?.type === file?.type &&
					item?.size === file?.size &&
					item?.lastModified === file?.lastModified
				)
					result = true;
			});
		}
		resolve(result);
	});
};

export const formatTimestamp = (timestamp) => {
	const unixTimeStamp = convertToMillisecondsPrecision(timestamp);
	const inputDate = new Date(unixTimeStamp);
	const currentDate = new Date();

	if (
		inputDate.getDate() === currentDate.getDate() &&
		inputDate.getMonth() === currentDate.getMonth() &&
		inputDate.getFullYear() === currentDate.getFullYear()
	) {
		const hours = inputDate.getHours();
		const minutes = inputDate.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
		return `${formattedHours}:${formattedMinutes} ${ampm}`;
	} else if (
		inputDate.getDate() === currentDate.getDate() - 1 &&
		inputDate.getMonth() === currentDate.getMonth() &&
		inputDate.getFullYear() === currentDate.getFullYear()
	) {
		return "Yesterday";
	} else {
		const monthsArr = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		const month = monthsArr[inputDate.getMonth()];
		const day = inputDate.getDate();
		const year = inputDate.getFullYear().toString();
		return `${month} ${day}, ${year}`;
	}
};

export const unixTimestampToTime = (timestamp) => {
	const unixTimeStamp = convertToMillisecondsPrecision(timestamp);
	const date = new Date(unixTimeStamp);

	const hours = date.getHours();
	const minutes = date.getMinutes();
	const amOrPm = hours >= 12 ? "PM" : "AM";

	const formattedHours = hours % 12 || 12;

	const formattedTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
		.toString()
		.padStart(2, "0")} ${amOrPm}`;

	return formattedTime;
};
export const convertToMillisecondsPrecision = (unixTimestamp) => {
	if (unixTimestamp) {
		const timestampString = unixTimestamp.toString();
		const hasDecimal = timestampString.includes(".");
		if (hasDecimal) {
			const parts = timestampString.split(".");
			const seconds = parseInt(parts[0]);
			const milliseconds = parseFloat("0." + parts[1]) * 1000;
			return seconds * 1000 + milliseconds;
		} else if (timestampString.length === 10) {
			return unixTimestamp * 1000; // Convert seconds to milliseconds
		}
	}
	return unixTimestamp; // Return unchanged for milliseconds precision
};
export const unixTimestampToDate = (timestamp, format = "MMMM DD, YYYY") => {
	const unixTimeStamp =
		typeof timestamp !== "object"
			? convertToMillisecondsPrecision(timestamp)
			: null;

	const date =
		typeof timestamp !== "object" ? new Date(unixTimeStamp) : timestamp;
	const currentDate = new Date();

	// Check if the date is today
	if (
		date.getDate() === currentDate.getDate() &&
		date.getMonth() === currentDate.getMonth() &&
		date.getFullYear() === currentDate.getFullYear()
	) {
		return "Today";
	}

	// Check if the date is yesterday
	const yesterday = new Date();
	yesterday.setDate(currentDate.getDate() - 1);
	if (
		date.getDate() === yesterday.getDate() &&
		date.getMonth() === yesterday.getMonth() &&
		date.getFullYear() === yesterday.getFullYear()
	) {
		return "Yesterday";
	}

	// For older dates, return the formatted date

	return moment(date).format(format);
};

export const calculateLastseen = (lastOnline) => {
	const currentTime = new Date();
	const lastOnlineTime = new Date(lastOnline);

	const timeDifference = Math.abs(currentTime - lastOnlineTime);
	const minutes = Math.floor(timeDifference / 60000);
	const hours = Math.floor(minutes / 60);
	if (minutes < 1) {
		return "few seconds ago";
	} else if (minutes < 60) {
		return `${minutes} ${minutes == 1 ? "minute" : "minutes"} ago`;
	} else if (hours < 24) {
		return `${hours} ${"hours"} ago`;
	} else if (hours < 24 * 365) {
		const days = Math.floor(hours / 24);
		if (days > 29) {
			const months = Math.floor(days / 30);
			if (months === 1) {
				return `1 month ago`;
			} else {
				return `${months} months ago`;
			}
		} else {
			return `${days} ${days === 1 ? "day" : "days"} ago`;
		}
	} else {
		const years = Math.floor(hours / (24 * 365));
		return `${years} ${years === 1 ? "year" : "years"} ago`;
	}
};

export const checkVersion = (LatestVersion, currentVersion) => {
	const LatestArr = LatestVersion.split(".").map(Number);
	const CurrentArr = currentVersion.split(".").map(Number);

	function isValidVersionArray(arr) {
		return arr.every((num) => !isNaN(num));
	}

	if (!isValidVersionArray(LatestArr) || !isValidVersionArray(CurrentArr)) {
		return NaN;
	}

	for (let k = 0; k < LatestArr.length; ++k) {
		if (CurrentArr.length === k) return 1;
		if (LatestArr[k] !== CurrentArr[k]) {
			return LatestArr[k] > CurrentArr[k] ? 1 : -1;
		}
	}

	return LatestArr.length !== CurrentArr.length ? -1 : 0;
};

export const truncateText = (text, maxLength = 102) => {
	if (text.length > maxLength) {
		return text.substring(0, maxLength - 3) + "...";
	}
	return text;
};

export const setMessageContext = (context) => {
	localStorage.setItem("messageContext", JSON.stringify(context));
};
export const messageContext = () => {
	const context = localStorage.getItem("messageContext");
	return context ? JSON.parse(context) : null;
};
export const removeMessageContext = () => {
	localStorage.removeItem("messageContext");
};
export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const PDFPageCount = (url) => {
	const xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.responseType = "arraybuffer";
	xhr.onload = function (e) {
		if (xhr.status === 200) {
			const data = new Uint8Array(xhr.response);
			pdfjsLib.getDocument(data).then((pdf) => {
				return pdf.numPages;
			});
		} else {
			console.log(xhr.statusText);
		}
	};
	xhr.send();
};

export const getMimetypefromURL = async (url) => {
	const reponse = await fetch(url, {
		method: "HEAD",
		mode: "no-cors",
	});
	return reponse.headers.get("Content-Type");
};

export const generateThumbnail = (file) => {
	return new Promise((resolve, reject) => {
		if (
			isValidDataUrl(file) &&
			extension[file.split(".").pop().join(",").includes("image")]
		) {
			resolve(file);
		}
		const videoURL = isValidUrl(file) ? file : URL.createObjectURL(file);

		const video = document.createElement("video");
		video.src = videoURL;
		video.crossOrigin = "anonymous";

		video.onloadedmetadata = function () {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			let width = this.videoWidth;
			let height = this.videoHeight;

			const aspectRatio = width / height;

			if (aspectRatio > 1) {
				// Landscape video
				height = 350;
				width = height * aspectRatio;
			} else {
				// Portrait or square video
				width = 350;
				height = width / aspectRatio;
			}

			canvas.width = width;
			canvas.height = height;
			this.currentTime = 1;
			video.currentTime = 1;
			video
				.play()
				.then(() => {
					setTimeout(() => {
						context.drawImage(video, 0, 0, canvas.width, canvas.height);
						const dataUrl = canvas.toDataURL("image/jpeg");
						canvas.remove();
						resolve(dataUrl);
						video.pause();
					}, 500); // Adjust the timeout as needed
				})
				.catch((error) => {
					reject(error);
				});
			/*context.drawImage(this, 0, 0, canvas.width, canvas.height);
			const dataUrl = canvas.toDataURL("image/jpeg");
			canvas.remove();
			resolve(dataUrl);*/
		};
	});
};

export const translate = (text, lang) => {
	return new Promise((resolve, reject) => {
		const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${lang}&dt=t&q=${encodeURIComponent(
			text
		)}`;
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				resolve(data[0][0][0]);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const isLanguageSupported = (lang) => {
	const availableLanguages = Array.from(
		new Set(speechSynthesis.getVoices()?.map(({ lang }) => lang))
	).sort();
	return availableLanguages.some((l) => l.includes(lang));
};

export const getValuesByPartialKey = (object, searchText) => {
	if (!object) return null;

	searchText = searchText.toLowerCase();
	const values = [];
	for (const key in object) {
		if (key.toLowerCase().includes(searchText)) {
			values.push(object[key]);
		}
	}
	return values.length > 0 ? values : null;
};
function base64Decode(base64String) {
	const binaryString = window.atob(base64String);
	const byteNumbers = new Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		byteNumbers[i] = binaryString.charCodeAt(i);
	}
	return new Uint8Array(byteNumbers);
}
function removeDataUriPrefix(base64String) {
	// Check if the string starts with 'data:'
	if (base64String.startsWith("data:")) {
		// Find the index of the first comma after 'base64,'
		const commaIndex = base64String.indexOf(",");
		// Return the substring after the comma
		return base64String.slice(commaIndex + 1);
	}
	// Return the original string if it doesn't start with 'data:'
	return base64String;
}

export function base64ToFile(base64String, fileName, download = true) {
	const mimeType = base64String.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
	// Decode the Base64 string
	const processedBase64String = removeDataUriPrefix(base64String);
	const byteArray = base64Decode(processedBase64String);

	// Create a Blob from the decoded binary data
	const blob = new Blob([byteArray], {
		...(mimeType.includes("image") || mimeType.includes("video")
			? { type: mimeType }
			: { type: "application/octet-stream" }),
	});

	// Create a URL for the Blob
	const url = URL.createObjectURL(blob);

	// Create a link element with the download attribute
	if (download) {
		const link = document.createElement("a");
		link.href = url;
		link.download = fileName;

		// Trigger a click event on the link to initiate the file download
		document.body.appendChild(link);
		link.click();

		// Cleanup
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	} else {
		//convert to file
		const file = new File([blob], fileName, { type: blob.type });
		return file;
	}
}

export const concatenateBlobs = (blobs, type) => {
	return new Promise((resolve, reject) => {
		try {
			var buffers = [];

			var index = 0;

			function readAsArrayBuffer() {
				if (!blobs[index]) {
					return concatenateBuffers();
				}
				var reader = new FileReader();
				reader.onload = function (event) {
					buffers.push(event.target.result);
					index++;
					readAsArrayBuffer();
				};
				reader.readAsArrayBuffer(blobs[index]);
			}

			readAsArrayBuffer();

			function concatenateBuffers() {
				var byteLength = 0;
				buffers.forEach(function (buffer) {
					byteLength += buffer.byteLength;
				});

				var tmp = new Uint16Array(byteLength);
				var lastOffset = 0;
				buffers.forEach(function (buffer) {
					// BYTES_PER_ELEMENT == 2 for Uint16Array
					var reusableByteLength = buffer.byteLength;
					if (reusableByteLength % 2 != 0) {
						buffer = buffer.slice(0, reusableByteLength - 1);
					}
					tmp.set(new Uint16Array(buffer), lastOffset);
					lastOffset += reusableByteLength;
				});

				var blob = new Blob([tmp.buffer], {
					type: type,
				});

				resolve(blob);
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const getBlobDuration = (blob) => {
	const audio = document.createElement("audio");

	const durationPromise = new Promise((resolve, reject) => {
		audio.addEventListener("loadedmetadata", () => {
			if (audio.duration === Infinity) {
				audio.currentTime = Number.MAX_SAFE_INTEGER;
				audio.ontimeupdate = () => {
					audio.ontimeupdate = null;
					resolve(audio.duration);
					audio.currentTime = 0;
				};
			} else {
				resolve(audio.duration);
			}
		});
		audio.onerror = (event) => reject(event.target.error);
	});

	audio.src =
		typeof blob === "string" || blob instanceof String
			? blob
			: window.URL.createObjectURL(blob);

	return durationPromise;
};

export const dataURItoBlob = (dataURI) => {
	const byteString = atob(dataURI.split(",")[1]);
	const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], {
		type: mimeString,
		name: `video_${new Date().getMilliseconds}`,
	});
};

export const ImageDownlaoderFromURL = (url) => {
	const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
	if (!pattern.test(url)) {
		return;
	}
	fetch(url)
		.then((res) => {
			if (!res.ok) {
				throw new Error("Network Problem");
			}
			return res.blob();
		})
		.then((file) => {
			const ex = extFn(url);
			let tUrl = URL.createObjectURL(file);
			const tmp1 = document.createElement("a");
			tmp1.href = tUrl;
			tmp1.download = `downloaded_file.${ex}`;
			document.body.appendChild(tmp1);
			tmp1.click();
			URL.revokeObjectURL(tUrl);
			tmp1.remove();
		});
};

const extFn = (url) => {
	const match = url.match(/\.[0-9a-z]+$/i);
	return match ? match[0].slice(1) : "";
};

export const debounce = (func, wait) => {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const isPWA = () => {
	const isStandalone =
		window.matchMedia("(display-mode: standalone)").matches ||
		window.navigator.standalone === true;

	return isStandalone;
};

export const removeMD = (text) => {
	if (!text) return "";
	const markdownPatterns = [
		/(^|\s)[*_]{1,3}.+[*_]{1,3}(\s|$)/, // Bold, italic, bold italic (e.g., *text*, **text**, ***text***)
		/(^|\s)#+\s(.+)/, // Headers (e.g., # Header)
		/(^|\s)>\s(.+)/, // Blockquotes (e.g., > quote)
		/(^|\s)(-|\+|\*)\s.+/, // Unordered lists (e.g., - item)
		/(^|\s)\d+\.\s.+/, // Ordered lists (e.g., 1. item)
		/!\[(.*)\]\(.*\)/, // Images (e.g., ![alt text](url))
		/\[(.*)\]\(.*\)/, // Links (e.g., [link text](url))
		/(^|\s)```\s*\n([^```]*)\n```\s*(\s|$)/, // Code blocks (e.g., ``` code ```)
		/(^|\s)`([^`]+)`(\s|$)/, // Inline code (e.g., `code`)
	];

	text = text.replace(markdownPatterns[1], (match, p1, p2) => `${p1}**${p2}**`);
	text = text.replace(markdownPatterns[2], (match, p1, p2) => `${p1}**${p2}**`);
	text = text.replace(markdownPatterns[5], (match, p1) => `**${p1}**`);
	text = text.replace(markdownPatterns[6], (match, p1) => `**${p1}**`);
	text = text.replace(
		markdownPatterns[7],
		(match, p1, p2, p3) => `${p1}**${p2}**${p3}`
	);
	text = text.replace(
		markdownPatterns[8],
		(match, p1, p2, p3) => `${p1}**${p2}**${p3}`
	);

	return text;
};

export const calculateCharactersPerWidth = (
	fontSize,
	containerWidth,
	letterSpacing = 0
) => {
	// Create a temporary element to measure text width
	let tempElement = document.createElement("span");
	tempElement.style.position = "absolute";
	tempElement.style.whiteSpace = "nowrap";
	tempElement.style.fontSize = fontSize + "px";
	if (letterSpacing) tempElement.style.letterSpacing = letterSpacing + "px";
	tempElement.style.visibility = "hidden";
	tempElement.textContent = "W"; // Use 'W' as it is usually the widest character

	// Append to body to get the width
	document.body.appendChild(tempElement);
	let characterWidth = tempElement.offsetWidth;
	document.body.removeChild(tempElement);

	// Calculate the number of characters that fit in the container width
	let charactersPerWidth = Math.floor(containerWidth / characterWidth);
	return charactersPerWidth;
};

export const createCancelableFunction = (fn) => {
	let controller = null;
	let timeoutId = null;
	const isAsyncFunction = fn.constructor.name === "AsyncFunction";

	return function (...args) {
		if (isAsyncFunction) {
			if (controller) {
				controller.abort(); // Abort previous async operation
			}
			controller = new AbortController(); // Create a new controller
			fn(controller.signal, ...args)
				.then((result) => console.log(result))
				.catch((error) => {
					if (error.name === "AbortError") {
						console.log("Previous async function call was aborted.");
					} else {
						console.error("An error occurred:", error);
					}
				});
		} else {
			if (timeoutId) {
				clearTimeout(timeoutId); // Clear previous timeout
			}
			timeoutId = setTimeout(() => {
				try {
					fn(...args);
				} catch (error) {
					console.error("An error occurred:", error);
				}
			}, 0);
		}
	};
};
