import React, { useContext, useState } from "react";
import { SocketContext } from "../socket";
import { ENDPOINTS } from "../constants/socket";
import { useSelector } from "react-redux";

const usePoshTranslation = () => {
	const socket = useContext(SocketContext);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [translating, setTranslating] = useState([]);

	const translate = (data, callback) => {
		setTranslating(prevData => [...prevData, data.id]);
		socket.emit(
			ENDPOINTS.TRANSLATE_MESSAGE,
			JSON.stringify({
				message_id: data.id,
				target_language: loginResponse.data.language,
				message: data.type === "text" ? data.message : data.caption,
				type: "text",
			}),
			(response) => {
				console.log("translate response", response);
				setTranslating((prevData) => prevData.filter((id) => id !== data.id));
				callback(response);
			}
		);
	};

	return { translating, translate };
};

export default usePoshTranslation;
