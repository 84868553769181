import {
	Card,
	CardContent,
	CardHeader,
	Gauge,
	NavLeft,
	Navbar,
	Page,
	Popup,
	f7,
} from "framework7-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../materialIcon";
import "./style.css";
import useDownloader from "../../../hooks/useDownloader";

const DownloaderDialog = ({
	open,
	url,
	filename = "",
	title = "",
	progressText = "",
	descriptionText = "",
	completeText = "",
	onClose,
}) => {
	const { t } = useTranslation();
	const { percentage, download, error, isInProgress, complete } = useDownloader();

	useEffect(() => {
		if (complete) {
			setTimeout(() => {
				f7.popup.close();
			}, 2000);
		} else if (error) {
			setTimeout(() => {
				f7.popup.close();
			}, 2000);
		}
	}, [complete, error]);

	return (
		<Popup
			opened={open}
			onPopupOpened={() => {
				console.log("percentage downloadStarted");
				download(url, filename);
			}}
			onPopupClosed={onClose}
			closeByBackdropClick={false}
			closeOnEscape={false}
			className="file-download-popup">
			<Card className="m-0 bg-white max-h-full">
				<CardHeader>{t(title || "Downloading file")}</CardHeader>
				<CardContent>
					<div className="flex items-center justify-start gap-[10px] h-[50px]">
						{isInProgress ? (
							<Gauge
								className="m-0"
								value={percentage ? (percentage / 100).toFixed(2) : 1}
								size={50}
								borderWidth={5}
								borderBgColor="rgba(255,255,255,0.5)"
								borderColor="#1DAEFF"></Gauge>
						) : complete ? (
							<MaterialIcon
								fill
								size={48}
								icon="check_circle"
								className="text-theme-color text-[50px]"></MaterialIcon>
						) : error ? (
							<MaterialIcon
								icon="cancel"
								size={48}
								className="text-red-500"></MaterialIcon>
						) : null}
						<div className="flex flex-col items-start justify-between gap-[3px]">
							<h1 className="text-body leading-[21.8] text-base">
								{isInProgress
									? progressText || t("Please wait a moment")
									: complete
									? completeText || t("File Saved")
									: error && t("An error occurred")}
							</h1>
							{!isInProgress && descriptionText && (
								<h1 className="text-secondary leading-[19] text-sm">
									{descriptionText}
								</h1>
							)}
						</div>
					</div>
				</CardContent>
			</Card>
		</Popup>
	);
};

export default DownloaderDialog;
