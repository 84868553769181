import React, { useContext, useLayoutEffect } from "react";
import { deleteCookie, getCookie, setCookie } from "../../utils/functions";
import { AUTHTOKEN_COOKIE, STATUS_CODE } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Page, Preloader, f7 } from "framework7-react";
import { useWindowSize, useUpdateEffect } from "react-use";
import { autoLoginwithPoshService } from "../../redux/features/authSlice/login";
import "./style.css";
import { db } from "../../js/db";
import { SocketContext } from "../../socket";
import { logoutService } from "../../redux/features/authSlice/logout";
import { persistor } from "../../redux/store";
import { useTranslation } from "react-i18next";

const AutoPoshLogin = ({ poshid, wntoken, poshAccessToken, f7router }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { logoutResponse } = useSelector((state) => state.logoutSlice);
	const socket = useContext(SocketContext);
	let storedVersion = localStorage.getItem("appVersion") || "1.0.0";

	useLayoutEffect(() => {
		const authToken = getCookie(AUTHTOKEN_COOKIE);

		if (authToken) {
			const firstName = loginResponse?.data?.firstname;
			const lastName = loginResponse?.data?.lastname;

			const confirmMessage = (
				<div>
					{t("You are currently logged in with")}
					<span className="text-primary">{`${firstName} ${lastName}`}</span>
					{t(
						"account. Do you want to stay logged in with this account or switch to WN Social account?"
					)}
				</div>
			);

			f7.dialog.confirm(
				confirmMessage,
				"Switch Account",
				() => {
					dispatch(logoutService({ all: 0 }));
				},
				() => {
					window.history.replaceState(null, "", window.location.pathname);
					f7router.navigate(
						`${width < 775 ? "/chats/" : "/chats/null/single"}`
					);
				}
			);
		} else {
			dispatch(
				autoLoginwithPoshService({
					device_type: "",
					version_code: storedVersion,
					os_name: "",
					type: "posh",
					poshid: poshid,
					wntoken: wntoken,
					poshAccessToken: poshAccessToken,
				})
			);
		}
	}, []);

	useUpdateEffect(() => {
		if (logoutResponse?.code == 200 || logoutResponse?.code == 401) {
			socket.disconnect();
			deleteCookie(AUTHTOKEN_COOKIE);
			persistor.purge();
			db.delete()
				.then(() => {
					console.log("Database successfully deleted");
					setTimeout(() => {
						if (!db.isOpen()) {
							db.open().catch(function (err) {
								console.error("Failed to open db: " + (err.stack || err));
							});
						}
						dispatch(
							autoLoginwithPoshService({
								device_type: "",
								version_code: storedVersion,
								os_name: "",
								type: "posh",
								poshid: poshid,
								wntoken: wntoken,
								poshAccessToken: poshAccessToken,
							})
						);
					}, 1000);
				})
				.catch((err) => {
					console.error("Could not delete database");
				});
		}
	}, [logoutResponse]);

	useUpdateEffect(() => {
		if (loginResponse.code === STATUS_CODE.SUCCESS) {
			setCookie(AUTHTOKEN_COOKIE, loginResponse.data.token);
			f7.emit("loginSuccess", loginResponse.data.id); //app's internal event for re-initializing socket with authtoken
			window.history.replaceState(null, "", window.location.pathname);
			f7router.navigate(
				`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`
			);
		} else {
			//show error
			f7.toast.create({ text: loginResponse.message }).open();
			window.history.replaceState(null, "", window.location.pathname);
			f7router.navigate("/login/");
		}
	}, [loginResponse]);

	return (
		<Page name="autoPushLogin">
			<div className="h-full w-full flex flex-col items-center justify-center">
				<Preloader color="blue" />
			</div>
		</Page>
	);
};

export default AutoPoshLogin;
