import {
	Button,
	Link,
	List,
	ListInput,
	ListItem,
	NavLeft,
	NavRight,
	NavTitle,
	Navbar,
	Page,
	Popup,
	Tab,
	f7,
	Block,
	PageContent,
} from "framework7-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useDispatch, useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import useLocalStorageState from 'use-local-storage-state'
import {
	Notification,
	SecuritySafe,
	LanguageCircle,
	Forbidden,
	DeviceMessage,
	CloseCircle,
	Devices,
	Bag,
} from "iconsax-react";
import "./style.css";
import { FeedBackService } from "../../../../redux/features/userSlice/feedBack";
import {
	DeleteAccountService,
	verifyDeleteAccountOTPService,
} from "../../../../redux/features/userSlice/deleteAccount";
import OTPInput from "react-otp-input";
import { clearcache, updateApp, updateSW } from "../../../../utils/updateApp";
import { useLocalStorage } from "react-use";

const Settings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { DeleteResponse } = useSelector((state) => state.DeleteAccountSlice);

	const user = useLiveQuery(() =>
		db.users.where({ id: loginResponse?.data?.id }).first()
	);
	const { userProfileUpdateResponse, isLoading } = useSelector(
		(state) => state.profileUpdateSlice
	);
	const [feedbackPopupOpened, setFeedbackPopupOpened] = useState(false);
	const [popupOpened, setPopupOpened] = useState(false);
	const [confirmationPopupOpened, setConfirmationPopupOpened] = useState(false);
	const [otp, setOtp] = useState("");
	const [isOtpComplete, setIsOtpComplete] = useState(false);
	const [popoverOpened, setPopoverOpened] = useState(false);
	const [appVersion] = useLocalStorage("appVersion", "", { raw: true });
	const [updateState, setUpdateState] = useState("Check for update");

	const openFeedbackPopup = () => {
		setFeedbackPopupOpened(true);
	};

	const closeFeedbackPopup = () => {
		setFeedbackPopupOpened(false);
		setMessage("");
	};
	const [message, setMessage] = useState("");
	const [files, setFiles] = useState([]);

	const handleFileChange = (event) => {
		setFiles((prevFiles) => [...prevFiles, ...Array.from(event.target.files)]);
		event.target.value = null; // Reset the file input
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("message", message);
		files.forEach((file, index) => {
			formData.append(`file${index}`, file);
		});
		dispatch(FeedBackService(formData));

		f7.dialog.preloader(t("Please wait, this may take a moment"));
		setTimeout(() => {
			f7.dialog.close();
		}, 2000);
		setMessage("");
		setFeedbackPopupOpened(false);
	};
	const openDeletePopup = () => {
		setPopupOpened(true);
	};

	const closeDeletePopup = () => {
		setPopupOpened(false);
	};
	const closeDeletePopover = () => {
		setPopoverOpened(false);
	};

	const handleDelete = () => {
		dispatch(DeleteAccountService({}));
		closeDeletePopup();
		setConfirmationPopupOpened(true);
	};
	const closeConfirmationPopup = () => {
		setConfirmationPopupOpened(false);
	};
	const handleOTP = () => {
		dispatch(
			verifyDeleteAccountOTPService({
				reset_token: DeleteResponse.data.reset_token,
				code: parseInt(otp),
			})
		);
		setConfirmationPopupOpened(false);
		f7.dialog.preloader(t("Please wait, this may take a moment"));
		setTimeout(() => {
			f7.dialog.close();
		}, 1000);
		f7.dialog.alert("Account deleted");
	};
	const handleOtpChange = (otpValue) => {
		setOtp(otpValue);
		setIsOtpComplete(otpValue.length === 6); // Assuming OTP length is 6
	};

	const checkUpdate = () => {
		f7.preloader.show();
		updateApp()
			.then((update) => {
				f7.preloader.hide();
				if (update) {
					setUpdateState(`Updating`);
					f7.dialog.progress(
						`Updating ${f7params.name} to ${update.currentVersion}`
					);

					setTimeout(() => {
						localStorage.setItem("appVersion", update.currentVersion);
						update.isBroken = true;
						update.isBroken
							? db.delete().then(() => {
									clearcache();
							  })
							: clearcache();
					}, 1000);
				} else {
					console.log("App is up to date");
					setUpdateState("App is up to date");
				}
			})
			.catch((err) => {
				console.log("update", err);
			});
	};

	return (
		<Tab id="settingsTab">
			<Navbar>
				<NavLeft>
					<Link tabLink="#chatTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Settings")}
				</NavTitle>
			</Navbar>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				tabLink="#SettingNotification">
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<Notification color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{" "}
								{t("Notifications")}
							</div>
						</div>
					</div>
				</div>
			</Link>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				tabLink="#LoggedDevices">
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<Devices color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{" "}
								{t("Logged in Devices")}
							</div>
						</div>
					</div>
				</div>
			</Link>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				tabLink="#Privacy">
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<SecuritySafe color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{" "}
								{t("Privacy Setting")}
							</div>
						</div>
					</div>
				</div>
			</Link>

			{/* <div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
				<div className="flex items-start gap-[20px]">
					<div className="min-w-[24px] min-h-[24px] ">
						<MaterialIcon
							fill
							icon="palette"
							size={20}
							color={"#0e99fe"}
							className="text-[32px]"
						/>
					</div>
					<div className="flex-1">
						<div className="flex justify-between w-full">
							<p className="text-[16px] font-[400] text-[--f7-heading-color]">
								{t("Theme")}
							</p>
						</div>
					</div>
				</div>
			</div> */}
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				tabLink="#Language">
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<LanguageCircle color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{" "}
								{t("Language")}
							</div>
						</div>
					</div>
				</div>
			</Link>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				tabLink="#BlockedContacts">
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<Forbidden color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{" "}
								{t("Blocked Contacts")}
							</div>
						</div>
					</div>
				</div>
			</Link>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				onClick={openFeedbackPopup}>
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<DeviceMessage color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">{t("FeedBack")}</div>
						</div>
					</div>
				</div>
			</Link>
			<Popup opened={feedbackPopupOpened} onPopupClosed={closeFeedbackPopup}>
				<Page pageContent={false}>
					<Navbar title={t("Submit Feedback")}>
						<NavRight>
							<Link popupClose>
								<CloseCircle color="#596365" />
							</Link>
						</NavRight>
					</Navbar>
					<PageContent className="pb-0">
						<Block>
							<h2>{t("Submit Feedback")}</h2>
							<p>
								{t(
									"We love your feedback. Please write your comments in the space below."
								)}
							</p>
							<form onSubmit={handleSubmit}>
								<List noHairlinesMd>
									<ListInput
										label={t("FeedBack")}
										type="textarea"
										placeholder={t("FeedBack")}
										resizable
										maxlength="1000"
										value={message}
										onChange={(e) => setMessage(e.target.value)}
									/>
									{files.map((file, index) => (
										<ListItem key={index} title={file.name} />
									))}
									<ListItem>
										<label
											htmlFor="file-upload"
											className="inline-block cursor-pointer border bg-[color:var(--f7-md-primary)] text-white px-3 py-1.5 rounded-[10px] border-solid border-[#ccc];
">
											<span className="flex">
												<MaterialIcon
													icon="attach_file"
													size={20}
													className="text-[28px] rotate-45"
												/>
												{t("ATTACHMENT")}
											</span>
										</label>
										<input
											id="file-upload"
											type="file"
											multiple
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
									</ListItem>
								</List>
								<Button fill type="submit" disabled={!message.trim()}>
									{t("Submit")}
								</Button>
							</form>
						</Block>
					</PageContent>
				</Page>
			</Popup>
			<Link
				className="text-[16px] font-[400] text-[--f7-heading-color]"
				onClick={openDeletePopup}>
				<div className="px-[30px] py-[15px] w-[310px] h-[51px] rounded-[10px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px]">
					<div className="flex items-start gap-[20px]">
						<div className="min-w-[24px] min-h-[24px] ">
							<Bag color={"#0e99fe"} variant="Bold" icon="mail" />
						</div>
						<div className="flex-1">
							<div className="flex justify-between w-full">
								{t("Delete Account")}
							</div>
						</div>
					</div>
				</div>
			</Link>
			{appVersion && (
				<div className="absolute w-[100%] bottom-2.5 flex flex-col items-center justify-center version-info">
					{t("{{name}} - version", { name: "KT Messenger Web" })}: {appVersion}
					<Link onClick={checkUpdate} className="text-primary">
						{t(updateState)}
					</Link>
				</div>
			)}
			<Popup
				className="Delete  h-[180px] flex flex-col justify-center items-center"
				opened={popupOpened}
				onPopupClosed={closeDeletePopup}>
				<Page pageContent={false}>
					<Block>
						<h1 className="text-center font-semibold text-xl">
							{t("Delete Account")}
						</h1>
						<h3 className="text-center mt-2 font-semibold text-[15px]">
							{t("Are you sure you want to delete your account?")}
						</h3>
						<div className="flex justify-end gap-4 mt-5">
							<Button className="custom-clear-button" onClick={handleDelete}>
								{t("Yes")}
							</Button>
							<Button
								className="custom-cancel-button"
								onClick={closeDeletePopup}>
								{t("No")}
							</Button>
						</div>
					</Block>
				</Page>
			</Popup>

			<Popup
				className="Deleteotp  h-[300px] flex flex-col justify-center items-center"
				opened={confirmationPopupOpened}
				onPopupClosed={closeConfirmationPopup}
				target=".center-target"
				backdrop={false}>
				<Block>
					<h1 className="text-center font-semibold text-xl">
						{t("Delete Account")}
					</h1>
					<h2 className="text-center mt-5 font-semibold text-[15px] pb-2">
						{DeleteResponse?.data?.message}
					</h2>
					<OTPInput
						value={otp}
						onChange={handleOtpChange}
						numInputs={6}
						renderInput={(props) => <input {...props} />}
						containerStyle="input-resize flex justify-center"
					/>
					<div className="flex justify-end gap-4 mt-5">
						<Button
							className="custom-clear-button"
							onClick={handleOTP}
							disabled={!isOtpComplete} // Disable the button if OTP is not complete
						>
							{t("OK")}
						</Button>
						<Button
							className="custom-cancel-button"
							onClick={closeConfirmationPopup}>
							{t("Cancel")}
						</Button>
					</div>
				</Block>
			</Popup>
		</Tab>
	);
};

export default Settings;
