import { Block, Button, Popover } from "framework7-react";
import { Add } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  defaultProfileIcon,
  searchIcon
} from "../../../utils/icons";

function AddFilterMenu() {
  const { t: translate } = useTranslation();
  return (
    <Popover
      className="addFilterMenuOs3 min-w-[409px] ml-[0px] mt-[100px] bg-[#F1F1F1] p-4"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "409px" }}
      arrow={false}
      backdrop={true}
    >
      <Block className="flex flex-col w-[auto] gap-4 p-0 m-0">
        <Block className="flex justify-between items-center w-[100%] m-0 p-0  ">
          <p className="text-[18px] font-medium m-0">{translate("Filters")}</p>
          <Button
            className="w-[50px] h-[50px]"
            popoverClose=".addFilterMenuOs3"
          >
             <Add size={40} color="#5D6980" className="transform rotate-45" />
          </Button>
        </Block>
        <Block className="w-[100%] m-0 py-0 gap-2 px-[1rem] border bg-[#F6F6F6] border-[#E0E7EC] rounded-[60px] h-[45px] flex items-center ">
          <img className="w-[20px] h-[20px]" src={searchIcon} alt="" />
          <input className="w-[80%]" placeholder="Search..." type="search" />
        </Block>
        <Block className="p-0 m-0 flex flex-col max-h-[200px] overflow-scroll">
          <Block className="m-0 p-[0.5rem] flex items-center cursor-pointer hover:bg-[#CFEBFF]">
            <img
              className="w-[45px] h-[45px] rounded-[50%] mr-[1rem]"
              src={defaultProfileIcon}
              alt=""
            />
            <p className="text-[16px] font-medium text-[#000]">None</p>
          </Block>
          <Block className="m-0 p-[0.5rem] flex items-center cursor-pointer hover:bg-[#CFEBFF]">
            <img
              className="w-[45px] h-[45px] rounded-[50%] mr-[1rem]"
              src={defaultProfileIcon}
              alt=""
            />
            <p className="text-[16px] font-medium text-[#000]">
              Sunset Lamp{" "}
              <span className="text-[14px] font-normal text-[#302F36]">
                by Alexa Evans
              </span>
            </p>
          </Block>
          <Block className="m-0 p-[0.5rem] flex  items-center cursor-pointer hover:bg-[#CFEBFF]">
            <img
              className="w-[45px] h-[45px] rounded-[50%] mr-[1rem]"
              src={defaultProfileIcon}
              alt=""
            />
            <p className="text-[16px] font-medium text-[#000]">
              Sunset Lamp{" "}
              <span className="text-[14px] font-normal text-[#302F36]">
                by Alexa Evans
              </span>
            </p>
          </Block>
          <Block className="m-0 p-[0.5rem] flex items-center cursor-pointer hover:bg-[#CFEBFF]">
            <img
              className="w-[45px] h-[45px] rounded-[50%] mr-[1rem]"
              src={defaultProfileIcon}
              alt=""
            />
            <p className="text-[16px] font-medium text-[#000]">
              Sunset Lamp{" "}
              <span className="text-[14px] font-normal text-[#302F36]">
                by Alexa Evans
              </span>
            </p>
          </Block>
        </Block>
      </Block>
    </Popover>
  );
}

export default AddFilterMenu;
