import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	logoutResponse: {},
	isLoading: false,
	error: false,
};

export const logoutSlice = createSlice({
	name: "logout",
	initialState,
	reducers: {
		saveLogoutResponse: (state, action) => {
			state.logoutResponse = action.payload;
		},
		resetLogoutResponse: (state) => {
			state.logoutResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logoutService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(logoutService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.logoutResponse = action.payload;
			})
			.addCase(logoutService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const logoutService = createAsyncThunk("auth/logoutService", async (values) => {
	if (!values) return;

	return post("/user/logout", {
		type: "application/x-www-form-urlencoded",
		data: values,
	});
});

export const { saveLogoutResponse, resetLogoutResponse } = logoutSlice.actions;
export { logoutService };

export default logoutSlice.reducer;
