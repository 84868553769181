import { Tab, Tabs, f7 } from "framework7-react";
import React from "react";
import EditStoryTab from "../../../../components/StoriesPage/EditStoryTab";
import TabContent from "../../../../components/StoriesPage/TabContent";
import TabHeader from "../../../../components/StoriesPage/TabHeader";
import "./style.css";
import useStory from "../../../../hooks/useStory";

const Stories = ({ stateChaged }) => {
  const { postStoryHandler, t, defaultStorySetting } = useStory();

  return (
		<Tab
			id="storiesTab"
			className="flex flex-col"
			tabActive
			onTabShow={() => stateChaged("stories")}>
			<Tabs animated>
				<Tab id="userStoriesTab" tabActive>
					<>
						<TabHeader
							onClose={() => {
								f7.tab.show("#chatTab");
								stateChaged("chats");
								defaultStorySetting();
							}}
							translate={t}
						/>
						<TabContent translate={t} />
					</>
				</Tab>
				<Tab id={"makeStoryTab"} className="h-[100%] overflow-scroll">
					<EditStoryTab
						defaultStorySetting={defaultStorySetting}
						postStoryHandler={postStoryHandler}
						translate={t}
					/>
				</Tab>
			</Tabs>
		</Tab>
	);
};

export default Stories;
