import React from "react";
import { appleLoginService } from "../../../redux/features/authSlice/login";
import { useDispatch } from "react-redux";
import { Apple } from "iconsax-react";
import { Button } from "framework7-react";

const AppleLoginButton = () => {
	const dispatch = useDispatch();

	const initAppleSignIn = () => {
		window.AppleID.auth
			.signIn()
			.then((data) => {
				dispatch(
					appleLoginService({
						token: data.authorization.id_token,
						type: "apple",
					})
				);
			})
			.catch((error) => {
				console.error("apple data error", error);
				f7.toast
					.create({
						text: "Failed to login with Apple. Try again later.",
						horizontalPosition: "center",
					})
					.open();
			});
	};

	return (
		<Button className="w-[56px] h-[56px]" onClick={initAppleSignIn}>
			<Apple size={32} color="#000" variant="Bold" />
		</Button>
	);
};

export default AppleLoginButton;
