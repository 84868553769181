import { Block, f7 } from "framework7-react";
import { Send } from "iconsax-react";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ENDPOINTS } from "../../constants/socket";
import { db } from "../../js/db";
import { SocketContext } from "../../socket";

function StoryFooter({ setIsPaused, storyId, profile, userData, stories }) {
  const socket = useContext(SocketContext);
  const [userReply, setUserReply] = useState("");
  const sendMessage = () => {
    const identifier = uuidv4();
    if (storyId && userReply.length > 0) {
      const currentStory = stories?.find((story) => story?.id === storyId);
      f7.toast
        .create({
          text: "Sending...",
          position: "top",
          horizontalPosition: "right",
          closeTimeout: 10000,
        })
        .open();
      const storyJson=JSON.stringify(currentStory);
      socket.emit(
        ENDPOINTS.SEND_MESSAGE,
        JSON.stringify({
          message: userReply,
          receiver_id: userData?.user_id,
          identifier: identifier,
          group_id: identifier,
          mType: "text",
          story: storyJson,
        }),
        (response) => {
          console.log("Reply Story Status", response);
          db.chats.add({
            chat_id: response?.chat_id,
            can_send: true,
            disappearing_duration: null,
            accepted_by: null,
            durition: 0,
						firstname: userData?.firstname || userData?.name || userData?.kalam_name,
            hide_profile_picture: userData?.hide_profile_picture || false,
            hide_screenshot: null,
            image: null,
            is_muted: 0,
            is_online: 0,
            is_private_chat: 1,
            is_read: 0,
            last_message_id: null,
            lastname: userData?.lastname || null,
            message: null,
            message_sender_id: null,
            mobile: userData?.mobile || userData?.kalam_number,
            msg_type: null,
						nickname: userData?.nickname || userData?.name || userData?.kalam_name,
            owner_id: profile?.user_id,
            profile_image: userData?.profile_image || null,
            reaction: null,
            sender_id: null,
            type: "single",
            un_read_count: 0,
            unix_time: response?.unix_time,
            user_id: userData?.user_id,
          });
          f7.toast.close();
        }
      );
      setUserReply("");
      setIsPaused(false);
    }
  };

  return (
    <div>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
        className="flex items-center gap-4 absolute  w-full px-4 pb-4 bottom-0 z-[3]"
      >
        <Block
          style={{ backgroundColor: "rgba(128, 128, 128, 0.3)" }}
          className=" w-[100%] flex items-center h-[45px] m-auto rounded-[50px]"
        >
          <input
            onChange={(e) => {
              setIsPaused(true);
              setUserReply(e.target.value);
            }}
            value={userReply}
            type="text"
            placeholder="Reply to story..."
            className="font-semibold w-full px-[1rem] text-[#FFF] placeholder:text-[#FFF]"
          />
         
        </Block>
        {userReply.length > 0 && (
          <Send
            className="flex bg-[transparent] "
            style={{
              cursor: userReply.length > 0 ? "pointer" : "not-allowed",
                filter: "drop-shadow(0px 0px 3px #6f6d6d)"
            }}
            size="32"
            color="#FFF"
            variant="Bold"
            onClick={sendMessage}
          />
        )}
      </form>
    </div>
  );
}

export default StoryFooter;
