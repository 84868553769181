import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Tab,
	List,
	ListItem,
	
} from "framework7-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import languages from "../../../../../constants/languages.json";
import { ENDPOINTS } from "../../../../../constants/socket";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../../../../socket";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../../js/db";
import { saveLoginResponse } from "../../../../../redux/features/authSlice/login";

const Language = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [user, setUser] = useState(loginResponse?.data);

	useEffect(() => {
		if(!loginResponse?.data) return;
		setUser(loginResponse?.data);
	}, [loginResponse]);

	const handleLanguageChange = (e) => {
		const newLanguage = e.target.value;

		socket.emit(
			ENDPOINTS.UPDATE_SETTINGS,
			JSON.stringify({
				auto_translate: 0,
				language: newLanguage,
				user_id: loginResponse.data.id,
			})
		);
		dispatch(saveLoginResponse({ ...loginResponse, data: { ...loginResponse.data, language: newLanguage } }));
	};

	return (
		<Tab id="Language" className="page-content">
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Language Setting")}
				</NavTitle>
			</Navbar>
			<List className="m-[16px]">
				<ListItem
					title={t("App Language")}
					after={languages[i18n?.language?.split("-")[0]]}
					smartSelect
					smartSelectParams={{
						openIn: "popup",
						closeOnSelect: true,
						inputIconPosition: "end",
						cssClass: "",
						searchbar: true,
						searchbarPlaceholder: t("Search Language"),
						scrollToSelectedItem: true,
						renderItem: (item, index) => {
							const title = languages[item.value][1];
							const subtitle = languages[item.value][0];
							return `<li class="">
                                    <label class="item-radio item-radio-icon-end item-content">
                                        <input type="radio" name="radio-21a8bfca5b" value="${
																					item.value
																				}" ${
								item.selected ? "checked" : null
							} />
                                        <i class="icon icon-radio"></i>
                                        <div class="item-inner" style="flex-direction: column;align-items: start; justify-content:center;">
                                            <div class="item-title">${title}</div>
                                            ${
																							subtitle !== title
																								? `<div class="item-footer">${subtitle}</div>`
																								: ""
																						}
                                        </div>
                                    </label>
                                </li>`;
						},
					}}
				>
					<select
						value={i18n?.language}
						onChange={(e) => i18n.changeLanguage(e.target.value)}
					>
						{Object.keys(languages).map((key) => (
							<option key={key} value={key}>
								{languages[key][1]}
							</option>
						))}
					</select>
				</ListItem>

				<ListItem
					title={t("Your Language")}
					after={languages[user?.language]}
					smartSelect
					smartSelectParams={{
						openIn: "popup",
						closeOnSelect: true,
						inputIconPosition: "end",
						cssClass: "",
						searchbar: true,
						searchbarPlaceholder: t("Search Language"),
						scrollToSelectedItem: true,
						renderItem: (item, index) => {
							const title = languages[item.value][1];
							const subtitle = languages[item.value][0];

							return `<li class="">
                                    <label class="item-radio item-radio-icon-end item-content">
                                        <input type="radio" name="radio-21a8bfca5b" value="${
																					item.value
																				}" ${
								item.selected ? "checked" : null
							} />
                                        <i class="icon icon-radio"></i>
                                        <div class="item-inner" style="flex-direction: column;align-items: start; justify-content:center;">
                                            <div class="item-title">${title}</div>
                                            ${
																							subtitle !== title
																								? `<div class="item-footer">${subtitle}</div>`
																								: ""
																						}
                                        </div>
                                    </label>
                                </li>`;
						},
					}}
				>
					<select value={user?.language} onChange={handleLanguageChange}>
						{Object.keys(languages).map((key) => (
							<option key={key} value={key}>
								{languages[key][1]}
							</option>
						))}
					</select>
				</ListItem>
			</List>
		</Tab>
	);
};

export default Language;
