import { useMemo } from "react";
import TimerIcon from "../../../assets/images/icons/timer_icon.svg?react";

const StatusIcon = ({ status, dissapearing = false }) => {
    const state = useMemo(() => {
        return status?.status ? 1 : status?.last_online ? 2 : 3;
    }, [status]);

	return (
		<div
			className={`${
				state == 1
					? "bg-[#00CA5E]"
					: state == 2
					? "bg-[#FFE500]"
					: "bg-[#B9BCBE]"
			} border border-solid border-white w-[15px] h-[15px] rounded-full absolute bottom-[1px] -right-[1px] flex items-center justify-center`}>
			{dissapearing && (
				<TimerIcon
					stroke={state === 1 || state === 3 ? "white" : "#534B03"}
					fill={state === 1 || state === 3 ? "white" : "#534B03"}
					height={11}
					width={11}
				/>
			)}
		</div>
	);
};

export default StatusIcon;
