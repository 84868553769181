import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Toggle,
	Tab,
} from "framework7-react";
import React from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import useLocalStorageState from 'use-local-storage-state'

const SettingNotification = () => {
	const { t } = useTranslation();
	const [textNotification, setTextNotification] = useLocalStorageState("text-notification", {defaultValue: false});
	const [
		reactionNotification,
		setReactionNotification,
	] = useLocalStorageState("reaction-notification", { defaultValue: false });
	const [
		groupTextNotification,
		setGroupTextNotification,
	] = useLocalStorageState("group-text-notification", { defaultValue: false });
	const [
		groupReactionNotification,
		setGroupReactionNotification,
	] = useLocalStorageState("group-reaction-notification", {
		defaultValue: false,
	});

	return (
		<Tab id="SettingNotification">
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Notifications")}
				</NavTitle>
			</Navbar>
			<div className="page-content pt-0.5  flex flex-col items-center justify-start ">
				<div className="px-[30px] py-[15px] w-[310px] h-[448px] rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px] flex  gap-[10px] flex-col">
					<div className="flex justify-between">
						<p className="text-[16px] font-[700] text-[--f7-heading-color]">
							{t("Message")}
						</p>
					</div>
					<div className="flex justify-between mt-[20px]">
						<p className="text-[--f7-heading-color]  font-normal leading-[21.82px] text-left text-[16px]">
							{t("Notification tone")}
						</p>
						<div className="flex items-center cursor-pointer">
							<p className="text-[14px] text-[--f7-md-primary] font-[800]">
								{t("Tweet")}
							</p>
							{/* <MaterialIcon color={"#0e99fe"} icon="chevron_right" /> */}
						</div>
					</div>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Text messages notifications")}
									</p>
									<Toggle
										checked={textNotification}
										onChange={() => {
											setTextNotification(!textNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show previews of notifications at the top of the screen")}
								</p>
							</div>
						</div>
					</div>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Reaction notifications")}
									</p>
									<Toggle
										checked={reactionNotification}
										onChange={() => {
											setReactionNotification(!reactionNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show notifications for reactions to messages you send")}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="px-[30px] py-[15px] w-[310px] h-[532px] rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px] ml-[20px] mb-[15px] flex  gap-[10px] flex-col">
					<div className="flex justify-between">
						<p className="text-[16px] font-[700] text-[--f7-heading-color]">
							{t("Group chats")}
						</p>
					</div>
					<div className="flex justify-between mt-[20px]">
						<p className="text-[--f7-heading-color]  font-normal leading-[21.82px] text-left text-[16px]">
							{t("Notification tone")}
						</p>
						<div className="flex items-center cursor-pointer">
							<p className="text-[14px] text-[--f7-md-primary] font-[800]">
								{t("Chord")}
							</p>
							{/* <MaterialIcon color={"#0e99fe"} icon="chevron_right" /> */}
						</div>
					</div>
					<div className="flex justify-between mt-[20px]">
						<p className="text-[--f7-heading-color]  font-normal leading-[21.82px] text-left text-[16px]">
							{t("Notification tone for mentions")}
						</p>
						<div className="flex items-center cursor-pointer">
							<p className="text-[14px] text-[--f7-md-primary] font-[800]">
								{t("Chord")}
							</p>
							{/* <MaterialIcon color={"#0e99fe"} icon="chevron_right" /> */}
						</div>
					</div>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Group chats notifications")}
									</p>
									<Toggle
										checked={groupTextNotification}
										onChange={() => {
											setGroupTextNotification(!groupTextNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show previews of notifications at the top of the screen")}
								</p>
							</div>
						</div>
					</div>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Reaction notifications")}
									</p>
									<Toggle
										checked={groupReactionNotification}
										onChange={() => {
											setGroupReactionNotification(!groupReactionNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show notifications for reactions to messages you send")}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Tab>
	);
};

export default SettingNotification;
