import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { f7 } from "framework7-react";

const initialState = {
	uploadFileResponse: {},
	isLoading: false,
	error: false,
};

export const uploadFileSlice = createSlice({
	name: "uploadFile",
	initialState,
	reducers: {
		saveUploadFileResponse: (state, action) => {
			state.uploadFileResponse = action.payload;
		},
		resetUploadFileResponse: (state) => {
			state.uploadFileResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(uploadFileService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(uploadFileService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.uploadFileResponse = action.payload;
				f7.store.dispatch("setUploadProgress", -1);
			})
			.addCase(uploadFileService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const uploadFileService = createAsyncThunk(
	"chat/uploadFile",
	async (values) => {
		console.log(values);
		if (!values) return;

		return post("/chat/upload_file", {
			type: "multipart/form-data",
			data: values.data ? values.data : values,
			...(values.req_id && { req_id: values.req_id }),
		});
	}
);

export const { saveUploadFileResponse, resetUploadFileResponse } =
	uploadFileSlice.actions;
export { uploadFileService };

export default uploadFileSlice.reducer;
