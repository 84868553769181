import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from "../../assets/images/icons/Forgot.png";
import PhoneInput from "../../components/inputs/phone-input";
import { requestPasswordResetTokenService } from "../../redux/features/passwordResetSlice/requestpwdToken";
import { useTranslation } from "react-i18next";

import "./style.css";
import { STATUS_CODE } from "../../constants";
import {
	Page,
	Block,
	f7,
	List,
	Button,
	PageContent,
	Preloader,
	Link,
} from "framework7-react";
import { useUpdateEffect } from "react-use";
import MaterialIcon from "../../components/misc/materialIcon";

const ViaPIN = ({ f7router }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const deviceInfo = f7.device;
	// const [countryCode, setCountryCode] = useState();
	// const [errormessage, setErrorMessage] = useState('')
	// const [responsedata , setResponseData] = useState(null)
	const [phoneNumber, setPhoneNumber] = useState({
		phone: "",
		country: "",
		countryCode: "",
		validationError: false,
	});
	const [email, setEmail] = useState("");
	const [isValidEmail, setIsValidEmail] = useState("");
	const [username, setUsername] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const { pwdResetToken, restOTP, FRGTEmailPWD, isLoading, error } =
		useSelector((state) => state.pwdResetTokenSlice);
	// console.log('pwdResetToken', pwdResetToken?.message, error);
	const handleEmailchecked = (e) => {
		const enteredEmail = e.target.value;
		setUsername(enteredEmail);
		setIsValidEmail(enteredEmail === "" || validateEmail(enteredEmail));
		setEmail(e.target.value);
	};
	const validateEmail = (username) => {
		const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		return emailPattern.test(username);
	};

	const handleToken = () => {
		dispatch(
			requestPasswordResetTokenService({
				country_code: phoneNumber.countryCode,
				phone: phoneNumber.phone,
			})
		);
	};

	useUpdateEffect(() => {
		if (pwdResetToken.code === STATUS_CODE.SUCCESS) {
			f7.toast
				.create({ closeTimeout: 5000, text: t(pwdResetToken.message) })
				.open(); //after token received do processing here
			f7router.navigate("/NextToNumber/");
		} else {
			////f7 toast here  error
			f7.toast
				.create({ closeTimeout: 5000, text: t(pwdResetToken.message) })
				.open();
		}
	}, [pwdResetToken, error]);

	return (
		<Page name="ViaPIN" pageContent={false}>
			<AuthNavbar />

			<PageContent className="md:overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] ml-[30px]">
							<p className="flex items-center justify-start text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								<Link back>
									<MaterialIcon icon="arrow_back_ios" size={32} />
								</Link>
								{t("Forgot Password ?")}
							</p>
							<p className="leading-5 text-bases font-normal text-color-[--f7-chat-heading-color]">
								{t("Please enter Phone Number")}
							</p>
						</div>

						{/* <List className="list-none  w-[400px] mb-[0px]">
						<ListInput
                              type="text"
                               placeholder={t("Enter email address")}
								className={`input-field-normal w-full ${username && !isValidEmail > 0  ? 'invalid': ''}`}
								value={email}
								onChange={handleEmailchecked}
								onInput={(e) => {
								  setUsername(e.target.value);
                }}
                required
              >
                <div slot="media">
                  <Profile
                    color="#5d6980"
                    variant="Bold"
                    size={22}
                  />
                </div>
              </ListInput>
			  <p className=" ml-[40px] mt-[10px] error-text">{errormessage}</p>
              {username !=="" &&! isValidEmail &&(<p className=" ml-[40px] mt-[10px] error-text">Please enter a valid email (e.g. gane@email.com)</p>)} 
               {responsedata && ( <p className=" ml-[40px] mt-[10px] error-text">{JSON.stringify(responsedata,null,2)}</p>)}
							</List> */}
						<List className="list-none">
							{/* <ListItem className="mr-[-25px] mt-4 mb-4 pl-1">
								{/* <div className="w-full flex mr-[-15px] items-center gap-2">
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-0" />
									<p className="text-font-[--f7-searchbar-inline-input-font-siz]  font-[--f7-font-family] font-[--f7-list-media-item-title-font-weight] color-[--f7-chat-heading-color]">
										OR
									</p>
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-0" />
								</div> */}
							{/* </ListItem> */}
							<PhoneInput onChange={setPhoneNumber} />
						</List>
						<div className="w-[390px]">
							<Button
								onClick={handleToken}
								fill
								className="w-[100%] mt-[15px]"
								disabled={
									(!phoneNumber?.phone && !isLoading) ||
									phoneNumber.validationError
								}>
								{isLoading ? <Preloader color="white" /> : t("Continue")}
							</Button>
						</div>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default ViaPIN;
