import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
  ReportUserResponse: {},
  isLoading: false,
  error: null,
};
export const ReportUserSlice = createSlice({
  name: "ReportUser",
  initialState,
  reducers: {
    saveReportUserResponse: (state, action) => {
      state.ReportUserResponse = action.payload;
    },
    resetReportUserResponse: (state) => {
      state.ReportUserResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ReportUserService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReportUserService.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!action.payload) return;
        state.ReportUserResponse = action.payload;
      })
      .addCase(ReportUserService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const ReportUserService = createAsyncThunk(
  "user/ReportUser",
  async (values) => {
    if (!values) return;
    
		return post("/user/report_user", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
  }
);


export const { saveReportUserResponse, resetReportUserResponse } =
  ReportUserSlice.actions;

export default ReportUserSlice.reducer;

