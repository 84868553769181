import { ListItem } from "framework7-react";
import React from "react";
import "./style.css";
import { defaultProfileIcon } from "../../utils/icons";

function Story(props) {
  const {
    title,
    subTitle,
    groupTitle,
    avatar,
    isSpecial,
    isViewAble,
    onAvatarClick,
    CustomTitle,
    avatarRedius,
    ...resProps
  } = props;
  return (
    <ListItem
      className={`${groupTitle ? "isGroupTitle" : "cursor-pointer"}`}
      title={
        CustomTitle ? (
          CustomTitle
        ) : (
          <p
            className={`${
              groupTitle
                ? "font-semibold text-[14px] pl-[0rem] cursor-default"
                : "font-bold pl-[0.5rem] "
            }  text-[#302F36] leading-[19px] pt-[10px] max-w-[95%] whitespace-nowrap overflow-hidden text-ellipsis`}
          >
            {title}
          </p>
        )
      }
      subtitle={
        <p
          className={`${
            groupTitle ? "pl-[0rem]" : "pl-[0.5rem]"
          } text-[13.3px] font-normal text-[#302F36] max-w-[95%] whitespace-nowrap overflow-hidden text-ellipsis`}
        >
          {subTitle}
        </p>
      }
      {...resProps}
    >
      {!groupTitle && isViewAble ? (
        <div
          slot="media"
          className={`${
            isSpecial ? "border-[#00CA5E]" : "border-[#0E99FE]"
          } border-[2px]  p-[1px]`}
          style={{ borderRadius: avatarRedius || "50%" }}
        >
          <img
            className="w-[45px] h-[45px]"
            src={avatar || defaultProfileIcon}
            alt=""
            style={{ borderRadius: avatarRedius || "50%" }}
          />
        </div>
      ) : (
        <div slot="media">
          {!groupTitle && (
            <img
              className="w-[45px] h-[45px]"
              src={avatar || defaultProfileIcon}
              alt=""
              onClick={onAvatarClick}
              style={{ borderRadius: avatarRedius || "50%" }}
            />
          )}
        </div>
      )}
    </ListItem>
  );
}

export default Story;
