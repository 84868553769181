import { fabric } from "fabric";
import { useEffect } from "react";
import { f7, useStore } from "framework7-react";

function useCanvas() {
	const storyFile = useStore("storyFile");
	const canvas = useStore("canvas");

	useEffect(() => {
		const newCanvas = new fabric.Canvas("canvas", {
			height: 500,
			width: 500,
			selection: false,
		});
		f7.store.dispatch("setCanvas", newCanvas);
	}, []);

	useEffect(() => {
		if (storyFile && canvas) {
			const type = storyFile.type.slice(0, 5);
			if (type === "image") {
				fabric.Image.fromURL(URL.createObjectURL(storyFile), (img) => {
					img.set({
						left: 0,
						top: 0,
						scaleX: canvas.width / img.width,
						scaleY: canvas.height / img.height,
						selectable: false,
					});
					canvas.add(img);
					canvas.renderAll();
				});
			}
		}
	}, [storyFile, canvas]);

	return {};
}

export default useCanvas;
