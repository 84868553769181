import {
	Button,
	Chip,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Preloader,
	Searchbar,
	Subnavbar,
	Tab,
	f7,
} from "framework7-react";
import React, { useState, useEffect, useRef } from "react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import user from "../../../../assets/images/placeholders/user.png";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { useTranslation } from "react-i18next";

const AddParticipants = ({ onNext, selectedItems, setSelectedItems }) => {
	const { desktop } = f7.device;
	const { t, i18n } = useTranslation();
	const searchbar = useRef(null);
	const contactsList = useRef(null);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { isLoading } = useSelector((state) => state.contactSlice);
	const contacts = useLiveQuery(() =>
		db.users
			.orderBy("name")
			.filter(
				(user) =>
					user.contact_id !== 0 &&
					user.blocked === 0 &&
					user.id !== loginResponse?.data?.id
			)
			.toArray()
	);
	const handleItemClick = (item) => {
		const isSelected = selectedItems.find((chat) => chat.id === item.id);
		if (isSelected) {
			setSelectedItems(
				selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
	};
	const handleItemClickRemove = (item) => {
		setSelectedItems(
			selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
		);
	};
	const [vlData, setVlData] = useState({
		items: [],
	});
	const renderExternal = (vl, newData) => {
		setVlData({ ...newData });
	};

	const searchAll = (query, searchItems) => {
		const found = [];
		for (let i = 0; i < searchItems.length; i += 1) {
			if (
				searchItems[i]?.name ||
				searchItems[i]?.NAME ||
				`User-${searchItems[i]?.id}`
					.toLowerCase()
					.indexOf(query.toLowerCase()) >= 0 ||
				query.trim() === ""
			)
				found.push(i);
		}
		return found;
	};

	useEffect(() => {
		if (contacts?.length > 0) {
			if (contactsList.current) {
				f7.virtualList.create({
					el: contactsList.current.el,
					items: contacts,
					createUl: false,
					renderExternal,
					searchAll,
					height: "auto",
				});
			}
			if (searchbar.current) {
				f7.searchbar.create({
					el: searchbar.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
			}
		}
	}, [contacts, searchbar]);
	return (
		<Tab id="add-participants" className="h-full">
			<Navbar>
				<NavLeft>
					<Link tabLink="#contacts">
						<MaterialIcon
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Add Group Participants")}
				</NavTitle>
			</Navbar>
			<Subnavbar>
				{!isLoading && vlData.items?.length > 0 && (
					<Searchbar
						ref={searchbar}
						inline
						backdrop={false}
						disableButton={false}
						inner={false}
						placeholder={t("search")}
						className="w-full"
						searchContainer=".contact-list2"
						searchIn=".item-title"
					/>
				)}
			</Subnavbar>

			<div className="page-content pr-0">
				<div className="flex px-5 gap-2 flex-wrap ">
					{selectedItems?.map((item) => (
						<Chip
							key={item.id}
							className="bg-[--f7-navbar-bg-color] pr-5 font-[400]  rounded-[100px] text-[12px] text-[--f7-heading-color] md:text-[14px]"
							text={item.name || item.NAME || `User-${item?.id}`}>
							<LazyLoaderWraper
								slot="media"
								src={item.image ? item.image : user}
								height={24}
								width={24}
								alt=""
							/>
							<span
								onClick={() => handleItemClickRemove(item)}
								className=" cursor-pointer  relative bottom-[-8px] ml-2 mr-[-7px]">
								<MaterialIcon
									size={20}
									icon="close"
									weight={300}
									fill
									color="--f7-heading-color"
								/>
							</span>
						</Chip>
					))}
				</div>
				{isLoading && (
					<div className="flex flex-col justify-center items-center h-4/5">
						<Preloader color="#0e99fe" />
					</div>
				)}
				{!isLoading && contacts?.length ? (
					<List
						ref={contactsList}
						mediaList
						strongIos
						className="search-list contact-list2 searchbar-found">
						{vlData.items.map((contact, index) => (
							<ListItem
								key={index}
								checkbox
								checked={selectedItems?.find((item) => item.id === contact.id)}
								hideLastSeen
								onChange={() => handleItemClick(contact)}
								title={contact?.name || contact?.NAME || `User-${contact?.id}`}
								subtitle={contact.bio}
								className={`cursor-pointer ${
									contact.bio ? "" : "subtitle-center"
								}`}>
								<LazyLoaderWraper
									slot="media"
									src={contact.profile_image ? contact.profile_image : user}
									height={55}
									width={55}
									alt=""
									className="rounded-full align-bottom"
									wrapperclassname="rounded-full align-bottom"
								/>
							</ListItem>
						))}
					</List>
				) : (
					<List
						strongIos
						outlineIos
						dividersIos
						className="searchbar-not-found">
						<ListItem title="No contacts found" />
					</List>
				)}
				<List
					strongIos
					outlineIos
					dividersIos
					className="searchbar-not-found contacts-not-found2">
					<ListItem title="No contact found" />
				</List>
			</div>
			<Button
				tabLink="#group-chat-tab"
				onClick={onNext}
				className="fixed bottom-[20px] right-[30px] rounded-full flex
             items-center justify-center active-zoom z-10 w-[60px] h-[60px] bg-[--f7-md-primary] cursor-pointer">
				<MaterialIcon size={48} icon="east" fill color="white" />
			</Button>
		</Tab>
	);
};

export default AddParticipants;
