import { useLiveQuery } from "dexie-react-hooks";
import { Button, List, Panel, Popover, f7 } from "framework7-react";
import {
	Call,
	Import,
	Message,
	Notification,
	Setting2,
	Trash,
	Video,
} from "iconsax-react";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../axios/axiosClient";
import { ENDPOINTS } from "../../constants/socket";
import { db } from "../../js/db";
import ContactInfo from "../../pages/chats/panels/contact-info";
import {
	deleteStoryByID,
	updateShareURL,
} from "../../redux/features/storiesSlice";
import { removeStoryByID } from "../../redux/features/storiesSlice/currentStories";
import { SocketContext } from "../../socket";
import MaterialIcon from "../misc/materialIcon";
import "./style.css";
import DownloaderDialog from "../misc/downloaderDialog";
import { userProfileService } from "../../redux/features/userSlice/profile";

function MoreMenu({ translate, isMyStory, storyId }) {
	const userStories = useSelector((store) => store?.currentStories?.stories);
	const [userDetailPanel, setUserDetailPanel] = useState(false);
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const users = useLiveQuery(() => db?.chats?.toArray(), [], []);
	const chatId = users.find(
		(user) => user?.user_id === userStories[0]?.user_id
	)?.chat_id;

	const doCall = ({ type }) => {
		console.log("chatId", chatId);
		if (!chatId) {
			return;
		}
		f7.popover.close();
		f7.store.dispatch(
			"setCallChatId",
			// ID REQUIRED
			chatId
		);
		f7.store.dispatch("setCallType", "single");
		f7.store.dispatch("setCallDirection", "outgoing");
		f7.store.dispatch("setCallChatType", type);
		f7.store.dispatch("setShowCallScreen", true);
	};

	const onStoryDeleteHandler = () => {
		f7.toast
			.create({
				text: "Deleting...",
				position: "top",
				horizontalPosition: "right",
				closeTimeout: 10000809809,
			})
			.open();
		try {
			socket.emit(
				ENDPOINTS.DELETE_STORY,
				JSON.stringify({
					story_id: storyId,
				}),
				(response) => {
					dispatch(removeStoryByID({ id: storyId }));
					dispatch(deleteStoryByID({ id: storyId }));
					console.log("Story Delete Status", response);
				}
			);
		} catch (error) {
			console.log(error, "Somethinng Went Wrong");
		} finally {
			f7.toast.close();
			f7.popover.close();
		}
	};

	const [downloadLink, setDownloadLink] = useState(null);

	const showShareDialog = () => {
		const url = userStories.find((story) => story?.id === storyId)?.file_url;
		if (url) return true;
		return false;
	};
	const showDownloadDialog = () => {
		const url = userStories.find((story) => story?.id === storyId)?.file_url;
		if (url) return true;
		return false;
	};

	const downloadStoryHandler = () => {
		const downloadURL = userStories.find(
			(story) => story?.id === storyId
		)?.file_url;
		console.log("downloadURL", downloadURL);
		if (downloadURL && downloadURL.length > 0) {
			setDownloadLink(downloadURL);
		} else {
			console.log("Something Went Wrong");
		}
	};
	return (
		<Popover
			className="storyMenu w-[171px] ml-[-50px]"
			bgColor="rgba(128, 128, 128, 0.3)"
			verticalPosition="auto"
			style={{ width: "100px" }}
			arrow={false}
			backdrop={false}>
			{!isMyStory ? (
				<List className="text-[#FFF] bg-rgba(128, 128, 128, 0.3)">
					<Button className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("Mute")}
						<Notification size="28" color="#FFF" variant="Bold" />
					</Button>
					<Button
						onClick={() => {
							if (!chatId) {
								return;
							}
							f7.tab.show("#chatTab");
							setTimeout(() => f7.views.main.router.navigate(`/chats/${chatId}/single`), 100);
						}}
						className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2 relative">
						{translate("Message")}
						<Message size="28" color="#fff" variant="Bold" />
					</Button>
					<Button
						popupOpen={chatId && ".callscreen-popup"}
						onClick={() => doCall({ type: "audio" })}
						className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("Audio call")}
						<Call size="28" color="#FFF" variant="Bold" />
					</Button>
					<Button
						popupOpen={chatId && ".callscreen-popup"}
						onClick={() => doCall({ type: "video" })}
						className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("Video call")}
						<Video size="32" color="#FFF" variant="Bold" />
					</Button>
					{showShareDialog() && (
						<Button
							onClick={() => {
								console.log(storyId);
								dispatch(updateShareURL({ id: storyId }));
								setTimeout(() => {
									dispatch(updateShareURL({ id: 0 }));
								}, 10000);
							}}
							className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
							{translate("Share story")}
							<MaterialIcon
								className={"text-[30px]"}
								icon="share"
								fill
								color="#FFF"
							/>
						</Button>
					)}
					<Button
						onClick={() => {
							f7.popover.close();
							setUserDetailPanel(true);
						}}
						className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("View contact")}
						<Setting2 size="32" variant="Bold" />
					</Button>
				</List>
			) : (
				<List className="text-[#FFF] bg-rgba(128, 128, 128, 0.3)">
					<Button
						onClick={onStoryDeleteHandler}
						className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("Delete")}
						<Trash variant="Bold" />
					</Button>
					{showDownloadDialog() && (
						<Button
							onClick={downloadStoryHandler}
							className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
							{translate("Save")}
							<Import size="32" />
						</Button>
					)}
					{showShareDialog() && (
						<Button
							onClick={() => {
								console.log(storyId);
								dispatch(updateShareURL({ id: storyId }));
								setTimeout(() => {
									dispatch(updateShareURL({ id: 0 }));
								}, 10000);
							}}
							className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
							{translate("Share")}
							<MaterialIcon
								className={"text-[30px]"}
								icon="share"
								fill
								color="#FFF"
							/>
						</Button>
					)}
					{/* <Button className="text-[#FFF] h-[48px] font-bold text-[16px] rounded-none flex justify-end items-center gap-2">
						{translate("StorySetting")}
						<Setting2 size="32" variant="Bold" />
					</Button> */}
				</List>
			)}
			{chatId && (
				<Panel
					id="rightUserdetailPanel"
					opened={userDetailPanel && chatId}
					onPanelClosed={() => setUserDetailPanel(false)}
					containerEl="#StoriesPage"
					right
					reveal
					backdrop={false}
					className="rounded-none z-[1000]">
					{userDetailPanel && (
						<ContactInfo
							user_id={Number(userStories[0]?.user_id)}
							userMode={false}
							chat_id={Number(
								// ID REQUIRED
								chatId
							)}
						/>
					)}
				</Panel>
			)}
			<DownloaderDialog
				open={downloadLink != null}
				url={downloadLink}
				onClose={() => setDownloadLink(null)}
			/>
		</Popover>
	);
}

export default MoreMenu;
