import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	Page,
	Panel,
	List,
	ListItem,
	NavTitle,
	NavLeft,
	Navbar,
	Searchbar,
	Subnavbar,
	Preloader,
	Tab,
	Link,
	Tabs,
	PageContent,
	f7,
} from "framework7-react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import user from "../../../../assets/images/placeholders/user.png";
import { Profile2User, SearchNormal1, UserSquare } from "iconsax-react";
import { ENDPOINTS } from "../../../../constants/socket";
import { v4 as uuidv4 } from "uuid";
import { useContext } from "react";
import { SocketContext } from "../../../../socket";
import AddParticipants from "../../tabs/add-participants";
import NewGroupChat from "../../tabs/new-group-chat";
import FindFriends from "../../tabs/find-friends";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { useTranslation } from "react-i18next";

const NewChat = ({
	f7router,
	opened,
	onPanelClosed,
	selectedItems,
	setSelectedItems,
	closeNewChat,
	NavbarOverlay,
}) => {
	const searchbar = useRef(null);
	const { t, i18n } = useTranslation();
	const contactsList = useRef(null);
	const { isLoading } = useSelector((state) => state.contactSlice);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const userId = loginResponse?.data?.user_id;

	const contacts = useLiveQuery(() => db.users.orderBy("name")
	.filter(user => user.contact_id !== 0 && user.blocked === 0 && user.id !== loginResponse?.data?.id)
	.toArray());

	const [vlData, setVlData] = useState({
		items: [],
	});
	const renderExternal = (vl, newData) => {
		setVlData({ ...newData });
	};

	const searchAll = (query, searchItems) => {
		const found = [];
		for (let i = 0; i < searchItems.length; i += 1) {
			if (
				searchItems[i].name
					.toLowerCase()
					.indexOf(query.toLowerCase()) >= 0 ||
				query.trim() === ""
			)
				found.push(i);
		}
		return found;
	};
	const socket = useContext(SocketContext);

	const startChat = (contact) => {
		if (contact.chat_id) { 
			f7router.navigate(`/chats/${contact.chat_id}/single`);
		} else {
			const identifier = uuidv4();
			socket.emit(
				ENDPOINTS.START_CHAT,
				JSON.stringify({
					chat_id: 0,
					receiver_id: contact?.id,
					identifier: identifier,
					group_id: identifier,
					user_id: userId,
				}),
				(response) => {
					const addChat = () => {
						db.chats.add({
							chat_id: response,
							can_send: true,
							disappearing_duration: null,
							accepted_by: null,
							durition: 0,
							firstname:
								contact?.firstname || contact?.name || contact?.kalam_name,
							hide_profile_picture: contact?.hide_profile_picture || false,
							hide_screenshot: null,
							image: null,
							is_muted: 0,
							is_online: 0,
							is_private_chat: 1,
							is_read: 0,
							last_message_id: null,
							lastname: contact?.lastname || null,
							message: null,
							message_sender_id: null,
							mobile: contact?.mobile || contact?.kalam_number,
							msg_type: null,
							nickname:
								contact?.nickname || contact?.name || contact?.kalam_name,
							owner_id: loginResponse?.data?.user_id,
							profile_image: contact?.profile_image || null,
							reaction: null,
							sender_id: null,
							type: "single",
							un_read_count: 0,
							unix_time: Date.now() / 1000,
							user_id: contact?.id,
						});
					};
					db.chats
						.where({ chat_id: response })
						.first()
						.then((chat) => {
							if (!chat) addChat();
						})
						.catch((error) => addChat());
					f7router.navigate(`/chats/${response}/single`);
				}
			);
		}
	};
	const groupUsers = selectedItems?.map((user) => user?.id);
	const [newGroupData, setNewGroupData] = useState({
		who_can_edit: "all",
		change_group_forwarding: 1,
		users: groupUsers,
		who_can_send_message: "admins",
		owner_id: userId,
		calling_feature: 0,
		admins: [],
		group_name: "",
		description: "",
		approve_new_members: 0,
		change_main_group_sharing: 1,
	});

	useEffect(() => {
		if (contacts?.length > 0) {
			if (contactsList.current) {
				f7.virtualList.create({
					el: contactsList.current.el,
					items: contacts,
					createUl: false,
					renderExternal,
					searchAll,
					height: "auto",
				});
			}
			if (searchbar.current) {
				f7.searchbar.create({
					el: searchbar.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
				console.log("search", searchbar.current);
			}
		}
	}, [contacts, searchbar]);

	return (
		<Panel
			id="newChatPanel"
			opened={opened}
			onPanelClosed={onPanelClosed}
			containerEl="#chats"
			left
			cover
			className="w-full rounded-none z-[1000]">
			<Page name="newchat" pageContent={false}>
				<Tabs>
					<Tab id="contacts" tabActive className="h-full">
						<Navbar>
							<NavLeft>
								<Link panelClose>
									<MaterialIcon icon="arrow_back" />
								</Link>
							</NavLeft>
							<NavTitle className="text-[--f7-heading-color] font-bold">
								{t("New Chat")}
							</NavTitle>
						</Navbar>
						<Subnavbar>
							{!isLoading && vlData.items?.length > 0 && (
								<Searchbar
									ref={searchbar}
									// init={false}
									inline
									backdrop={false}
									disableButton={false}
									inner={false}
									placeholder={t("search")}
									className="w-full"
									searchContainer=".contact-list"
									searchIn=".item-title"
								/>
							)}
						</Subnavbar>

						<PageContent className="bg-white">
							<List>
								<ListItem
									title={t("New Group Chat")}
									className="cursor-pointer"
									onClick={(e) => {
										f7.tab.show("#add-participants", true);
									}}>
									<div
										slot="media"
										className="flex justify-center items-center w-[45px] h-[45px] rounded-full bg-theme">
										<Profile2User color="white" size={24} />
									</div>
								</ListItem>
								{/* <ListItem title={t("Share invite link")}>
									<div
										slot="media"
										className="flex justify-center items-center w-[45px] h-[45px] rounded-full bg-theme">
										<UserSquare color="white" size={24} />
									</div>
								</ListItem> */}
								<ListItem
									className="cursor-pointer"
									onClick={(e) => {
										f7.tab.show("#find-friends", true);
									}}
									title={t("Find friends")}>
									<div
										slot="media"
										className="flex justify-center items-center w-[45px] h-[45px] rounded-full bg-theme">
										<SearchNormal1
											color="white"
											size={24}
										/>
									</div>
								</ListItem>
							</List>
							<p className="text-[--f7-chat-heading-color] font-bold text-[14px] px-5 md:text-[18px] ">
								{t("CONTACTS ON KT MESSENGER")}
							</p>
							{isLoading && contacts?.length < 1 && (
								<div className="flex flex-col justify-center items-center h-4/5">
									<Preloader color="#0e99fe" />
								</div>
							)}
							{!isLoading && contacts?.length > 0 ? (
								<List
									ref={contactsList}
									mediaList
									strongIos
									className="search-list contact-list searchbar-found">
									{vlData.items.map((contact, index) => (
										<ListItem
											key={index}
											onClick={() => startChat(contact)}
											title={contact?.name}
											className="cursor-pointer panel-close"
											subtitle={contact?.bio}>
											<img
												slot="media"
												src={
													!contact.hide_profile_picture
														? contact.profile_image
														: user
												}
												placeholder={user}
												height={45}
												width={45}
												alt=""
												className="rounded-full align-bottom"
												wrapperclassname="rounded-full align-bottom"
											/>
										</ListItem>
									))}
								</List>
							) : (
								<List
									strongIos
									outlineIos
									dividersIos
									className="searchbar-not-found contacts-not-found">
									<ListItem title="No contacts found" />
								</List>
							)}
						</PageContent>
					</Tab>

					<AddParticipants
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
					/>

					<NewGroupChat
						newGroupData={newGroupData}
						setNewGroupData={setNewGroupData}
						f7router={f7router}
						closeNewChat={closeNewChat}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
					/>
					<FindFriends f7router={f7router} />
				</Tabs>
			</Page>
		</Panel>
	);
};

export default NewChat;
