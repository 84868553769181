import {
	Page,
	f7,
	useStore,
	Popup,
	Button,
} from "framework7-react";
import React, { useState, useEffect, useContext } from "react";
import {  useSelector } from "react-redux";
import MaterialIcon from "../../components/misc/materialIcon";
import { LISTENERS, ENDPOINTS } from "../../constants/socket";
import { SocketContext } from "../../socket";
import {
	Video,
	Call,
} from "iconsax-react";
import useSound from "use-sound";
import inComingSfx from "../../assets/sounds/incoming.mp3";
import { useTimer } from "react-timer-hook";
import userImg from "../../assets/images/placeholders/user.png";
import LazyLoaderWraper from "../misc/lazyloaderWraper";
import { useTranslation } from "react-i18next";

const InComingCall = ({ callData }) => {
	//console.log("InComingCall", callData);
	const { t, i18n } = useTranslation();
	const callRoomId = useStore("callRoomId");
	const [play, { stop, isPlaying }] = useSound(inComingSfx, { loop: true });
	const [callPopupOpened, setCallPopupOpened] = useState(false);
	const inCommingCall = useStore("inCommingCall");
	const socket = useContext(SocketContext);
	//console.log("inCommingCall", inCommingCall, isPlaying);
	let time = new Date();
	const { loginResponse } = useSelector((state) => state.loginSlice);
	time.setSeconds(time.getSeconds() + 45);
	const {
		totalSeconds,
		seconds,
		minutes,
		hours,
		days,
		isRunning,
		start,
		pause,
		resume,
		restart,
	} = useTimer({
		autoStart: true,
		expiryTimestamp: time,
		onExpire: () => {
			if (!callRoomId) {
				pause();
				closeIncommingCall();
				//endCall(0);
				setCallPopupOpened(false);
			}
		},
	});

	useEffect(() => {
		f7.on(LISTENERS.START_NEW_CALL, (response) => {
			console.log(LISTENERS.START_NEW_CALL, response);
			if (response.chatId) {
				f7.store.dispatch("setInCommingCall", response);
				if (response.chatId) {
					socket?.emit(
						ENDPOINTS.NEW_CALL_RECEIVED,
						JSON.stringify({
							chatId: response.chatId,
							callId: response.callId,
							connectedUserId: response.connectedUserId,
						}),
						async (response) => {
							console.log("socket ack", ENDPOINTS.NEW_CALL_RECEIVED, response);
						}
					);
				}
			}
		});
		f7.on(LISTENERS.CALL_ENDED, (response) => {
			console.log("socket new msg incomming CALL_ENDED", response,inCommingCall);

			if(inCommingCall && response.chatId==inCommingCall.chatId){				
				//endCall(0);
				closeIncommingCall();
				console.log("socket new msg incomming CALL_ENDED", response,inCommingCall);
			}
			
		});
		f7.on(LISTENERS.CALL_ACCEPTED, (response) => {
			console.log("socket new msg incomming CALL_ACCEPTED", response,inCommingCall);
			if(inCommingCall && response.connectedUserId==loginResponse.data.id && response.chatId==inCommingCall.chatId){				
				closeIncommingCall();
				console.log("socket new msg incomming CALL_ACCEPTED", response,inCommingCall);
			}
			
		});
		time = new Date();
		time.setSeconds(time.getSeconds() + 45);
		restart(time, true);
		console.log("inCommingCall", inCommingCall);
		if (inCommingCall != null && inCommingCall) {
			setCallPopupOpened(true);
			play();
		} else {
			setCallPopupOpened(false);
			stop();
		}
		return () => {
			f7.off(LISTENERS.START_NEW_CALL);
			f7.off(LISTENERS.CALL_ENDED);
			f7.off(LISTENERS.CALL_ACCEPTED);
			
		};
	}, [inCommingCall]);

	

	const StartCall = (mode) => {
		stop();
		if (inCommingCall) {
			socket.emit(
				ENDPOINTS.CALL_ANSWERED,
				JSON.stringify({
					chatId: inCommingCall.chatId,
					isVideo: inCommingCall.isVideo,
					callId: inCommingCall.callId,
					roomId: inCommingCall.roomId,
				}),
				function (response) {
					console.log("response", response);
				}
			);
			f7.store.dispatch("setCallChatId", parseInt(inCommingCall.chatId));
			f7.store.dispatch("setCallRoomId", inCommingCall.roomId);
			f7.store.dispatch("setCallType", mode);
			f7.store.dispatch("setCallDirection", "incoming");
			f7.store.dispatch("setCallChatType", inCommingCall.callType);
			f7.store.dispatch("setShowCallScreen", true);

			setCallPopupOpened(false);
			f7.store.dispatch("setInCommingCall", null);
		}
	};
	const StartAudioCall = () => {
		console.log("StartAudioCall", inCommingCall);
		StartCall("audio");
	};
	const StartVideoCall = () => {
		console.log("StartVideoCall", inCommingCall);
		StartCall("video");
	};
	const closeIncommingCall = () => {
		console.log("endCall", isPlaying);
		stop();		
		console.log("endCall", inCommingCall);
		setCallPopupOpened(false);
		f7.store.dispatch("setInCommingCall", null);
	};
	const endCall = () => {
		console.log("endCall ",isPlaying);
		stop();
		socket.emit(
			ENDPOINTS.END_CALL,
			JSON.stringify({
				chatId: inCommingCall?.chatId,
				manually: 1,
				isVideo: inCommingCall?.isVideo,
				callId: inCommingCall?.callId,
				roomId: inCommingCall?.roomId,
			})
		);
		console.log("endCall", inCommingCall);
		setCallPopupOpened(false);
		f7.store.dispatch("setInCommingCall", null);
	};

	return (
    <Popup
      className="incoming-call-popup"
      closeByBackdropClick={false}
      onPopupOpened={() => {
        console.log("onPopupOpened");
      }}
      opened={callPopupOpened}
      onPopupClosed={() => setCallPopupOpened(false)}
    >
      <Page className="page-incomming-call bg-[#414247]">
        <div className="w-full h-full flex flex-col items-center justify-between">
          <div className="flex h-3/4 flex-col items-center justify-center">
            <LazyLoaderWraper
              src={inCommingCall?.photoUrl}
              placeholder={userImg}
              height={100}
              width={100}
              alt=""
              className="rounded-full"
              wrapperclassname="rounded-full"
            />
            <div className="callcolor font-bold text-[32px] leading-[42.65px] text-center mt-3">
              {inCommingCall?.name}
            </div>
            <div className="callcolor font-bold text-[16px] leading-[21.82px] text-center">
              {t("Incomming Call")}
            </div>
          </div>
          <div className="flex justify-center items-start w-full h-[80px]">
            <Button
              popupOpen=".callscreen-popup"
              onClick={StartVideoCall}
              rounded
              fill
              className="w-[60px] h-[60px] bg-[#00CA5E] mx-[10px]"
            >
              <Video size="32" color="#ffffff" />
            </Button>
            <Button
              popupOpen=".callscreen-popup"
              onClick={StartAudioCall}
              rounded
              fill
              className="w-[60px] h-[60px] bg-[#00CA5E] mx-[10px]"
            >
              <Call size="32" color="#ffffff" />
            </Button>
            <Button
              onClick={endCall}
              rounded
              fill
              className="w-[60px] h-[60px] bg-[#e05047] mx-[10px]"
            >
              <MaterialIcon icon="call_end" size={24} className="text-[28px]" />
            </Button>
          </div>
        </div>
      </Page>
    </Popup>
  );
};
export default InComingCall;
