import { Block, Button, Link } from "framework7-react";
import { Add, Setting2 } from "iconsax-react";
import React from "react";
import { archiveStoryIcon } from "../../utils/icons";

function TabHeader({ translate, onClose, backIcon, disabled, tablink }) {
  return (
    <Block className="w-[100%] h-[60px] bg-[rgb(246,246,246)] flex justify-between items-center px-[1rem]">
      <Block className="flex gap-[0.5rem] items-center">
        <Button
          onClick={onClose}
          disabled={disabled}
          className="w-[40px] h-[40px]"
        >
          <Link onClick={onClose} tabLink={tablink}>
            {backIcon ? (
              backIcon
            ) : (
              <Add size={32} color="#5D6980" className="transform rotate-45" />
            )}
          </Link>
        </Button>
        <p className="text-[18px] font-semibold text-[#302F36] leading-[32px]">
          {translate("stories")}
        </p>
      </Block>
      <Block className="flex gap-[0.5rem] items-center">
        {/* <Button className="w-[40px] h-[40px]">
          <img src={archiveStoryIcon} alt="" />
        </Button>
        <Button className="w-[40px] h-[40px]">
          <Setting2 size="23" color="#5D6980" variant="Bold" />
        </Button> */}
      </Block>
    </Block>
  );
}

export default TabHeader;
