import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Toggle,
	Tab,
	PageContent,
	ListItem,
	List,
	Block,
} from "framework7-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import { SocketContext } from "../../../../../socket";
import { ENDPOINTS } from "../../../../../constants/socket";
import { useSelector } from "react-redux";

const PrivacySetting = () => {
	const { t } = useTranslation();
	const socket = useContext(SocketContext);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [privacySettings, setPrivacySettings] = useState({
		hide_address: 0,
		hide_dob: 0,
		hide_email: 0,
		hide_from_search: 0,
		hide_online_status: 0,
		hide_phone: 0,
		hide_profile_picture: 0,
		hide_seen: 0,
		hide_last_seen_at: 0,
		hide_screenshot: 0,
	});

	useEffect(() => {
		if (loginResponse?.data?.user_id || loginResponse?.data?.id) {
			PrivacyList()
		}
	}, [loginResponse, socket]);
	
const PrivacyList = ()=>{
socket?.emit(
	ENDPOINTS.GET_PRIVACY_SETTINGS,
	JSON.stringify({
		user_id: loginResponse.data.user_id || loginResponse.data.id,
	}),
	(response) => {
		console.log("PrivacySetting:", response);
		setPrivacySettings(response);
	}
);
}
	const handleChange = (updatedState, key) => {
		const value = updatedState ? 1 : 0;

		setPrivacySettings((prevState) => ({
			...prevState,
			[key]: value,
		}));
		socket?.emit(
			ENDPOINTS.UPDATE_PRIVACY_SETTING,
			JSON.stringify({
				field: key,
				value: value,
				user_id: loginResponse.data.user_id || loginResponse.data.id,
			}),
			(data) => {
				if (!data || data[key] !== value) {
					setPrivacySettings((prevState) => ({
						...prevState,
						[key]: data[key],
					}));
				}
			}
		);
	};
	
	return (
		<Tab id="Privacy" onTabShow={PrivacyList}>
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Privacy Setting")}
				</NavTitle>
			</Navbar>
			<PageContent className="pt-0.5">
				<Block className="mt-[-18px]">
					<List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
					<h3 className="text-base font-semibold leading-[21.82px] text-left text-[#302F36] pl-[26px] pt-5 pb-[5px]">{t("Personal Details Visibility")}</h3>
					<p className="pl-6 pb-2.5 text-sm font-normal leading-[19.1px] text-left text-[#5D6980]">{t("Disable any of the options to hide your information")}</p>
					<hr className="w-[80%] h-[1px] mx-auto bg-gray-100 border-0 md:w-[85%] sm:w-[85%] dark:bg-[#D4D4D4]"/>
					<ListItem className="pl-[9px] pt-5" title={t("Profile picture")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_profile_picture}
								onToggleChange={(state) =>
									handleChange(!state, "hide_profile_picture")
								}
							/>
						</ListItem>
						<ListItem className="pl-[9px]" title={t("Email address")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_email}
								onToggleChange={(state) => handleChange(!state, "hide_email")}
							/>
						</ListItem>
						<ListItem className="pl-[9px]" title={t("Phone number")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_phone}
								onToggleChange={(state) => handleChange(!state, "hide_phone")}
							/>
						</ListItem>
						<ListItem className="pl-[9px]" title={t("Address")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_address}
								onToggleChange={(state) => handleChange(!state, "hide_address")}
							/>
						</ListItem>
						</List >
						<List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
						<ListItem className="pl-[9px]" title={t("Online status")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_online_status}
								onToggleChange={(state) =>
									handleChange(!state, "hide_online_status")
								}
							/>
						</ListItem>
						<p className="pl-[22px] pb-6 text-sm font-normal leading-[19.1px] text-left text-[#5D6980]">{t("Enable this option to show you’re Online, or disable it to hide your online status.")}</p>

						</List>
						<List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
						<ListItem className="pl-[9px]" title={t("Last seen")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_last_seen_at}
								onToggleChange={(state) => handleChange(!state, "hide_last_seen_at")}
							/>
						</ListItem>
						<p className="pl-[22px] pb-4 text-sm font-normal leading-[19.1px] text-left text-[#5D6980] pr-[10px]">{t("Enable this option to show your last seen time.")}</p>

						</List>
						<List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
						<ListItem className="pl-[9px]" title={t("Message read receipts")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_seen}
								onToggleChange={(state) => handleChange(!state, "hide_seen")}
							/>
						</ListItem>
						<p className="pl-[22px] pb-4 text-sm font-normal leading-[19.1px] text-left text-[#5D6980] pr-[10px]">{t("If you turn off read receipts, senders will not be able to see when you read their messages.")}</p>

						</List>

						<List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
						<ListItem className="pl-[9px]" title={t("Search Visibility")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_from_search}
								onToggleChange={(state) =>
									handleChange(!state, "hide_from_search")
								}
							/>
						</ListItem>
						<p className="pl-[22px] pb-4 text-sm font-normal leading-[19.1px] text-left text-[#5D6980]">{t("Enable this option to appear in searches, or disable it to keep your profile concealed.")}</p>
						</List>
						{/* <List className="rounded-[20px] bg-[--f7-navbar-bg-color] settings-list mb-[-18px]">
						<ListItem className="pl-[9px]" title={t("Screenshot Privacy")}>
							<Toggle
								slot="after"
								checked={!privacySettings.hide_screenshot}
								onToggleChange={(state) =>
									handleChange(!state, "hide_screenshot")
								}
							/>
						</ListItem>
						<p className="pl-[22px] pb-4 text-sm font-normal leading-[19.1px] text-left text-[#5D6980]">{t("Enable this option to permit screenshots of your chats, or disable it to block others from capturing them.")}</p>
						</List> */}
						{/* <ListItem
							title={t("auto download all photos and videos")}
							className="bg-[--f7-navbar-bg-color]">
							<Toggle
								slot="after"
							/>
						</ListItem> */}
						{/* <ListItem title={t("allow other to take screenshot of your chats")}>
							<Toggle
								slot="after"
								checked={privacySettings.hide_screenshot}
								onToggleChange={(state) =>
									handleChange(!state, "hide_screenshot")
								}
							/>
						</ListItem> */}
					
				</Block>
			</PageContent>
		</Tab>
	);
};

export default PrivacySetting;
