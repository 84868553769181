import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	DeleteResponse: {},
    verifyDeleteAccountOTP:{},
	isLoading: false,
	error: null,
};

const DeleteAccountSlice = createSlice({
	name: "DleteAccount",
	initialState,
	reducers: {
		saveDeleteResponse: (state, action) => {
			state.DeleteResponse = action.payload;
		},
		resetDeleteResponse: (state) => {
			state.DeleteResponse = {};
		},
		saveverifyDeleteAccountOTPResponse: (state, action) => {
			state.verifyDeleteAccountOTPResponse = action.payload;
		},
		resetverifyDeleteAccountOTPResponse: (state) => {
			state.verifyDeleteAccountOTPResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(DeleteAccountService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(DeleteAccountService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.DeleteResponse = action.payload;
			})
			.addCase(DeleteAccountService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(verifyDeleteAccountOTPService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(verifyDeleteAccountOTPService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.DeleteResponse = action.payload;
			})
			.addCase(verifyDeleteAccountOTPService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

export const DeleteAccountService = createAsyncThunk(
	"user/deleteAccount",
	async (values) => {
		if(!values) return;

		return post("user/deleteAccount", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);
export const verifyDeleteAccountOTPService = createAsyncThunk(
	"user/verifyDeleteAccountOTP",
	async (values) => {
		if(!values) return;

		return post("user/verifyDeleteAccountOTP", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);
export const {
	saveDeleteResponse,
	resetDeleteResponse,
	saveverifyDeleteAccountOTPResponse,
	resetverifyDeleteAccountOTPResponse,
} = DeleteAccountSlice.actions;
export default DeleteAccountSlice.reducer;
