import { MessagesTitle } from "framework7-react";
import React, { useEffect, useRef, useState } from "react";

const DateHeader = ({
	date,
	background = false,
	firstHeader = false,
	loadMoreMessages = () => {},
}) => {
	const messageRef = useRef(null);
	const [isIntersecting, setIsIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.5, // Adjust this threshold as needed
			}
		);
		messageRef.current?.el &&
			firstHeader &&
			observer.observe(messageRef.current?.el);

		return () => {
			messageRef.current?.el &&
				firstHeader &&
				observer.unobserve(messageRef.current?.el);
		};
	}, [firstHeader]);

	useEffect(() => loadMoreMessages, [isIntersecting]);

	return (
		<MessagesTitle
			ref={messageRef}
			id={date.replace(/[\s,]+/g, "_")}
			className={`flex items-center justify-center no-bg ${
				background ? "border-b border-solid border-black/10 bg-white/40" : ""
			}`}>
			<div className="text-secondary message-title-content">{date}</div>
		</MessagesTitle>
	);
};

export default DateHeader;
