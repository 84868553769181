export const checkNotificationPermission = () => { 
    if (Notification.permission === "granted") {
        return true;
    } else if (Notification.permission === "denied") {
        return false;
    } else {
        //request permission
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                return true;
            } else {
                return false;
            }
        });
    }
}