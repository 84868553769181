import React, { useState, useEffect, useRef } from "react";
import playButton from "../../../assets/images/icons/playButton.svg";
import stopButton from "../../../assets/images/icons/stopButton.svg";
import "./style.css";
import WaveSurfer from "wavesurfer.js";

const AudioPlayer = ({ AudioURL }) => {
	const containerRef = useRef();
	const waveSurferRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);

	// Function to format time in MM:SS format
	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = Math.floor(timeInSeconds % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

  useEffect(() => {
		const loadWaveSurfer = async () => {
			const waveSurfer = WaveSurfer.create({
				container: containerRef.current,
				waveColor: "#5D6980",
				progressColor: "#37aafe",
				dragToSeek: true,
				width: "14vw",
				hideScrollbar: true,
				normalize: true,
				height: 20,
				barHeight: 10,
				barRadius: 8,
				barWidth: 3,
			});

			waveSurfer.load(AudioURL);
			waveSurfer.on("ready", () => {
				setDuration(waveSurfer.getDuration());
				waveSurferRef.current = waveSurfer;
			});
			waveSurfer.on("audioprocess", () => {
				setCurrentTime(waveSurfer.getCurrentTime());
			});
			waveSurfer.on("finish", () => {
				setIsPlaying(false);
			});

			return () => {
				waveSurfer.destroy();
			};
		};

		loadWaveSurfer();

		return () => {
			if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
			}
		};
	}, [AudioURL]);

	const togglePlay = () => {
		if (!waveSurferRef.current) return;
		if (waveSurferRef.current.isPlaying()) {
			waveSurferRef.current.pause();
		} else {
			waveSurferRef.current.play();
		}
		setIsPlaying(waveSurferRef.current.isPlaying());
	};

	return (
		<div className="WaveSurferWrap">
			<button onClick={togglePlay} type="button">
				{isPlaying ? (
					<span>
						<img
							src={stopButton}
							className="w-[20px] h-[20px]"
							alt="Stop Button"
						/>
					</span>
				) : (
					<span>
						<img
							src={playButton}
							className="w-[20px] h-[20px]"
							alt="Play Button"
						/>
					</span>
				)}
			</button>
			<div ref={containerRef} />
			<span className="audio-time">
				{isPlaying ? formatTime(currentTime) : formatTime(duration)}
			</span>
		</div>
	);
};

export default AudioPlayer;
