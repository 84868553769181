import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Page,
	Button,
	BlockTitle,
	List,
	ListInput,
	ListItem,
	f7,
	Preloader,
	PageContent,
} from "framework7-react";

import AuthNabvar from "../../components/navs/authNavbar";
import { Eye, EyeSlash, } from "iconsax-react";
import "./style.css";
import { updatePinService } from "../../redux/features/authSlice/signup";
import { useUpdateEffect } from "react-use";
import { AUTHTOKEN_COOKIE, STATUS_CODE } from "../../constants";
import { setCookie } from "../../utils/functions";

const CreatePIN = ({ f7router }) => {
	const deviceInfo = f7.device;
	const dispatch = useDispatch();
	const { signupResponse, updatePinResponse, isLoading, error } = useSelector(
		(state) => state.signupSlice
	);
	const [PINType, setPINType] = useState("password");
	const [validPIN, setvalidPIN] = useState(true);
	const [PIN, setPIN] = useState("");
	const [confirmPIN, setConfrimPIN] = useState("");
	const [validconfirmPIN, setVaildConfirmPIN] = useState(true);

	const handlechangedPinChanged = (e) => {
		const newPIN = e.target.value;
		const isValid = newPIN.lenght >= 6;
		setPIN(newPIN);
		setvalidPIN(isValid);
		if (confirmPIN) {
			const isconfirmPIN = newPIN === confirmPIN;
			setVaildConfirmPIN(isconfirmPIN);
		}
	};
	const handleConfirmPinChanged = (e) => {
		const newconfirmPIN = e.target.value;
		const isConirmValid = newconfirmPIN === PIN;
		setConfrimPIN(newconfirmPIN);
		setVaildConfirmPIN(isConirmValid);
	};

	useUpdateEffect(() => {
		if(updatePinResponse.code === STATUS_CODE.SUCCESS) {
			setCookie(AUTHTOKEN_COOKIE, signupResponse.data.token);
			f7.emit("loginSuccess", signupResponse.data.id); //app's internal event for re-initializing socket with authtoken
			f7router.navigate(`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`);
		}
	}, [updatePinResponse, signupResponse])

	return (
		<Page name="CreatePIN" pageContent={false}>
			<AuthNabvar />
			<PageContent className="flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
				<div className="w-full md:w-[400px]">
					<div className="pl-[20px]">
						<p className="text-[26px] font-boldtext-[--f7-heading-color-color] font-semibold font-[--f7-font-family]">
							Create your PIN
						</p>
						<p className="leading-5 text-bases font-normal text-color-[--f7-chat-heading-color] font-[--f7-font-family]">
							This 6-digit PIN can be used to change or reset your
							PIN
						</p>
					</div>
					<BlockTitle className="w-[345px] leading-5 text-color-[--f7-heading-color] font-bold text-sm">
						Create PIN
					</BlockTitle>

					<List className="list-none" form>
						<ListInput
							type={PINType}
							value={PIN}
							onChange={handlechangedPinChanged}
							placeholder="Enter a 6 digit PIN"
							className={`input-field-normal mt-5 ${
								!validPIN && PIN.length > 0 && PIN.length < 6
									? "invalid"
									: ""
							}`}
							required>
							<div slot="content-end" className=" cursor-pointer">
								{PINType === "password" ? (
									<Eye
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								) : (
									<EyeSlash
										color="#5d6980"
										variant="Bold"
										size={24}
									/>
								)}
							</div>
						</ListInput>
						{!validPIN && PIN.length > 0 && PIN.length < 6 && (
							<p className="w-[345px] ml-[40px] mt-[10px] error-text">
								Please enter 6 digit pin
							</p>
						)}
					</List>

					<BlockTitle className="w-[345px] leading-5 text-color-[--f7-heading-color] font-bold text-sm">
						Comfirm PIN
					</BlockTitle>
					<List>
						<List className="list-none" form>
							<ListInput
								type={PINType}
								value={confirmPIN}
								onChange={handleConfirmPinChanged}
								placeholder="Comfirm  6 digit PIN"
								
								className={`input-field-normal mt-5 ${
									!validPIN && confirmPIN.length > 0 && confirmPIN.length < 6
										? "invalid"
										: ""
								}`}
								requi
								required>
								<div
									slot="content-end"
									className=" cursor-pointer">
									{PINType === "password" ? (
										<Eye
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									) : (
										<EyeSlash
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									)}
								</div>
							</ListInput>
							{!validconfirmPIN && confirmPIN.length > 0 && (
								<p className="w-[345px] ml-[40px] mt-[10px] error-text">
									{" "}
									The PINs entered doesn't match
								</p>
							)}
						</List>
						<ListItem className="mt-5 button-container list-none mr-[-15px]">
							<Button
								onClick={() => {
									dispatch(updatePinService({ pin: PIN, token: signupResponse.data.token }));
								}}
								fill
								className="w-full">
								{isLoading ? (
									<Preloader color="white" />
								) : (
									t("Continue")
								)}
							</Button>
						</ListItem>
					</List>
				</div>
			</PageContent>
		</Page>
	);
};

export default CreatePIN;
