import { f7 } from "framework7-react";
import { post } from "../axios/axiosClient";
import { HANDSHAKE_URL } from "../constants";
import {
	decrypt,
	encrypt,
	exportKey,
	generateKeyPair,
	getPrivateKeyFromPem,
	privateDecrypt,
} from "./crypto";

export const handshake = () => {
	return new Promise(async (resolve, reject) => {
		try {
			let savedKeys = localStorage.getItem("keys");

			if (savedKeys) {
				savedKeys = JSON.parse(savedKeys);

				const privateKey = await getPrivateKeyFromPem(savedKeys.privateKey);
				const aesEncryptionKey = await privateDecrypt(
					privateKey,
					savedKeys.encryptionKey
				);

				if (aesEncryptionKey) {
					sessionStorage.setItem("encryptionKey", aesEncryptionKey);
				}

				resolve();
				return;
			}

			const keyPair = await generateKeyPair();
			const publicKey = await exportKey(keyPair.publicKey);
			const privateKey = await exportKey(keyPair.privateKey, "private");
			if (!publicKey) reject("Error exporting public key");

			try {
				const response = await post(HANDSHAKE_URL, {
					type: "application/x-www-form-urlencoded",
					data: { public_key: publicKey, type: "handshake" },
				});

				if (response?.data?.cryptoEncryptedKey) {
					localStorage.setItem(
						"keys",
						JSON.stringify({
							publicKey,
							privateKey,
							encryptionKey: response.data.cryptoEncryptedKey,
						})
					);

					const aesEncryptionKey = await privateDecrypt(
						keyPair.privateKey,
						response.data.cryptoEncryptedKey
					);

					if (aesEncryptionKey) {
						sessionStorage.setItem("encryptionKey", aesEncryptionKey);
					}

					resolve();
				} else {
					reject("Error getting encryption key");
				}
			} catch (ex) {
				console.log("handshake", ex);
				reject(ex);
			}
		} catch (ex) {
			console.log("handshake", ex);
			reject(ex);
		}
	});
};
