import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from "../../assets/images/icons/Forgot.png";
import OTPInput from "react-otp-input";
import { verifyPasswordOTPTokenService } from "../../redux/features/passwordResetSlice/requestpwdToken";

import "./style.css";

import {
	Page,
	Block,
	f7,
	List,
	Button,
	PageContent,
	Preloader,
	Link,
} from "framework7-react";
import { useUpdateEffect } from "react-use";
import { STATUS_CODE } from "../../constants";
import { useTranslation } from "react-i18next";


const NextToEmail = ({ f7router }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const deviceInfo = f7.device;
	const [phoneNumber, setPhoneNumber] = useState("");
	const [username, setUsername] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [name, setName] = useState("");
	const [inputBorderColor, setInputBorderColor] = useState("");
	const [inputValue, setInputValue] = useState("");
	const inputRef = useRef(null);
	const [otp, setOtp] = useState("");
	// const [isLoading, setIsLoading] = useState(false);
	// const [errorapi, setErrorApi] = useState('');
	const [message, setMessage] = useState("");

	const { pwdResetToken, restOTP, FRGTEmailPWD, isLoading, error } =
		useSelector((state) => state.pwdResetTokenSlice);

	//  console.log('pwdResetToken', pwdResetToken?.message, error);
	//  console.log('restOTP', restOTP?.code);
	//  console.log("FRGTEmailPWD", FRGTEmailPWD?.data.password_reset_token, error);

	useUpdateEffect(() => {
		if (
			// restOTP.code === STATUS_CODE.SUCCESS ||
			restOTP.code === STATUS_CODE.SUCCESS
		) {
			f7.toast
				.create({
					closeTimeout: 5000,
					text:  t(restOTP.message),
				})
				.open();
			f7router.navigate("/CreateNewPasswordEmail/");
		} else {
			f7.toast
				.create({
					closeTimeout: 5000,
					text:  t(restOTP.message),
				})
				.open();
		}
	}, [ restOTP, error]);

	const handleOTP = () => {
		dispatch(
			verifyPasswordOTPTokenService({
				code: parseInt(otp),
				password_reset_token: FRGTEmailPWD?.data.password_reset_token,
			})
		);
	};
	return (
		<Page name="NextToEmail" pageContent={false}>
			<AuthNavbar />
			<PageContent className="md:overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] ml-[30px] pb-[10px]">
							<p className="text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
							{t("Forgot Password ?")} 
							</p>
							<p className="leading-5 text-bases font-normal text-color-[--f7-chat-heading-color] ">
								{t(FRGTEmailPWD?.message)}
							</p>
						</div>

						<List className="flex mb-[20px] mt-[15px]">
							<OTPInput
								value={otp}
								onChange={setOtp}
								containerStyle="input-resize"
								numInputs={6}
								renderInput={(props) => <input {...props} />}
							/>
						</List>
							{/* {otp !=="" &&(<p className=" ml-[20px] mt-[10px] mb-[10px] error-text">{restOTP?.message}</p>)}  */}
						<Button
							onClick={handleOTP}
							fill
							className="w-[400px]"
							disabled={!otp || isLoading}>
							{isLoading ? (
								<Preloader color="white" />
							) : (
								t("Continue")
							)}
						</Button>
						<Link href="/login/" className="text-[var(--f7-theme-color)] text-lg mt-5">{t("Cancel")}</Link>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default NextToEmail;
