import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	getGroupDetailResponse: {},
	isLoading: false,
	error: false,
};

export const getGroupDetailSlice = createSlice({
	name: "getGroupDetail",
	initialState,
	reducers: {
		saveGetGroupDetailResponse: (state, action) => {
			state.getGroupDetailResponse = action.payload;
		},
		resetGetGroupDetailResponse: (state) => {
			state.getGroupDetailResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGroupDetailService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getGroupDetailService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.getGroupDetailResponse = action.payload;
			})
			.addCase(getGroupDetailService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const getGroupDetailService = createAsyncThunk(
	"chat/getGroupDetail",
	async (values) => {
		if (!values) return;

		return post("/chat/get_group_detail", {
			type: "application/json",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveGetGroupDetailResponse, resetGetGroupDetailResponse } = getGroupDetailSlice.actions;
export { getGroupDetailService };

export default getGroupDetailSlice.reducer;
