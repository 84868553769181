import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";

const initialState = {
	FeedBackResponse: {},
	isLoading: false,
	error: null,
};
export const FeedBackSlice = createSlice({
	name: "FeedBackService",
	initialState,
	reducers: {
		saveFeedBackResponse: (state, action) => {
			state.FeedBackResponse = action.payload;
		},
		resetFeedBackResponse: (state) => {
			state.FeedBackResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(FeedBackService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(FeedBackService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.FeedBackResponse = action.payload;
			})
			.addCase(FeedBackService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

export const FeedBackService = createAsyncThunk("user/FeedBack", async (values) => {
	if (!values) return;
	
	return post("/user/submit_feedback", {
		type: "multipart/form-data",
		data: values,
	});
});

export const { saveFeedBackResponse, resetFeedBackResponse } =
	FeedBackSlice.actions;

export default FeedBackSlice.reducer;
