import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	findFriendsResponse: {},
	isLoading: false,
	error: false,
};

const req_id = "user/findFriendsService";

export const findFriendsSlice = createSlice({
	name: "findFriends",
	initialState,
	reducers: {
		saveFindFriendsResponse: (state, action) => {
			state.findFriendsResponse = action.payload;
		},
		resetFindFriendsResponse: (state) => {
			state.findFriendsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(findFriendsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(findFriendsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.findFriendsResponse = action.payload;
			})
			.addCase(findFriendsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const findFriendsService = createAsyncThunk(req_id, async (values) => {
	if (!values) return;

	return post("/user/search", {
		type: "application/x-www-form-urlencoded",
		data: { encodedParams: await encrypt(JSON.stringify(values)) },
		req_id,
	});
});

export const { saveFindFriendsResponse, resetFindFriendsResponse } =
	findFriendsSlice.actions;
export { findFriendsService };

export default findFriendsSlice.reducer;
