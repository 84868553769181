import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
  pendingRequestsResponse: {},
  isLoading: false,
  error: false,
};

export const pendingRequestsSlice = createSlice({
  name: "pendingRequests",
  initialState,
  reducers: {
    savePendingRequestsResponse: (state, action) => {
      state.pendingRequestsResponse = action.payload;
    },
    resetPendingRequestsResponse: (state) => {
      state.pendingRequestsResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pendingRequestsService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(pendingRequestsService.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!action?.payload?.data) return;
				state.pendingRequestsResponse = action.payload;
				// storing friendRequests in index db
				db.friendRequests
					.bulkPut(action?.payload?.data)
					.then(async function (lastKey) {
						console.log("Dexie updated pendingRequests: ", lastKey);
					})
					.catch(Dexie.BulkError, function (err) {
						console.log("Dexie error while saving friendRequests : ", err);
					});
			})
      .addCase(pendingRequestsService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const pendingRequestsService = createAsyncThunk(
	"user/pendingRequests",
	async (values) => {
		if (!values) return;

		return post("/user/get_pending_requests", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { savePendingRequestsResponse, resetPendingRequestsResponse } =
  pendingRequestsSlice.actions;
export { pendingRequestsService };

export default pendingRequestsSlice.reducer;
