import { Block } from "framework7-react";
import React from "react";
import Linkify from "../components/Linkfy";
import "../style.css";

function TextStoryPreview({
  bg,
  text,
  color,
  textAlign,
  fontSize,
}) {

  return (
    <Block className="flex justify-center m-0 bg-[#2C2C2C] items-center w-[100%] min-h-[100%] flex-wrap gap-4">
      <Block className="min-w-[400px] w-[60%] min-h-[400px] max-h-[1000px] h-[85vh] bg-[#494949] rounded-[20px] p-4 flex flex-col">
        <p className="text-[#E5ECF2] text-[20px] font-semibold ">Preview</p>
        <Block className="flex justify-center m-0 bg-[#2C2C2C] items-center w-[100%] flex-1 mt-4 rounded-[20px] overflow-hidden">
          <Block
            style={{
              backgroundColor: bg || "rgba(255 ,255 , 255 ,0.2)",
              color: color || "white",
              textAlign: textAlign || "center",
            }}
            className="w-[360px] h-[100%] flex justify-center items-center"
          >
            <Linkify fontSize={fontSize} text={text} />
          </Block>
        </Block>
      </Block>
    </Block>
  );
}

export default TextStoryPreview;
