import { Block, useStore } from "framework7-react";
import React from "react";
import useCanvas from "../../../hooks/useCanvas";

function VideoStoryPreview() {
  const {} = useCanvas();
  const storyFile = useStore("storyFile");
  return (
		<Block className="flex justify-center m-0 bg-[#2C2C2C] items-center w-[100%] min-h-[100%] flex-wrap gap-4">
			<Block className="min-w-[400px] w-[60%] min-h-[400px] max-h-[1000px] h-[85vh] bg-[#494949] rounded-[20px] p-4 flex flex-col">
				<p className="text-[#E5ECF2] text-[20px] font-semibold ">Preview</p>
				<Block className="flex justify-center m-0 bg-[#2C2C2C] items-center w-[100%] flex-1 mt-4 rounded-[20px] overflow-hidden">
					{storyFile && storyFile?.type?.includes("image") ? (
						<div className="w-full h-full flex justify-center items-center">
							<canvas width={"auto"} height="auto" id="canvas" />
						</div>
					) : (
						<video
							className="max-h-full"
							autoPlay
							loop
							src={URL?.createObjectURL(storyFile)}></video>
					)}
				</Block>
			</Block>
		</Block>
	);
}

export default VideoStoryPreview;
