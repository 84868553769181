import { useLiveQuery } from "dexie-react-hooks";
import { Block, Button } from "framework7-react";
import {
  AddCircle,
  ArrowDown2,
  TextalignCenter,
  TextalignLeft,
  TextalignRight,
} from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useEyeDropper from "use-eye-dropper";
import { db } from "../../../js/db";
import { fetchMeta } from "../../../redux/features/metaSlice";
import {
  updateStoryBG,
  updateStoryText,
  updateStorytextAlign,
} from "../../../redux/features/storiesSlice/userStory";
import MaterialIcon from "../../misc/materialIcon";
import FontSizeMenu from "../menus/FontSizeMenu";

function TextStoryFeatures() {
  const { open, close, isSupported } = useEyeDropper();
  const dispatch = useDispatch();
  const fontRef = useSelector((store) => store?.userStory?.fontSize);
  const text = useSelector((store) => store?.userStory?.text);
  const [fontSize, setfontSize] = useState("Medium");
  const [BGColorPicker, setBGColorPicker] = useState(false);
  const [FontColorPicker, setFontColorPicker] = useState(false);
  const metaDatas = useLiveQuery(() => db.metaDatas.toArray(), [], []);
  const inputColorRef = useRef();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (fontRef === 24) {
      setfontSize("Small");
    } else if (fontRef === 32) {
      setfontSize("Medium");
    } else {
      setfontSize("Large");
    }
  }, [fontRef]);

  useEffect(() => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text?.split(urlRegex);
    parts.forEach((part) => {
      if (part.match(urlRegex)) {
        const url = part;
        let urlParts = url.split("/");
        let finalURL = urlParts?.[0] + "//" + urlParts?.[2] + "/";
        const linkExist = metaDatas.find((meta) => meta.url === finalURL);
        if (!linkExist) {
          dispatch(fetchMeta({ url: finalURL }));
        }
      }
    });
  }, [text]);

  return (
    <Block className="pt-[1rem] px-[1rem] flex flex-col flex-1 overflow-scroll gap-4">
      <Block className="border h-[150px] w-[100%] rounded-[20px] border-[#E0E7EC] bg-[#F6F6F6]">
        <textarea
          className="p-[1rem] h-[100%] w-[100%] text-[#5D6980] font-normal "
          name=""
          id=""
          cols="30"
          value={text}
          rows="10"
          placeholder="Start typing"
          onChange={(e) => dispatch(updateStoryText(e.target.value))}
        ></textarea>
      </Block>
      <Block className="flex justify-between">
        <Button
          onClick={() => dispatch(updateStorytextAlign("left"))}
          className="border border-[#E0E7EC] bg-[#F6F6F6] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
        >
          <TextalignLeft size="24" color="#302F36" variant="Bold" />
        </Button>
        <Button
          onClick={() => dispatch(updateStorytextAlign("center"))}
          className="border border-[#E0E7EC] bg-[#F6F6F6] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
        >
          <TextalignCenter size="24" color="#302F36" variant="Bold" />
        </Button>
        <Button
          onClick={() => dispatch(updateStorytextAlign("right"))}
          className="border border-[#E0E7EC] bg-[#F6F6F6] w-[45px] h-[45px] rounded-[16px] flex justify-center items-center"
        >
          <TextalignRight size="24" color="#302F36" variant="Bold" />
        </Button>
        <Button
          popoverOpen=".fontSizeMenu"
          className="border border-[#E0E7EC] bg-[#F6F6F6] w-[150px]  h-[45px] flex justify-between px-[1rem] items-center"
        >
          <p className="text-[14px] font-normal text-[#302F36]">{fontSize}</p>
          <ArrowDown2 size="22" color="#5D6980" variant="Bold" />
          <FontSizeMenu />
        </Button>
      </Block>
      {/* <Block className="border p-4 w-[100%] rounded-[20px] border-[#E0E7EC] bg-[#F6F6F6]">
        <p className="text-[14px] font-bold mb-2">Font Color</p>
        <CirclePicker onChange={(e) => dispatch(updateStoryFontColor(e.hex))} />
        <p className="text-[14px] font-bold mt-2">Custom</p>
        <Block className="flex justify-start items-center gap-2 mt-1">
          <AddCircle
            className="cursor-pointer"
            size="20"
            color="#5D6980"
            variant="Outline"
            onClick={() => setFontColorPicker(!FontColorPicker)}
          />
          <img
            onClick={() => setFontColorPicker(!FontColorPicker)}
            className="cursor-pointer"
            src={colorPickerIcon}
            alt=""
          />
        </Block>
        {FontColorPicker && (
          <SketchPicker
            onChange={(e) => dispatch(updateStoryFontColor(e.hex))}
          />
        )}
      </Block> */}
      <Block className="border p-4 w-[100%] rounded-[20px] border-[#E0E7EC] bg-[#F6F6F6]">
  
        <p className="text-[14px] font-bold mb-2">{translate("Backgrounds")}</p>
        <CirclePicker onChange={(e) => dispatch(updateStoryBG(e.hex))} />
        <p className="text-[14px] font-bold mt-2">{translate("Custom")}</p>
        <Block className="flex justify-start items-center gap-2 mt-1">
          <AddCircle
            className="cursor-pointer"
            size="20"
            color="#5D6980"
            variant="Outline"
            onClick={() => inputColorRef.current.click()}
          />
          <div
            onClick={async () => {
              if (isSupported()) {
                try {
                  const pickedColor = await open();
                  dispatch(updateStoryBG(pickedColor.sRGBHex));
                } catch (error) {
                  console.log("error", error);
                }
              } else {
                console.log("Feature not available in this browser");
              }
            }}
            className="cursor-pointer flex justify-center items-center"
          >
            <MaterialIcon
              className={"text-[28px]"}
              icon="colorize"
              fill
              color="#5D6980"
            />
          </div>

          <input
            ref={inputColorRef}
            onInput={(e) => dispatch(updateStoryBG(e.target.value))}
            type="color"
            className="absolute top-0 left-0 opacity-0 z-[-1]"
          />
        </Block>
      </Block>
    </Block>
  );
}

export default TextStoryFeatures;
