import $ from "dom7";
import { Messages, PageContent, f7, useStore } from "framework7-react";
import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useContext,
	useCallback,
	memo,
} from "react";
import Messagebar from "../../components/inputs/messagebar";
import Audiobar from "../../components/inputs/audiobar";
import DateHeader from "../../components/misc/DateHeader";
import RenderMessage from "../../components/messages/renderMessage";
import { useTranslation } from "react-i18next";
import conversation_img from "../../assets/images/placeholders/conversation.svg";
import hand from "../../assets/images/placeholders/hand.gif";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../js/db";
import { useUpdateEffect, useWindowSize } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { chatMessageService } from "../../redux/features/chatSlice/chatMessages";
import {
	convertToMillisecondsPrecision,
	unixTimestampToDate,
} from "../../utils/functions";
import { SocketContext } from "../../socket";
import { ENDPOINTS } from "../../constants/socket";
import { useActivate } from "react-activation";
import ChatShimmer from "../../components/misc/chatShimmer";

const SingleChatContent = memo(
	({
		id,
		type,
		f7router,
		reactionsOpen,
		msgInfoPanel,
		setSelectedMessage,
		displayMenu,
		showScrollToBottom,
		callStart,
		setMyExistingReactions,
	}) => {
		id = id !== "null" ? (id !== "posh_ai" ? parseInt(id) : id) : id;
		const [loadedFromCache, setLoadedFromCache] = useState(false);
		const [loadingState, setLoadingState] = useState("loading");
		const loadingTimeout = useRef(null);
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const socket = useContext(SocketContext);
		const { width } = useWindowSize();
		const [firstRender, setFirstRender] = useState(true);
		const [initialLoad, setInitialLoad] = useState(true);
		const allowInfinite = useRef(true);
		const [selectedLocationMessage, setSelectedLocationMessage] =
			useState(null);
		const [showMessagebar, setShowMessagebar] = useState(id !== "null");
		const [showAudiobar, setShowAudiobar] = useState(false);
		const [messagebarReason, setMessagebarReason] = useState("");
		const [, forceUpdate] = useState(0);
		const scrollBottomThreshold = useMemo(
			() => (f7.device.electron ? 1009 : 945),
			[width]
		);
		const pageContent = useRef(null);
		const messagesRef = useRef(null);
		const [pageSize, setPageSize] = useState(20);
		const [showMessagePreloader, setShowMessagePreloader] = useState(false);
		const [isLastPage, setIsLastPage] = useState(false);
		const selectedMessages = useStore("selectedMessages");
		const unseenMessages = useStore("unseenMessages");
		const showUnseen = useRef(true);
		// const [cachedUnreadMessages, setCachedUnreadMessages] = useState(null);

		const { chatMessagesResponse, isLoading, error } = useSelector(
			(state) => state.chatMessageSlice
		);
		const { loginResponse } = useSelector((state) => state.loginSlice);

		const _chat =
			id !== "null"
				? useLiveQuery(
						() => db.chats.where({ chat_id: id }).first(),
						[id],
						null
				  )
				: null;

		const chat = useMemo(() => _chat, [_chat]);

		const toggleAudioInput = () => {
			setShowMessagebar((state) => !state);
			setShowAudiobar((state) => !state);
		};

		useEffect(() => {
			if (id !== "null") {
				if (isLoading && !initialLoad) setShowMessagePreloader(true);
				else setShowMessagePreloader(false);
			}
		}, [isLoading, initialLoad]);

		const savedScrollPos = useStore("savedScrollPos");

		// useActivate(() => {
		// 	setLoadedFromCache(true);
		// 	if (savedScrollPos[id]) {
		// 		pageContent.current?.el?.scrollTo({
		// 			top: savedScrollPos[id],
		// 			left: 0,
		// 			behavior: "auto",
		// 		});
		// 		f7.store.dispatch("setSavedScrollPos", {
		// 			chatId: id,
		// 			pos: savedScrollPos[id],
		// 			offset:
		// 				pageContent.current?.el.scrollHeight -
		// 				pageContent.current?.el.scrollTop,
		// 		});
		// 	} else {
		// 		pageContent.current?.el?.scrollTo(
		// 			0,
		// 			pageContent.current?.el?.scrollHeight + 190
		// 		);
		// 		setTimeout(() => {
		// 			f7.store.dispatch("removeSavedScrollPos", id);
		// 		}, 1000);
		// 	}
		// 	dispatch(
		// 		chatMessageService({
		// 			chat_id: id,
		// 			from_search: 0,
		// 			offset: 0,
		// 			swipe_up: 0,
		// 		})
		// 	);
		// 	allowInfinite.current = true;
		// }, [savedScrollPos]);

		const [unreadCount, setUnreadCount] = useState(0);

		const _messageCount = useLiveQuery(
			async () => {
				if (id === "null") return 0;
				const c = await db.messages.where({ chat_id: id }).count();
				return c;
			},
			[id],
			0
		);

		const messageCount = useMemo(() => _messageCount, [_messageCount]);

		const _messages = useLiveQuery(async () => {
			performance.mark("query-start");
			if (id === "null") return [];
			const chatId = id;

			const [data, translatedData] = await Promise.all([
				db.messages
					.where("chat_id")
					.equals(chatId)
					.reverse()
					.limit(pageSize)
					.toArray(),
				db.translatedMessages
					.where("chat_id")
					.equals(chatId)
					.reverse()
					.limit(pageSize)
					.toArray(),
			]);

			performance.mark("query-promise-complete");

			if (data.length === 0) return [];

			const translatedMap = new Map(
				translatedData?.map((msg) => [msg.id, msg])
			);

			const mergedData = data.map((msg) => translatedMap?.get(msg.id) || msg);

			performance.mark("query-merge-translated-msgs");

			mergedData.sort((a, b) => {
				const timeDifference =
					convertToMillisecondsPrecision(parseInt(a.unix_time)) -
					convertToMillisecondsPrecision(parseInt(b.unix_time));

				if (timeDifference !== 0) {
					return timeDifference;
				}

				return a.id - b.id;
			});

			performance.mark("query-sortedby-time");

			// console.log("shouldRender render", mergedData);

			const flatList = [];
			const unreadList = [];
			let currentDate = null;
			const unreadHeaderAdded = new Set(); // Track dates added under "Unread"

			for (const message of mergedData) {
				const messages = Array.isArray(message) ? message : [message];
				const date = unixTimestampToDate(
					convertToMillisecondsPrecision(messages[0].unix_time)
				);

				const isUnread =
					(type === "single" ? message.is_read < 2 : message.is_viewed !== 1) &&
					parseInt(message.sender_id) !== loginResponse.data.id;

				if (isUnread) {
					// Add to unread list
					if (!unreadHeaderAdded.has(date)) {
						unreadList.push({
							type: "dateHeader",
							content: date,
							parent: "unread",
						});
						unreadHeaderAdded.add(date);
					}
					unreadList.push(
						...messages.map((msg) => ({
							type: "message",
							content: msg,
							parent: date,
						}))
					);
				} else {
					// Add to flatList
					if (date !== currentDate) {
						flatList.push({ type: "dateHeader", content: date });
						currentDate = date;
					}
					flatList.push(
						...messages.map((msg) => ({
							type: "message",
							content: msg,
							parent: date,
						}))
					);
				}
			}

			performance.mark("query-loop-end");

			// Add unread header and unread messages if any
			if (unreadList.length > 0) {
				flatList.push({ type: "dateHeader", content: "Unread" });
				flatList.push(...unreadList);
			}

			if (showUnseen.current || firstRender) {
				const unreadMessages = mergedData.filter(
					(msg) =>
						(type === "single" ? msg.is_read < 2 : msg.is_viewed !== 1) &&
						parseInt(msg.sender_id) !== loginResponse.data.id
				);

				setUnreadCount(unreadMessages.length);
			}

			performance.mark("query-end");
			return { groupedMessages: flatList, mergedData };
		}, [id, pageSize]);

		const messages = useMemo(
			() => _messages?.groupedMessages,
			[_messages?.groupedMessages]
		);

		useEffect(() => {
			if (!messages) return;
			performance.mark("msg-rendered");
			performance.measure(
				"time-to-initial-query",
				"query-start",
				"query-promise-complete"
			);
			performance.measure(
				"time-to-merge",
				"query-start",
				"query-merge-translated-msgs"
			);
			performance.measure("time-to-sort", "query-start", "query-sortedby-time");
			performance.measure("time-to-loop", "query-start", "query-loop-end");
			performance.measure("time-to-sort-unread", "query-start", "query-end");
			const measuresQuery = performance.getEntriesByName(
				"time-to-initial-query"
			);
			const measuresMerge = performance.getEntriesByName("time-to-merge");
			const measuresSort = performance.getEntriesByName("time-to-sort");
			const measuresLoop = performance.getEntriesByName("time-to-loop");
			const measuresEnd = performance.getEntriesByName("time-to-sort-unread");

			console.warn(
				"Time to Query Promise Fullfillment:",
				measuresQuery[0].duration
			);
			console.warn(
				"Time to Merge Translated Messages:",
				measuresMerge[0].duration
			);
			console.warn("Time to Sort by Timestamp:", measuresSort[0].duration);
			console.warn(
				"Time to Data Preparation Loop for Render:",
				measuresLoop[0].duration
			);
			console.warn("Time to Sort Unread Messages:", measuresEnd[0].duration);
			performance.clearMarks();
			performance.clearMeasures();

		}, [messages]);

		// useEffect(() => {
		// 	if (!messages) return;
		// 	if (showUnseen.current || firstRender) {
		// 		const unreadMessages = messages["unread"];
		// 		if (unreadMessages) {
		// 			setCachedUnreadMessages(unreadMessages);
		// 		}
		// 	}
		// }, [messages, showUnseen, firstRender]);

		useEffect(() => forceUpdate((n) => n + 1), [messages]);

		const _members =
			id !== "null"
				? useLiveQuery(() => db.members.where({ chat_id: id }).toArray())
				: [];

		const members = useMemo(() => _members, [_members]);

		const _users = useLiveQuery(
			async () => {
				members?.length > 0
					? await db.users
							.filter((user) => {
								return members?.map((member) => member.id).includes(user.id);
							})
							.toArray()
					: [];
			},
			[members],
			[]
		);

		const users = useMemo(() => _users, [_users]);

		useUpdateEffect(() => {
			if (!isLoading && typeof id !== "string" && id > 0) {
				socket?.emit(
					ENDPOINTS.READ_ALL_MESSAGES,
					JSON.stringify({
						chat_id: id,
						test: 2,
					})
				);
			}
		}, [isLoading, id]);

		// useEffect(() => {
		// 	if (
		// 		id === "null" ||
		// 		!pageContent.current?.el ||
		// 		loadingState !== "loaded" ||
		// 		f7router.currentRoute.query?.message_id
		// 	)
		// 		return;

		// 	const outerHeight = pageContent.current?.el?.clientHeight;
		// 	const innerHeight = messagesRef.current?.el?.clientHeight;
		// 	const outerDivScrollTop = pageContent.current?.el?.scrollTop;

		// 	if (
		// 		innerHeight === prevInnerDivHeight.current &&
		// 		fetchingNewMsg.current
		// 	) {
		// 		fetchingNewMsg.current = false;
		// 		return;
		// 	}

		// 	if (
		// 		!prevInnerDivHeight.current ||
		// 		(outerDivScrollTop === prevInnerDivHeight.current - outerHeight + 190 &&
		// 			!fetchingNewMsg.current)
		// 	) {
		// 		if (messages && messages.length > 0) {
		// 			pageContent.current?.el?.scrollTo({
		// 				top: innerHeight - outerHeight + 190,
		// 				left: 0,
		// 				behavior: "auto", //prevInnerDivHeight.current != null ? "smooth" : "auto",
		// 			});
		// 			setTimeout(() => {
		// 				f7.store.dispatch("removeSavedScrollPos", id);
		// 			}, 1000);
		// 			// f7.store.dispatch("setSavedScrollPos", {
		// 			// 	chatId: id,
		// 			// 	pos: innerHeight - outerHeight + 190,
		// 			// 	offset:
		// 			// 		pageContent.current?.el.scrollHeight -
		// 			// 		pageContent.current?.el.scrollTop,
		// 			// });
		// 			if (prevInnerDivHeight.current === 0) {
		// 				f7.store.dispatch("setChatPageMounted", id);
		// 			}
		// 			prevInnerDivHeight.current =
		// 				prevInnerDivHeight.current === null ? 0 : innerHeight;
		// 			f7.emit("scrollToBottomUpdate", false);
		// 			hideUnread(true);
		// 			forceUpdate((n) => n - 1);
		// 		} else {
		// 			fetchingNewMsg.current = true;
		// 			dispatch(
		// 				chatMessageService({
		// 					chat_id: id,
		// 					from_search: 0,
		// 					offset: 0,
		// 					swipe_up: 0,
		// 				})
		// 			);
		// 		}
		// 	} else {
		// 		// if (!messages || messages.length <= 10) {
		// 		// 	fetchingNewMsg.current = true;
		// 		// 	dispatch(
		// 		// 		chatMessageService({
		// 		// 			chat_id: id,
		// 		// 			from_search: 0,
		// 		// 			offset: 0,
		// 		// 			swipe_up: 0,
		// 		// 		})
		// 		// 	);
		// 		// }
		// 		fetchingNewMsg.current = false;
		// 	}
		// }, [messages, id, loadingState]);

		useEffect(() => {
			if (id !== "null") {
				if (id === 11) {
					setShowMessagebar(false);
					return;
				}

				if (chat && chat.type == "single") {
					if (chat.is_blocked == 1) {
						setShowMessagebar(false);
					} else {
						setShowMessagebar(true);
					}
					return;
				}
			} else setShowMessagebar(false);
		}, [members, chat, id, loginResponse, users]);

		useUpdateEffect(() => {
			if (chatMessagesResponse?.data?.is_last_page) {
				setIsLastPage(true);
				forceUpdate((n) => n + 1);
			}
		}, [chatMessagesResponse]);

		useUpdateEffect(() => {
			if (messages && messages?.length > 0 && initialLoad)
				setInitialLoad(false);

			if (messages && messages?.length > 0 && !allowInfinite.current)
				setTimeout(() => {
					allowInfinite.current = true;
				}, 1000);
			forceUpdate((n) => n + 1);
		}, [messages, initialLoad]);

		const handleScrollToBottom = (messageId = null) => {
			if (!pageContent.current.el) return;

			setTimeout(() => {
				pageContent.current.el.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				f7.emit("scrollToBottomUpdate", false);
				// const startTime = performance.now();
				// const startScrollTop = pageContent.current.el.scrollTop;

				// function scroll(timestamp) {
				// 	const elapsed = timestamp - startTime;
				// 	const progress = Math.min(elapsed / 500, 1);

				// 	// Calculate the target scroll position. Since we want to scroll to the top
				// 	// (which is visually the bottom of the content), the scroll target is 0.
				// 	const scrollTo = startScrollTop - startScrollTop * progress;
				// 	pageContent.current.el.scrollTop = scrollTo;

				// 	if (progress < 1) {
				// 		requestAnimationFrame(scroll);
				// 	} else {
				// 		// Ensure it ends exactly at the top (visually the bottom of the content)
				// 		pageContent.current.el.scrollTop = 0;
				// 	}
				// }

				// requestAnimationFrame(scroll);
			}, 50);
		};

		useEffect(() => {
			if (!f7) return;

			f7?.on("scrollToBottom", handleScrollToBottom);

			return () => {
				f7?.off("scrollToBottom", handleScrollToBottom);
			};
		}, []);

		const conditionalScrollToBottom = (messageId) => {
			if (id !== "null" && messageId) {
				if (!showScrollToBottom) {
					f7.emit("scrollToBottom");
				}
			}
		};

		useUpdateEffect(() => {
			if (!messages) return;
			allowInfinite.current = true;
		}, [messages]);

		const loadMoreMessages = useCallback(async () => {
			if (id === "null" || !allowInfinite.current) return;
			allowInfinite.current = false;
			const data = _messages?.mergedData || [];
			if (data.length === 0) return;
			if (id !== null && !isLastPage) {
				dispatch(
					chatMessageService({
						chat_id: id,
						from_search: 0,
						offset: data[0].id,
						swipe_up: 0,
					})
				);
				setPageSize((prev) => prev + 20);
			}
		}, [isLastPage, id, _messages]);

		const hideUnread = (check_bottom = false) => {
			const condition = check_bottom
				? showScrollToBottom === false && showUnseen.current
				: true;
			const timeout = check_bottom ? 5000 : 0;
			const delay = check_bottom ? 500 : 0;
			if (condition) {
				const element = document.querySelector(`[id*="_Unread_Messages"]`);
				if (element) {
					setTimeout(() => {
						delay ? element.classList.add("fade-out") : (element.hidden = true);
						setTimeout(() => {
							type === "single"
								? db.messages
										.where("chat_id")
										.equals(id)
										.and(
											(msg) =>
												msg.is_read < 2 &&
												msg.sender_id !== loginResponse.data.id
										)
										.modify((value, ref) => {
											ref.value = {
												...value,
												is_read: 2,
											};
										})
								: db.messages
										.where("chat_id")
										.equals(id)
										.and(
											(msg) =>
												msg.is_viewed < 1 &&
												msg.sender_id !== loginResponse.data.id
										)
										.modify((value, ref) => {
											ref.value = {
												...value,
												is_viewed: 1,
											};
										});
							showUnseen.current = false;
							setFirstRender(false);
							// setCachedUnreadMessages(null);
						}, delay);
					}, timeout);
				}
			} else return;
		};

		useEffect(() => {
			Object.keys(unseenMessages).includes(id) &&
				conditionalScrollToBottom(
					unseenMessages[id][unseenMessages[id]?.length - 1]
				);
		}, [unseenMessages, id]);

		useEffect(() => {
			f7 && f7.on("clearUnseen", hideUnread);

			return () => {
				f7 && f7.off("clearUnseen", hideUnread);
			};
		}, []);

		useEffect(() => {
			if (!pageContent.current?.el) return;

			$(pageContent.current?.el).on("scroll", (e) => {
				if (
					pageContent.current?.el.scrollHeight -
						pageContent.current?.el.scrollTop >
					scrollBottomThreshold
				) {
					f7.emit("scrollToBottomUpdate", true);
					showUnseen.current = true;
					forceUpdate((n) => n + 1);
				} else {
					f7.emit("scrollToBottomUpdate", false);
					forceUpdate((n) => n - 1);
					hideUnread(true);
				}
				f7.store.dispatch("setSavedScrollPos", {
					chatId: id,
					pos: pageContent.current?.el.scrollTop,
					offset:
						pageContent.current?.el.scrollHeight -
						pageContent.current?.el.scrollTop,
				});

				const container = pageContent.current?.el;
				const parent = pageContent.current?.el?.parentElement;
				if (container && parent) {
					const scrollTop = container.scrollTop || parent.scrollTop;
					const maxScrollTop =
						(container.scrollHeight || parent.scrollHeight) -
						container.clientHeight;

					const percentageFromTop = parseInt(
						Math.abs((scrollTop / maxScrollTop) * 100)
					);

					if (
						percentageFromTop > 50 &&
						_messages?.mergedData?.length < messageCount
					) {
						setPageSize((prev) => prev + 20);
					}
					if (percentageFromTop > 70) {
						loadMoreMessages();
					}
					if (percentageFromTop === 0) {
						hideUnread(true);
					}
				}
			});

			return () => {
				$(pageContent.current?.el).off("scroll");
			};
		}, [
			_messages,
			messageCount,
			scrollBottomThreshold,
			loadMoreMessages,
			setPageSize,
			hideUnread,
		]);

		useEffect(() => {
			if (selectedMessages.length > 0) {
				$(".message, .message > *").on("click", (e) => {
					e.preventDefault();
					e.stopPropagation();
					const id = parseInt(e.target.closest(".message").id);
					const groupMessage = e.target
						.closest(".message")
						.classList.contains("grouped-message");

					function handleSelection(message) {
						const index = selectedMessages.findIndex(
							(msg) => msg.id === message.id
						);
						if (index !== -1) {
							f7.store.dispatch("removeSelectedMessages", message.id);
						} else {
							if (!selectedMessages.some((msg) => msg.id === message.id)) {
								f7.store.dispatch("addSelectedMessages", message);
							}
						}
					}

					if (groupMessage) {
						db.messages
							.where("group_id")
							.equals(id.toString())
							.toArray()
							.then((msgs) => {
								msgs.forEach((msg) => {
									handleSelection(msg);
								});
							});
					} else {
						db.messages.get(id).then((msg) => {
							handleSelection(msg);
						});
					}
					forceUpdate((n) => n + 1);
				});
			}

			$(".message-bubble, .message-bubble > *").on("contextmenu", async (e) => {
				e.preventDefault();
				e.stopPropagation();
				const msg = await db.messages.get(
					parseInt(e.target.closest(".message").id)
				);
				displayMenu(msg, e);
			});

			return () => {
				$(".message, .message > *").off("click");
				$(".message-bubble, .message-bubble > *").off("contextmenu");
			};
		}, [selectedMessages]);

		useEffect(() => {
			if (id !== "null") {
				loadingTimeout.current && clearTimeout(loadingTimeout.current);

				if (messageCount > 0 && messages && messages.length > 0) {
					setLoadingState("loaded");
				} else if (
					(messages !== undefined && !isLoading && !error) ||
					(messages !== null && !isLoading && !error)
				) {
					loadingTimeout.current = setTimeout(() => {
						setLoadingState("empty");
					}, 500);
				} else if (isLoading) {
					setLoadingState("loading");
				}
			}

			return () => {
				loadingTimeout.current && clearTimeout(loadingTimeout.current);
			};
		}, [messages, messageCount, isLoading, id]);

		return (
			<PageContent
				ref={pageContent}
				messagesContent
				className={`overflow-x-hidden ${
					!messageCount ? "no-toolbar-padding" : ""
				}`}>
				{showMessagebar && (
					<Messagebar
						id={id}
						toggleAudioInput={toggleAudioInput}
						loadedFromCache={loadedFromCache}
					/>
				)}
				{showAudiobar && (
					<Audiobar id={id} toggleAudioInput={toggleAudioInput} />
				)}
				{!showMessagebar && messagebarReason && (
					<div className="toolbar messagebar md:pl-[12px] pl-[6px] md:pr-[24px] pr-[6px] py-[15px] md:gap-[20px] flex items-center justify-center">
						<span className="text-[#5D6980] text-base text-center">
							{members ? messagebarReason : t("Loading Chat...")}
						</span>
					</div>
				)}
				{id === "null" && (
					<div className="w-full h-full flex justify-center items-center">
						<div className="h-[347px] w-[321px] bg-white/40 rounded-[20px] flex flex-col justify-center items-center p-[30px] gap-y-2.5">
							<img src={conversation_img} className="w-full" />
							<span className="text-xl text-secondary font-bold">
								{f7.name} {f7.device.electron ? "desktop" : "web"}
							</span>
							<span className="text-base text-center text-secondary w-[261px]">
								{t(
									"Welcome to KT Messenger! Where every message counts. Start chatting, sharing, and connecting with your friends and family like never before."
								)}
							</span>
						</div>
					</div>
				)}
				{id !== "null" && loadingState === "loaded" && (
					<Messages
						ref={messagesRef}
						scrollMessages={false}
						scrollMessagesOnEdge={false}>
						{messages &&
							messages.map((message, index) => {
								if (message.type === "dateHeader") {
									return (
										<DateHeader
											key={`message${index}`}
											date={
												message.content.toLowerCase() !== "unread"
													? message.content
													: t(`{{count}} Unread Messages`, {
															count: unreadCount,
													  })
											}
											background={
												message.content.toLowerCase() === "unread"
													? true
													: false
											}
											firstHeader={index === 0}
											// loadMoreMessages={loadMoreMessages}
										/>
									);
								} else {
									return (
										<RenderMessage
											key={`message${index}`}
											message={message.content}
											reactions={message.content?.reactions || null}
											reactionsOpen={reactionsOpen}
											members={members}
											type={type}
											displayMenu={displayMenu}
											infoOpened={msgInfoPanel}
											setSelectedMessage={setSelectedMessage}
											conditionalScrollToBottom={conditionalScrollToBottom}
											callStart={callStart}
											selected={
												selectedMessages.find(
													(msg) => msg.id === message.content.id
												)
													? true
													: false
											}
											setMyExistingReactions={setMyExistingReactions}
										/>
									);
								}
							})}
					</Messages>
				)}

				{id !== "null" && loadingState === "empty" && (
					<div className="w-full h-full flex justify-center items-center">
						<div
							className="h-[347px] w-[321px] bg-white/50 rounded-[10px] flex flex-col justify-center items-center p-[20px] gap-y-2.5 cursor-pointer"
							onClick={() => f7.emit("sendhi", id)}>
							<span className="text-xl text-secondary font-bold">
								{/* uzair */}
								{t("No messages here yet")}
							</span>
							<span className="text-base text-center text-secondary">
								{/* uzair */}
								{t("Send a message or tap here")}
								<br />
								{/* {t("greeting below to say hi")} */}
							</span>
							<img src={hand} className="w-[125px]" />
						</div>
					</div>
				)}
				{id !== "null" && loadingState === "loading" && (
					<div className="h-full flex justify-center items-center">
						<div className="w-full h-full flex flex-col p-[51px] gap-[40px]">
							<ChatShimmer width="360px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="77px" />
							<ChatShimmer width="333px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="54px" />
							<ChatShimmer width="333px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="130px" />
						</div>
					</div>
				)}
			</PageContent>
		);
	}
);

export default SingleChatContent;
