export const VERSION = "1.0.8";
import urlJoin from "url-join";
import { v4 as uuidv4 } from "uuid";

export const MODE = import.meta.env.MODE ? import.meta.env.MODE : "development";
export const COOKIE_PREFIX = `ktm_${MODE}`;
export const AUTHTOKEN_COOKIE = `authtoken_${MODE}`;

export const PROTOCOL =
	process.env.NODE_ENV != "production" ? location.protocol : "https:";

export const BASE_URL =
	MODE == "production"
		? `${PROTOCOL}//api.ktmessenger.com`
		: MODE == "staging"
		? `${PROTOCOL}//staging-api.kalamtime.com`
		: `${PROTOCOL}//api.ktmessenger.com`;

export const PUBLIC_BASE_URL =
	MODE == "production"
		? `${PROTOCOL}//public-api.ktmessenger.com`
		: MODE == "staging"
		? `${PROTOCOL}//staging-public-api.kalamtime.com`
		: `${PROTOCOL}//public-api.ktmessenger.com`;

export const API_URL = urlJoin(BASE_URL, "api");

export const SOCKET_URL = BASE_URL;
export const SOCKET_PUBLIC_URL = PUBLIC_BASE_URL;

export const UPDATE_URL =
	MODE == "production"
		? `${PROTOCOL}//api.ktmessenger.com/check-update`
		: MODE == "staging"
		? `${PROTOCOL}//staging-api.kalamtime.com/check-update`
		: `${PROTOCOL}//api.ktmessenger.com/check-update`;

export const HANDSHAKE_URL =
	MODE == "production"
		? `${PROTOCOL}//api.ktmessenger.com/api/user/handshake`
		: MODE == "staging"
		? `${PROTOCOL}//staging-api.kalamtime.com/api/user/handshake`
		: `${PROTOCOL}//api.ktmessenger.com/api/user/handshake`;
		
export const CALL_SOCKET_URL = "https://room-call.ogoul.com";

export const AI_URL = "https://gpt.ogoul.com";

export const DB_NAME = `KTMDB_${MODE}`;

export const STATUS_CODE = Object.freeze({
	SUCCESS: 200,
	UNAUTHORIZED: 404,
});

console.log(
	MODE,
	COOKIE_PREFIX,
	AUTHTOKEN_COOKIE,
	PROTOCOL,
	BASE_URL,
	SOCKET_URL,
	DB_NAME
);

export const REACTIONS = {
	like: "1f44d",
	heart: "2764-fe0f",
	smile: "1f603",
	excited: "1f973",
	laugh: "1f923",
	cry: "1f622",
	angry: "1f621",
	sad: "1f614",
	thanks: "1f64f",
	dislike: "1f44e",
};

export const APPLE_SDK = {
	clientId: "com.posh.ktmwebapp",
	scope: "email name",
	redirectURI: "https://bb46-103-88-216-250.ngrok-free.app",
	state: "login with apple",
	nonce: uuidv4(),
	usePopup: true,
};
