import React from 'react'

function DownloadIconSvg({ stroke , width , height }) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6499 14.6001L14.8499 17.8001L18.0499 14.6001"
        stroke={stroke}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8496 5V17.7125"
        stroke={stroke}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 15.2251C25 20.7501 21.25 25.2251 15 25.2251C8.75 25.2251 5 20.7501 5 15.2251"
        stroke={stroke}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIconSvg
