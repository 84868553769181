import React, {
	memo,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useState,
} from "react";
import { ListItem, Badge, f7, useStore, Button } from "framework7-react";
import MaterialIcon from "../../misc/materialIcon";
import MsgStatusIcon from "../../misc/msgStatusIcon";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import StatusIcon from "../../misc/statusIcon";
import group from "../../../assets/images/placeholders/group.png";
import userimage from "../../../assets/images/placeholders/user.png";
import "./style.css";
import { ENDPOINTS, LISTENERS } from "../../../constants/socket";
import { SocketContext } from "../../../socket";
import {
	addPronoun,
	formatTimestamp,
	isJson,
	removeMD,
} from "../../../utils/functions";
import { db } from "../../../js/db";
import { useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import notesicon from "../../../assets/images/icons/notes-icon.svg";
import { useTranslation } from "react-i18next";

import { CallCalling } from "iconsax-react";
import moment from "moment";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";

const RecentChatItem = ({
	id,
	userId,
	owner,
	index,
	type,
	selected,
	checked,
	highlightTitle = false,
	searchTerm = "",
	draft = false,
	onClick = () => {}
}) => {
	id = id ? (id !== "posh_ai" ? parseInt(id) : id) : null;
	type = type || "single";
	const socket = useContext(SocketContext);
	const [lastEffectedMessageId, setLastEffectedMessageId] = useState(null);
	const { t, i18n } = useTranslation();
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const [message, setMessage] = useState("");
	const callChatId = useStore("callChatId");
	const typing = useStore("typing");
	const [typingText, setTypingText] = useState("");
	const userStatus = useStore("userStatus");
	const [, forceUpdate] = useState(0);

	useEffect(() => {
		if (typing && Object.keys(typing).length > 0 && typing[id]) {
			const _typing = typing[id];
			if (_typing && _typing instanceof Array) {
				if (_typing?.length > 1) {
					const typingText = _typing?.map((data) => data.user?.split(" ")[0]);
					setTypingText(typingText.join(", ") + "is " + t("typing..."));
				} else {
					setTypingText(
						_typing[0]?.user?.split(" ")[0] + " is " + t("typing...")
					);
				}
			} else if (_typing) {
				setTypingText(t("typing..."));
			} else {
				setTypingText("");
			}
		} else {
			setTypingText("");
		}
	}, [typing]);

	// useEffect(() => {
	// 	if (!activeConversation) return;
	// 	if (activeConversation === index) {
	// 		setSelected(true);
	// 	} else {
	// 		setSelected(false);
	// 	}
	// 	forceUpdate((n) => n + 1);
	// }, [index, activeConversation]);

	useEffect(() => {
		console.log("drafts index", index, selected);
		forceUpdate((n) => n + 1);
	}, [index, selected]);

	useEffect(() => {
		userId &&
			userId !== loginResponse.data.id &&
			userId !== "posh_ai" &&
			socket.emit(
				ENDPOINTS.CHECK_USER_STATUS,
				JSON.stringify({
					user_id: userId,
				}),
				(data) => {
					f7.store.dispatch("setUserStatus", data);
				}
			);
	}, [userId]);

	const chat = useLiveQuery(
		async () => {
			const data = await db.chats.where({ chat_id: id }).first();
			if (!data) return {};
			if (loginResponse?.data?.chat_id && id === loginResponse?.data?.chat_id) {
				return {
					...data,
					nickname: t("My Notes"),
					profile_image: notesicon,
					is_read: 2,
					un_read_count: 0,
					is_muted: 0,
					chat_id: id,
				};
			} else return data;
		},
		[id],
		{}
	);

	useLayoutEffect(() => {
		if (draft) return;
		if (chat?.message) {
			if (isJson(chat?.message)) {
				const subtitleJson = JSON.parse(chat?.message);
				if (chat.msg_type === "mms") {
					const subtitle = `${
						loginResponse.data.id === parseInt(subtitleJson.id)
							? t("You")
							: subtitleJson.firstname
					} ${t("shared an MMS")}`;
					setMessage(subtitle);
				} else if (subtitleJson.type === "create") {
					const subtitle = `${
						loginResponse.data.id === parseInt(subtitleJson.id)
							? t("You")
							: subtitleJson.firstname
					} ${t("created this group")}`;
					setMessage(subtitle);
				} else if (subtitleJson.type === "remove") {
					const subtitle = `${
						loginResponse.data.id === parseInt(subtitleJson.id)
							? t("You")
							: subtitleJson.sender_name
					} ${t("removed")} ${subtitleJson.firstname}`;
					setMessage(subtitle);
				} else if (subtitleJson.type === "add") {
					const subtitle = `${
						loginResponse.data.id === parseInt(subtitleJson.id)
							? t("You")
							: subtitleJson.sender_name
					} ${t("added in the Group")}`;
					setMessage(subtitle);
				} else if (subtitleJson.type === "left") {
					const subtitle = `${
						loginResponse.data.id === parseInt(subtitleJson.id)
							? t("You")
							: subtitleJson.firstname
					} ${t("left the Group")}`;
					setMessage(subtitle);
				} else if (
					subtitleJson?.disappear === "0" ||
					subtitleJson?.disappear === 0
				) {
					const subtitle =
						subtitleJson?.id === loginResponse.data.id
							? t("You turned off disappearing messages")
							: subtitleJson.firstname +
							  " " +
							  t("turned off disappearing messages");
					setMessage(subtitle);
				} else if (
					subtitleJson?.disappear === "1" ||
					subtitleJson?.disappear === 1
				) {
					const durationHumanized = moment
						.duration(subtitleJson.duration, "minutes")
						.humanize();
					const commonMessage = t(
						"turned on disappearing messages. New messages will disappear from this chat"
					);
					const dynamicPart = `${commonMessage} ${durationHumanized} ${t(
						"after they're sent, except when kept"
					)}`;

					const subtitle =
						subtitleJson?.id === loginResponse.data.id
							? `${t("You")} ${dynamicPart}`
							: `${subtitleJson.firstname} ${dynamicPart}`;

					setMessage(subtitle);
				}
			} else {
				const subtitle_prefix =
					chat.type === "single"
						? ""
						: loginResponse.data.id == chat.message_sender_id
						? "You: "
						: chat.firstname + ": ";
				const subtitle =
					chat.msg_type === "text"
						? chat?.message.replace(
								/@\[([^\]]+)\]\((\d+)\)/g,
								(match, name, id) => `@${name}`
						  )
						: chat.msg_type === "location"
						? "Shared a location"
						: chat?.message?.length > 0
						? chat?.message
						: `Shared ${addPronoun(chat.msg_type)}`;
				setMessage(removeMD(subtitle_prefix + subtitle));
			}
		} else {
			const subtitle_prefix =
				chat.type === "single"
					? ""
					: loginResponse.data.id == chat.message_sender_id
					? "You: "
					: chat.firstname + ":";
			if (chat?.last_message_id) {
				/(block)/gi.test(chat.msg_type)
					? setMessage(`${chat.msg_type}ed`)
					: setMessage(
							`${subtitle_prefix} Shared ${addPronoun(chat?.msg_type)}`
					  );
			} else {
				setMessage(`Started new chat`);
			}
		}
	}, [chat, draft, selected]);

	useEffect(() => {
		f7.on(LISTENERS.NEW_MESSAGE, (response) => {
			console.log("socket new msg recentchat", id, response.chat_id);
			setLastEffectedMessageId(response.id);
		});
		return () => {
			f7.off(LISTENERS.NEW_MESSAGE);
		};
	}, [lastEffectedMessageId, selected, index]);

	const highlightText = (text) => {
		if (!highlightTitle) return text;

		const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

		const highlightedText = parts.map((part, index) => {
			if (part.toLowerCase() === searchTerm.toLowerCase()) {
				return (
					<span key={index} className="text-kt-blue">
						{part}
					</span>
				);
			}
			return part;
		});

		return highlightedText;
	};
	const escapeHtml = (unsafe) => {
		return unsafe.replace(/&/g, "&amp;")
					 .replace(/</g, "&lt;")
					 .replace(/>/g, "&gt;")
					 .replace(/"/g, "&quot;")
					 .replace(/'/g, "&#039;");
	  };
	  
	const getSubtitleIndent = useCallback(() => {
		let indent = 0;
		if (callChatId === id) indent += 1;
		if (chat?.is_muted) indent += 1;
		if (chat?.un_read_count !== 0) indent += 1;
		return indent;
	}, [callChatId, chat, id]);

	if (chat && (chat?.chat_id > 0 || chat?.chat_id === "posh_ai")) {
		return (
			<ListItem link
				noChevron
				// link={`/chats/${id}/${type}`}
				selected={selected}
				checked={checked}
				className={`cursor-pointer recent-chat-item py-0`}
				title={highlightText(
					chat?.nickname
						? chat?.nickname
						: chat?.firstname + " " + chat?.lastname
				)}
				subtitle={
					typingText ? (
						<span>{typingText}</span>
					) : draft && !selected ? (
						<span>
							<span className="text-primary font-bold align-super">Draft:</span>{" "}
							
							<MarkdownPreview
								source={escapeHtml(draft.text)}
								rehypePlugins={[rehypeSanitize]}
								className={`${
									getSubtitleIndent() > 2
										? "pw-150"
										: getSubtitleIndent() === 1
										? "pw-185"
										: ""
								}`}
							/>
						</span>
					) : (
						message && (
							<>
								{owner &&
									id !== loginResponse?.data?.chat_id &&
									id !== "posh_ai" &&
									!/(block)/gi.test(chat.msg_type) && (
										<MsgStatusIcon status={chat?.is_read} />
									)}{" "}
								<MarkdownPreview
									source={escapeHtml(message)}
									disableHtml={true}
									rehypePlugins={[rehypeSanitize]}
									className={`${
										getSubtitleIndent() > 2
											? "pw-150"
											: getSubtitleIndent() === 1
											? "pw-185"
											: ""
									}`}
								/>
							</>
						)
					)
				}
				onClick={() => onClick(index, id, type)}>
				<div slot="media" className="h-[45px] relative">
					<>
						<LazyLoaderWraper
							src={
								!chat?.hide_profile_picture
									? chat?.nickname === "My Notes"
										? notesicon
										: type === "single"
										? chat?.profile_image || userimage
										: chat?.profile_image || group
									: type === "single"
									? chat?.profile_image || userimage
									: chat?.profile_image || group
							}
							placeholder={type === "single" ? userimage : group}
							height={45}
							width={45}
							alt=""
							className="rounded-full object-cover"
						/>

						{type === "single" &&
							id !== loginResponse.data.chat_id &&
							id !== 11 &&
							id !== "posh_ai" && (
								<StatusIcon
									status={userStatus[userId]}
									dissapearing={chat?.disappearing_duration}
								/>
							)}
					</>
				</div>
				<div
					slot="after"
					className="h-[47px] flex flex-col justify-between items-end">
					{chat?.unix_time && (
						<span className="">{formatTimestamp(chat?.unix_time)}</span>
					)}
					<div className="flex justify-evenly grow-0 items-center mt-[-18px]">
						{chat?.un_read_count !== 0 && (
							<Badge color="blue">{chat?.un_read_count}</Badge>
						)}
						{/* {pinned && (
							<img src={thumbtack} className="w-[20px] thumbtack" />
						)} */}
						{chat?.is_muted ? (
							<MaterialIcon icon="volume_off" fill color="#A0A0A0" />
						) : null}
						{callChatId === id && (
							<Button
								popupOpen=".callscreen-popup"
								className="h-[30px] p-0 w-[24px]">
								<CallCalling size="20" color="#00ca5e" />
							</Button>
						)}
					</div>
				</div>
			</ListItem>
		);
	}
};

export default RecentChatItem;
