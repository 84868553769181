import $ from "dom7";
import React, {
	useRef,
	useState,
	useContext,
	useEffect,
	useMemo,
	useCallback,
	memo,
} from "react";
import { useWindowSize } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import { useSelector, useDispatch } from "react-redux";
import {
	Navbar,
	Page,
	Button,
	f7,
	NavLeft,
	NavRight,
	useStore,
	Panel,
	Popover,
	Popup,
	SkeletonText,
	SkeletonAvatar,
	Fab,
	Badge,
	Toggle,
	Toolbar,
	Link,
	Tabs,
	Tab,
	List,
	ListItem,
} from "framework7-react";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import Lightbox from "../../components/modals/lightbox";
import LazyLoaderWraper from "../../components/misc/lazyloaderWraper";
import { Call, Setting2, Video } from "iconsax-react";
import { REACTIONS } from "../../constants";
import notesicon from "../../assets/images/icons/notes-icon.svg";
import userimage from "../../assets/images/placeholders/user.png";
import groupimage from "../../assets/images/placeholders/group.png";
import "./style.css";
import ContactInfo from "../chats/panels/contact-info";
import { userProfileService } from "../../redux/features/userSlice/profile";
import GroupInfo from "../chats/panels/group-info";
import EditGroupInfo from "../chats/panels/edit-group-info";
import GroupMembers from "../chats/panels/group-members";
import AddMoreParticipants from "../chats/panels/add-more-participants";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../js/db";
import MaterialIcon from "../../components/misc/materialIcon";
import {
	calculateLastseen,
	removeMessageContext,
	imageURLtoBlobGenerator,
	unixTimestampToDate,
	convertToMillisecondsPrecision,
	imageURLtoBlobGeneratorAsync,
} from "../../utils/functions";
import { SocketContext } from "../../socket";
import { ENDPOINTS } from "../../constants/socket";
import DownloaderDialog from "../../components/misc/downloaderDialog";
import MessageForward from "../chats/panels/message-forward";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import SearchPanel from "../chats/panels/search-panel";
import StatusIcon from "../../components/misc/statusIcon";
import MessageInfoPanel from "../chats/panels/message-info";
import { getMessageInfoService } from "../../redux/features/chatSlice/messageInfo";
import LiveMap from "../../components/modals/liveMap";
import KeepAlive, { useAliveController } from "react-activation";
import SingleChatContent from "./content";
import { chatMessageService } from "../../redux/features/chatSlice/chatMessages";
import { getReactionDetailService } from "../../redux/features/chatSlice/reactionDetail";

const SingleChatPage = memo(({ id, type, f7router }) => {
	id = id !== "null" ? (id !== "posh_ai" ? parseInt(id) : id) : id;
	const { t } = useTranslation();
	const { width } = useWindowSize();
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const [, forceUpdate] = useState(0);
	const userStatus = useStore("userStatus");
	const [statusMessage, setStatusMessage] = useState({});
	const backLinkVisible = useMemo(() => width < 775, [width]);
	const [selectedMessage, setSelectedMessage] = useState(null);
	const [
		messageContext,
		setMessageContext,
		{ removeItem: removeMessageContext },
	] = useLocalStorageState("messageContext");
	const [mode, setMode] = useState("view");
	const [membersGroup, setMembersGroup] = useState(null);
	const [addMoreParticipants, SetAddMoreParticipants] = useState(null);
	const profileServiceDispatched = useRef(false);
	const [reactionsOpen, setReactionsOpen] = useState(false);
	const typing = useStore("typing");
	const [typingText, setTypingText] = useState("");
	const showScrollToBottom = useRef(false);
	const unseenMessages = useStore("unseenMessages");
	const selectedMessages = useStore("selectedMessages");
	const [AIWebSearch, setAIWebSearch] = useLocalStorageState("AIWebSearch", {
		defaultValue: false,
	});
	const isMounted = useRef(true);
	const [reactions, setReactions] = useState(null);
	const [allReactionsCount, setAllReactionsCount] = useState(null);

	// const { drop, dropScope, clear, getCachingNodes } = useAliveController();

	// if (getCachingNodes().length > 3) {
	// 	drop(getCachingNodes()[0].name);
	// 	dropScope(getCachingNodes()[0].name);
	// }

	const _messageCount = useLiveQuery(
		async () => {
			if (id === "null") return 0;
			const c = await db.messages.where({ chat_id: id }).count();
			return c;
		},
		[id],
		0
	);

	const messageCount = useMemo(() => _messageCount, [_messageCount]);

	useEffect(() => {
		if (!isMounted.current) return;
		if (id !== "null") {
			if (typing && typing[id]) {
				const _typing = typing[id];
				if (_typing && _typing instanceof Array) {
					if (_typing?.length > 1) {
						const typingText = _typing?.map(
							(data) => data.user?.split(" ")[0]
						);
						setTypingText(
							typingText.join(", ") + " is " + t("typing...")
						);
					} else {
						setTypingText(
							_typing[0]?.user?.split(" ")[0] +
								" is " +
								t("typing...")
						);
					}
				} else if (_typing) {
					setTypingText(t("typing..."));
				} else {
					setTypingText("");
				}
			} else {
				setTypingText("");
			}
		} else {
			setTypingText("");
		}
	}, [typing]);

	const { loginResponse } = useSelector((state) => state.loginSlice);

	const _chat =
		id !== "null"
			? useLiveQuery(
					() => db.chats.where({ chat_id: id }).first(),
					[id],
					null
			  )
			: null;

	const chat = useMemo(() => _chat, [_chat]);

	const _members =
		id !== "null"
			? useLiveQuery(() => db.members.where({ chat_id: id }).toArray())
			: [];

	const members = useMemo(() => _members, [_members]);

	const targetMembers = useMemo(
		() =>
			members?.filter((member) => member.id !== loginResponse.data.id) ||
			[],
		[members]
	);

	const { getReactionDetailResponse } = useSelector(
		(state) => state.reactionDetailSlice
	);

	const _userReactions = useLiveQuery(
		async () => {
			if (!messageContext) return {};

			const data = await db.reactions
				.where({ message_id: messageContext?.id })
				.first();

			if (data && data.reactions) {
				let _reactions = Object.keys(data.reactions).filter(
					(key) => data.reactions[key].length > 0
				);
				let _allCount = 0;
				_reactions.forEach((reaction) => {
					_allCount += data.reactions[reaction].length;
				});
				setReactions(_reactions);
				setAllReactionsCount(_allCount);
			}

			return data;
		},
		[messageContext, getReactionDetailResponse],
		{}
	);

	const userReactions = useMemo(() => _userReactions, [_userReactions]);

	let displayedNames = useMemo(() => {
		let _displayedNames = [];
		if (
			targetMembers &&
			Array.isArray(targetMembers) &&
			targetMembers.length > 0
		) {
			const targetName = targetMembers.map((member) =>
				member.firstname ? member.firstname : member.username
			);
			_displayedNames = targetName;

			const loginUserId = loginResponse.data.id;
			const loginUserName = `You`;

			if (
				members.some((member) => member.id === loginUserId) &&
				_displayedNames.length < 10
			) {
				_displayedNames.push(loginUserName);
			}
		}
		return _displayedNames;
	}, [targetMembers, loginResponse, members]);

	const callStart = (mode, chatType) => {
		f7.store.dispatch("setCallChatId", id);
		f7.store.dispatch("setCallType", mode);
		f7.store.dispatch("setCallDirection", "outgoing");
		f7.store.dispatch("setCallChatType", chatType);
		f7.store.dispatch("setShowCallScreen", true);
	};

	useEffect(() => {
		if (!isMounted.current) return;
		if (
			targetMembers &&
			Array.isArray(targetMembers) &&
			targetMembers.length > 0 &&
			!profileServiceDispatched.current
		) {
			if (type === "single") {
				const targetMemberIds = targetMembers.map(
					(member) => member.id
				);
				if (targetMemberIds.length && id !== "posh_ai") {
					dispatch(
						userProfileService({
							user_id: targetMemberIds[0],
						})
					);
					profileServiceDispatched.current = true;
					socket.emit(
						ENDPOINTS.CHECK_USER_STATUS,
						JSON.stringify({
							user_id: targetMemberIds[0],
						}),
						(data) => {
							f7.store.dispatch("setUserStatus", data);
						}
					);
				}
			}
		}
	}, [members]);

	useEffect(() => {
		if (!isMounted.current) return;
		if (chat?.chat_id == 11) {
			const state = t("Official KT Messenger Account");
			setStatusMessage(state);
		} else {
			const state = userStatus[chat?.user_id]
				? userStatus[chat?.user_id]?.status
					? t("online")
					: !userStatus[chat?.user_id].last_online ||
					  userStatus[chat?.user_id].last_online == ""
					? t("Away")
					: `${t("Last seen")} ${calculateLastseen(
							userStatus[chat?.user_id].last_online
					  )}`
				: t("Away");

			setStatusMessage(state);
		}
	}, [userStatus, chat]);

	const [infoPanel, setInfoPanel] = useState(false);
	const [forwardPanel, setForwardPanel] = useState(false);
	const [searchPanel, setSearchPanel] = useState(false);
	const [msgInfoPanel, setMsgInfoPanel] = useState(false);

	useEffect(() => {
		if (!isMounted.current) return;
		f7 &&
			f7.on("setSelectChat", (mid) => {
				if (!mid) {
					db.messages
						.where({ chat_id: id })
						.filter((msg) => {
							return msg.type === "image" || msg.type === "video";
						})
						.toArray()
						.then((msgs) => {
							const media = msgs.sort(
								(a, b) => b.unix_time - a.unix_time
							);
							f7.store.dispatch(
								"setSelectedMessageId",
								media[0].id
							);
							setSelectedMessage(media[0].id);
						});
				} else {
					f7.store.dispatch("setSelectedMessageId", mid);
					setSelectedMessage(mid);
				}
			});

		return () => {
			f7 && f7.off("setSelectChat");
		};
	});

	// const [lastId, setLastId] = useState(null);

	// useUpdateEffect(() => {
	// 	if (lastId) {
	// 		const el = document.getElementById(lastId);
	// 		if (el) {
	// 			el.scrollIntoView({ alignToTop: true, behavior: "instant" });
	// 		}
	// 	}
	// }, [lastId]);

	const hideReply = ({ props }) => {
		if (props instanceof Array || !members || id === "posh_ai") {
			return true;
		} else {
			// const existInMembers = members.find(
			// 	(m) => m.id === loginResponse.data.id
			// );
			// if (existInMembers) {
			// 	return false;
			// } else {
			// 	return true;
			// }
			return id !== "null" && id !== "posh_ai" && id > 11 ? false : true;
		}
	};
	const hideForward = ({ props }) => {
		if (props.type === "call" || id === "posh_ai" || id === 11) {
			return true;
		} else return false;
	};
	const hideEdit = ({ props }) => {
		if (
			props instanceof Array ||
			props.sender_id != loginResponse.data.id ||
			props.type === "location" ||
			props.type === "contact" ||
			props.type === "mms" ||
			props.type === "call" ||
			id === "posh_ai"
		) {
			return true;
		} else return false;
	};
	const hideInfo = ({ props }) => {
		if (Array.isArray(props)) {
			if (
				props[0].sender_id != loginResponse.data.id ||
				msgInfoPanel ||
				id === "posh_ai" ||
				id === 11
			) {
				return true;
			}
			return false;
		} else {
			if (
				props.sender_id != loginResponse.data.id ||
				props.type === "call" ||
				msgInfoPanel ||
				id === "posh_ai"
			) {
				return true;
			}
			return false;
		}
	};
	const hideCopy = ({ props }) => {
		if (props instanceof Array) return true;
		else if (props.type === "image" || props.type === "text") return false;
		else return true;
	};
	/*const hideDelete = ({ props }) => {
		if (props.sender_id === loginResponse.data.id) return false;
		else if (type === "group" && chat?.owner_id === loginResponse.data.id)
			return false;
		else return true;
	};*/

	const copyMessage = (context) => {
		if (context) {
			navigator.clipboard.writeText(context.message);
			f7.toast
				.create({
					text: t("Message copied to clipboard"),
					position: "top",
					closeTimeout: 2000,
				})
				.open();
		}
	};

	const copyImgToClipboard = async (context) => {
		if (context) {
			try {
				imageURLtoBlobGenerator(context.audio_url, async (blobs) => {
					const data = {};
					blobs.map((blob) => {
						data[
							`${blob.type !== "image/png" ? "web " : ""}${
								blob.type
							}`
						] = blob;
					});
					await navigator.clipboard.write([
						new window.ClipboardItem(data),
					]);
				});
			} catch (ex) {
				console.log("copy error: ", ex);
			}
		}
	};

	const copyMultipleMessage = useCallback(async () => {
		const context = selectedMessages;
		if (context) {
			const blobs = {};
			let combinedText = "";
			const promises = [];

			const processImageURL = (message) => {
				return new Promise(async (resolve, reject) => {
					try {
						const imageBlobs = await imageURLtoBlobGeneratorAsync(
							message.audio_url
						);
						imageBlobs.map((blob) => {
							if (blob.type === "image/png") {
								blobs["image/png"] = blob;
							}
						});

						if (message.caption?.trim()?.length > 0) {
							const timestamp = new Date(
								convertToMillisecondsPrecision(
									message.unix_time
								)
							).toLocaleString();
							const sender =
								message.sender_id === loginResponse.data.id
									? ""
									: message.sender_name;
							combinedText += `[${timestamp}] ${sender}: ${message.caption}\n`;
						}
						resolve();
					} catch (ex) {
						console.log("copy error: ", ex);
						reject(ex);
					}
				});
			};

			const processText = (message) => {
				const timestamp = new Date(
					convertToMillisecondsPrecision(message.unix_time)
				).toLocaleString();
				const sender =
					message.sender_id === loginResponse.data.id
						? ""
						: message.sender_name;
				combinedText += `[${timestamp}] ${sender}: ${message.message}\n`;
			};

			for (const message of context) {
				if (message.type === "text") {
					processText(message);
				} else if (message.type === "image") {
					promises.push(processImageURL(message));
				} else {
					continue;
				}
			}

			// Wait for all image processing promises to resolve
			await Promise.all(promises);

			// Create a single ClipboardItem with all the blobs and text
			const clipboardData = {};
			Object.keys(blobs).forEach((key) => {
				clipboardData[key] = blobs[key];
			});
			if (combinedText) {
				clipboardData["text/plain"] = new Blob([combinedText.trim()], {
					type: "text/plain",
				});
			}

			try {
				await navigator.clipboard.write([
					new window.ClipboardItem(clipboardData),
				]);
			} catch (ex) {
				console.log("copy error: ", ex);
			}
			f7.store.dispatch("resetSelectedMessages");
		}
	}, [selectedMessages]);

	const DeleteMessage = (messagesToDelete) => {
		function deleteSingleMessage(context, dialog, meonly = 0) {
			if (context) {
				// Check if the message exists in the database before attempting to delete it
				db.messages
					.get(context.id)
					.then((message) => {
						if (message) {
							// If the message exists, proceed with deletion
							socket.emit(
								ENDPOINTS.DELETE_MESSAGE,
								JSON.stringify({
									msg_id: context.id,
									delete_for_me: meonly,
									chat_id: context.chat_id,
								}),
								(response) => {
									console.log(
										"Response From Delete for me Socket : ",
										response
									);
									dialog.close();
								}
							);

							db.messages
								.delete(context.id)
								.then(function (deleteCount) {
									console.log(
										"Deleted " + deleteCount + " objects"
									);
									f7.store.dispatch(
										"removeSelectedMessages",
										context.id
									);

									db.messages
										.where({ chat_id: context.chat_id })
										.toArray()
										.then((msgs) => {
											if (!msgs || msgs.length === 0)
												return;

											msgs?.sort(
												(a, b) =>
													convertToMillisecondsPrecision(
														parseInt(a.unix_time)
													) -
													convertToMillisecondsPrecision(
														parseInt(b.unix_time)
													)
											);
											const lastMsg =
												msgs[msgs.length - 1];
											db.chats
												.get(lastMsg?.chat_id)
												.then((chat) => {
													const chatData = {
														message:
															lastMsg.message,
														...(chat.type ===
															"group" && {
															firstname:
																lastMsg.sender_name,
														}),
														message_sender_id:
															parseInt(
																lastMsg.sender_id
															),
														unix_time:
															lastMsg.unix_time,
														msg_type: lastMsg.type,
													};

													db.chats
														.where({
															chat_id:
																lastMsg.chat_id,
														})
														.modify(chatData)
														.then((id) => {
															console.log(
																"modified chat",
																id
															);
														})
														.catch((ex) => {
															console.log(
																"Modification error:",
																ex
															);
														});
												});
										})
										.catch((error) => {
											console.log(
												"Error in fetching messages:",
												error
											);
										});
								})
								.catch((error) => {
									console.log("Deletion error:", error);
									alert(
										"Error in deleting message: " +
											error.message
									);
								});
						} else {
							// If the message does not exist, log a message
							console.log(
								"Message not found in database, cannot delete."
							);
							alert(
								"Message not found in database, cannot delete."
							);
						}
					})
					.catch((error) => {
						console.log(
							"Error in checking message existence:",
							error
						);
						alert(
							"Error in checking message existence: " +
								error.message
						);
					});
			}
		}

		var is_sent_by_me =
			messagesToDelete instanceof Array
				? messagesToDelete[0].sender_id == loginResponse.data.id
				: messagesToDelete.sender_id == loginResponse.data.id;

		const buttons = [
			{
				text: t("Cancel"),
				cssClass: "custom-Cancel-button",
				onClick: function () {
					// Handle cancel logic
					f7.dialog.close();
				},
			},
		];

		buttons.unshift({
			text: t("Delete for me"),
			cssClass: "custom-clear-button",
			onClick: function (dialog) {
				if (messagesToDelete instanceof Array) {
					messagesToDelete.forEach((message) => {
						deleteSingleMessage(message, dialog, 1);
					});
				} else {
					deleteSingleMessage(messagesToDelete, dialog, 1);
				}
			},
		});

		if (
			is_sent_by_me &&
			id !== "posh_ai" &&
			id !== loginResponse.data.chat_id
		) {
			buttons.unshift({
				text: t("Delete for everyone"),
				cssClass: "custom-clear-button",
				onClick: function (dialog) {
					if (messagesToDelete instanceof Array) {
						messagesToDelete.forEach((message) => {
							deleteSingleMessage(message, dialog);
						});
					} else {
						deleteSingleMessage(messagesToDelete, dialog);
					}
				},
			});
		}

		f7.dialog
			.create({
				title: t("Delete message?"),
				text: `${t(
					"Deleting a message for yourself allows you to delete your copy of messages you've sent or received"
				)}
            
                    <span class="text_Area">
                    ${t("This has no effect on your recipients' chats")}
                    </span>
                `,
				buttons: buttons,
			})
			.open();
	};

	const selectMessage = (message) => {
		const index = selectedMessages.findIndex(
			(msg) => msg.id === message.id
		);
		index === -1
			? f7.store.dispatch("addSelectedMessages", message)
			: f7.store.dispatch("removeSelectedMessages", message);
		forceUpdate((n) => n + 1);
	};

	const [myExistingReactions, setMyExistingReactions] = useState([]);

	useEffect(() => {
		if (!messageContext) {
			setMyExistingReactions([]);
			return;
		}
		setMyExistingReactions(messageContext.reactions?.my_reactions);
	}, [messageContext]);

	useEffect(() => {
		if (!myExistingReactions) return;

		if (!reactionsOpen || myExistingReactions?.length > 0) {
			const allReactionEl = document.querySelectorAll(
				`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn`
			);
			allReactionEl?.forEach((reactionEl) =>
				reactionEl?.classList?.remove("reactions-active")
			);

			setTimeout(() => {
				let reactionsMapped = [];
				myExistingReactions?.map((reaction) => {
					const emojiCode = REACTIONS[reaction];
					reactionsMapped.push(emojiCode);
					const reactionEl = document.querySelector(
						`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn[data-unified='${emojiCode}']`
					);
					reactionEl?.classList?.add("reactions-active");
				});
				const nonActiveReactions = Object.values(REACTIONS).filter(
					(r) => !reactionsMapped.includes(r)
				);
				nonActiveReactions.forEach((reaction) => {
					const reactionEl = document.querySelector(
						`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn[data-unified='${reaction}']`
					);
					reactionEl?.classList?.remove("reactions-active");
				});
			}, 100);
		}

		return () => {
			document
				.querySelector(`.reactions-active`)
				?.classList.remove("reactions-active");
		};
	}, [myExistingReactions, reactionsOpen]);

	const removeReaction = (reaction, msgId) => {
		socket.emit(
			ENDPOINTS.REMOVE_REACTION,
			JSON.stringify({
				message_id: msgId,
				reaction: reaction,
				chat_id: parseInt(id),
			}),
			async (response) => {
				if (response?.status) {
					try {
						await db.messages
							.where({ id: msgId })
							.modify((value, ref) => {
								ref.value = {
									...value,
									reactions: { ...response.reactions },
								};
							});
						setMyExistingReactions((prev) =>
							prev.filter((r) => r !== reaction)
						);
						dispatch(
							getReactionDetailService({ message_id: msgId })
						);
					} catch (ex) {
						console.log("Error in removing reaction", ex);
					}
				} else {
					f7.toast.create({
						text: "Something went wrong",
						position: "center",
						closeTimeout: 2000,
					});
				}
			}
		);
	};

	const handleReaction = (reaction) => {
		f7.popover.close();
		reaction = Object.keys(REACTIONS).find(
			(key) => REACTIONS[key] === reaction.unified
		);
		const context = messageContext;

		if (!context) return;

		if (myExistingReactions?.includes(reaction)) {
			removeReaction(reaction, context.id);
		} else {
			socket.emit(
				ENDPOINTS.ADD_REACTION,
				JSON.stringify({
					message_id: context.id,
					reaction: reaction,
					chat_id: id,
				}),
				async (response) => {
					console.log("reaction added", response);
					if (response.status) {
						removeMessageContext();
						await db.messages
							.where({ id: response.message_id })
							.modify((value, ref) => {
								ref.value = {
									...value,
									reactions: { ...response.reactions },
								};
							});
						setMyExistingReactions((prev) =>
							Array.isArray(prev)
								? [...prev, reaction]
								: [reaction]
						);
						dispatch(
							getReactionDetailService({
								message_id: response.message_id,
							})
						);
					}
				}
			);
		}
	};

	const { show } = useContextMenu({
		id: "chat-menu",
	});

	const displayMenu = useCallback(
		(message, e) => {
			if (msgInfoPanel) return;
			show({
				event: e,
				props: message,
			});
		},
		[msgInfoPanel]
	);

	useEffect(() => {
		if (!isMounted.current) return;
		f7.on("scrollToBottomUpdate", (value) => {
			showScrollToBottom.current = value;
			forceUpdate((n) => n + 1);
		});

		return () => {
			f7.off("scrollToBottomUpdate");
		};
	});

	return (
		<Page
			id={`singleChat_${id}`}
			name="singleChat"
			className={`singleChatPage ${
				id === "null" ? "no-content-overflow" : ""
			}`}
			onPageInit={() => {
				if (id !== "null") {
					f7.emit("updateIndex", id);
					f7.emit("setActiveChat", id);

					typeof id !== "string" &&
						id !== loginResponse.data.chat_id &&
						db.chats
							.where({ chat_id: id })
							.modify({ un_read_count: 0 })
							.then(() => console.log("Unread count cleared"));

					if (
						(id !== "posh_ai" && !messageCount) ||
						(id !== "posh_ai" && messageCount <= 10)
					) {
						dispatch(
							chatMessageService({
								chat_id: id,
								from_search: 0,
								offset: 0,
								swipe_up: 0,
							})
						);
					}
					f7 &&
						f7.on("searchMessage", (elId, animate = true) => {
							const el = document.getElementById(elId);

							el &&
								el.scrollIntoView({
									behavior: "smooth",
									block: "center",
								});

							if (animate) {
								el.classList.add("flash");

								setTimeout(() => {
									el.classList.remove("flash");
								}, 3000);
							}
						});

					if (f7router.currentRoute.query?.message_id) {
						const messageId =
							f7router.currentRoute.query.message_id;

						const observer = new MutationObserver((mutations) => {
							mutations.forEach((mutation) => {
								if (mutation.type === "childList") {
									const messageElement =
										document.getElementById(messageId);
									if (messageElement) {
										observer.disconnect();
										f7.emit("searchMessage", messageId);
									}
								}
							});
						});

						observer.observe(document.body, {
							childList: true,
							subtree: true,
						});
					}
				}
			}}
			onPageMounted={() => (isMounted.current = true)}
			onPageBeforeUnmount={() => (isMounted.current = false)}
			pageContent={false}>
			{/* <KeepAlive id={`${id}_nav`} cacheKey={`${id}_nav`} saveScrollPosition={false}> */}
			{id !== "null" && selectedMessages.length === 0 && (
				<Navbar slot="fixed">
					{backLinkVisible && (
						<NavLeft>
							<Button
								className="p-0 h-[40px]"
								onClick={() => f7router.navigate("/chats/")}>
								<MaterialIcon
									size={24}
									icon="chevron_left"
									weight={300}
									className="text-[40px] text-secondary"
								/>
							</Button>
						</NavLeft>
					)}
					<div
						className={`flex flex-row justify-center ${
							!backLinkVisible && "ml-[20px]"
						} ${
							loginResponse.data.chat_id !== id &&
							id !== "posh_ai" &&
							id !== 11 &&
							"cursor-pointer"
						}`}
						onClick={() => {
							if (
								loginResponse.data.chat_id !== id &&
								id !== "posh_ai" &&
								id !== 11
							)
								setInfoPanel(!infoPanel);
						}}>
						{chat ? (
							<div className="relative shrink-0">
								<LazyLoaderWraper
									src={
										id !== "null" &&
										id === loginResponse.data.chat_id
											? notesicon
											: (!chat?.hide_profile_picture &&
													chat?.profile_image) ||
											  chat?.profile_image?.length > 0
											? chat?.profile_image
											: type === "single"
											? userimage
											: groupimage
									}
									placeholder={
										type === "single"
											? userimage
											: groupimage
									}
									height={45}
									width={45}
									alt=""
									className="rounded-full align-bottom object-cover"
									wrapperclassname="rounded-full align-bottom"
								/>
								{type === "single" &&
									id !== loginResponse.data.chat_id &&
									id !== 11 &&
									id !== "posh_ai" && (
										<StatusIcon
											status={userStatus[chat?.user_id]}
											dissapearing={
												chat?.disappearing_duration
											}
										/>
									)}
							</div>
						) : (
							<SkeletonAvatar
								effect="wave"
								className="rounded-full align-bottom h-[45px] w-[45px]"
							/>
						)}
						<div className="flex flex-col justify-center items-start ml-[20px]">
							{chat ? (
								<div className="flex flex-row justify-between">
									<span className="text-body text-base font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">{`${
										id !== "null" &&
										id === loginResponse.data.chat_id
											? t("My Notes")
											: chat?.nickname
											? chat?.nickname
											: `${chat?.firstname} ${chat?.lastname}`
									}`}</span>
									{loginResponse.data.chat_id !== id &&
										id !== "posh_ai" &&
										id !== 11 && (
											<Button
												className="h-[25px]"
												onClick={() => {
													setInfoPanel(!infoPanel);
												}}>
												<Setting2
													size={15}
													color="#5d6980"
												/>
											</Button>
										)}
								</div>
							) : (
								<SkeletonText effect="wave" tag="span">
									Loading Nickname
								</SkeletonText>
							)}
							{loginResponse.data.chat_id !== id &&
								id !== "posh_ai" && (
									<>
										{chat ? (
											<span className="w-full text-secondary text-[13px] font-medium truncate participants-text">
												{typingText
													? typingText
													: chat?.type === "group"
													? displayedNames.length > 0
														? `${displayedNames.join(
																", "
														  )}`
														: t("You")
													: `${t(statusMessage)}`}
											</span>
										) : (
											<SkeletonText
												effect="wave"
												tag="span">
												Lorem ipsum dolor sit amet
											</SkeletonText>
										)}
									</>
								)}
						</div>
					</div>
					<NavRight className="md:mr-[20px]">
						<Button onClick={() => setSearchPanel(true)}>
							<MaterialIcon
								icon="search"
								weight="400"
								color="#5d6980"
								size={20}
								className="h-[20px] w-[20px] text-[22px]"
							/>
						</Button>
						{chat && chat.chat_id === "posh_ai" && (
							<div className="flex items-center gap-2.5">
								<span>Web Search</span>
								<Toggle
									tooltip="Web Search enables Posh AI to look for updated information from the internet."
									tooltipTrigger="hover"
									checked={AIWebSearch}
									onToggleChange={(e) => {
										setAIWebSearch(!e);
									}}
								/>
							</div>
						)}
						{chat &&
							chat?.chat_id != loginResponse.data.chat_id &&
							chat?.chat_id != 11 &&
							chat?.chat_id !== "posh_ai" && (
								<Button
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("video", chat?.type);
									}}>
									<Video
										size="20"
										color="#5d6980"
										variant="Linear"
									/>
								</Button>
							)}
						{chat &&
							chat?.chat_id != loginResponse.data.chat_id &&
							chat?.chat_id != 11 &&
							chat?.chat_id !== "posh_ai" && (
								<Button
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("audio", chat?.type);
									}}>
									<Call
										size="20"
										color="#5d6980"
										variant="Linear"
									/>
								</Button>
							)}
					</NavRight>
				</Navbar>
			)}
			{id !== "null" && selectedMessages.length > 0 && (
				<Navbar slot="fixed">
					<NavLeft>
						<Button
							className="p-0 h-[40px]"
							onClick={() =>
								f7.store.dispatch("resetSelectedMessages")
							}>
							<MaterialIcon
								size={24}
								icon="close"
								weight={300}
								className="text-secondary"
							/>
						</Button>
					</NavLeft>
					<div className={`flex flex-row justify-center`}>
						<div className="flex flex-col justify-center items-start ml-[20px]">
							<div className="flex flex-row justify-between">
								<span className="text-body text-base font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">
									{t("{{count}} Selected", {
										count: selectedMessages.length,
									})}
								</span>
							</div>
						</div>
					</div>
					<NavRight className="md:mr-[20px]">
						<Button
							className="p-5 h-[40px]"
							onClick={() => {
								DeleteMessage(selectedMessages);
							}}>
							<MaterialIcon
								size={24}
								icon="delete"
								weight={300}
								className="text-secondary"
							/>
						</Button>
						{selectedMessages.filter((msg) => msg.type === "image")
							?.length < 2 && (
							<Button
								className="p-5 h-[40px]"
								onClick={copyMultipleMessage}>
								<MaterialIcon
									size={24}
									icon="content_copy"
									weight={300}
									className="text-secondary"
								/>
							</Button>
						)}
						{id !== "posh_ai" && id !== 11 && (
							<Button
								className="p-5 h-[40px]"
								onClick={() => {
									// f7.store.dispatch("setSearchDisabled", true);
									setMessageContext(selectedMessages);
									setForwardPanel(true);
								}}>
								<MaterialIcon
									size={24}
									icon="redo"
									weight={300}
									className="text-secondary"
								/>
							</Button>
						)}
					</NavRight>
				</Navbar>
			)}
			{/* </KeepAlive> */}
			<Menu
				slot="fixed"
				id="chat-menu"
				theme="light"
				animation="fade"
				disableBoundariesCheck={false}>
				<Item
					hidden={hideReply}
					onClick={({ props }) => {
						f7.store.dispatch("setReply", props);
						document.body.classList.add("expanded-messagebar");
					}}>
					{t("Reply")}
				</Item>
				<Item
					hidden={hideForward}
					onClick={({ props }) => {
						setMessageContext(props);
						// f7.store.dispatch("setSearchDisabled", true);
						setForwardPanel(true);
					}}>
					{t("Forward")}
				</Item>
				<Item
					hidden={hideEdit}
					onClick={({ props }) => {
						f7.store.dispatch("setEdit", props);
						document.body.classList.add("expanded-messagebar");
					}}>
					{t("Edit")}
				</Item>
				<Item
					hidden={hideInfo}
					onClick={({ props }) => {
						setMessageContext(props);
						setMsgInfoPanel(true);
						dispatch(
							getMessageInfoService({
								message_id: Array.isArray(props)
									? props[0].id
									: props.id,
							})
						);
					}}>
					{t("Message Info")}
				</Item>
				<Item
					hidden={hideCopy}
					onClick={({ props }) => {
						if (props.type === "text") copyMessage(props);
						else if (props.type === "image")
							copyImgToClipboard(props);
					}}>
					{t("Copy to clipboard")}
				</Item>
				<Item onClick={({ props }) => DeleteMessage(props)}>
					{t("Delete")}
				</Item>
				<Item
					onClick={({ props }) => {
						Array.isArray(props)
							? props.map((msg) => selectMessage(msg))
							: selectMessage(props);
					}}>
					{t("Select Message")}
				</Item>
			</Menu>
			<Panel
				slot="fixed"
				id="infoPanel"
				opened={infoPanel}
				onPanelClosed={() => setInfoPanel(false)}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" &&
					type === "single" &&
					id !== loginResponse.data.chat_id &&
					chat && (
						<ContactInfo
							chat_id={id}
							user_id={chat?.user_id}
							userMode={false}
							onConnectClick={null}
						/>
					)}

				{id !== "null" &&
					type === "group" &&
					mode === "view" &&
					!membersGroup &&
					!addMoreParticipants && (
						<GroupInfo
							chat_id={id}
							switchMode={() => setMode("edit")}
							openMembersGroup={() => setMembersGroup("view")}
							openAddMembers={() =>
								SetAddMoreParticipants("view")
							}
							f7router={f7router}
						/>
					)}
				{id !== "null" &&
					type === "group" &&
					mode === "edit" &&
					!membersGroup &&
					!addMoreParticipants && (
						<EditGroupInfo
							chat_id={id}
							switchMode={() => setMode("view")}
						/>
					)}
				{id !== "null" &&
					type === "group" &&
					!addMoreParticipants &&
					membersGroup === "view" && (
						<GroupMembers
							chat_id={id}
							switchMode={() => setMembersGroup(null)}
							openAddMembers={() =>
								SetAddMoreParticipants("view")
							}
						/>
					)}
				{id !== "null" &&
					type === "group" &&
					addMoreParticipants === "view" && (
						<AddMoreParticipants
							chat_id={id}
							switchMode={() => SetAddMoreParticipants(null)}
						/>
					)}
			</Panel>
			<Panel
				slot="fixed"
				id="forwardPanel"
				opened={forwardPanel}
				onPanelClosed={() => {
					setForwardPanel(false);
					// f7.store.dispatch("setSearchDisabled", true);
					removeMessageContext();
					f7.store.dispatch("resetSelectedMessages");
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[11001]">
				{id !== "null" && messageContext && (
					<MessageForward chat_id={id} />
				)}
			</Panel>
			<Panel
				slot="fixed"
				id="searchPanel"
				opened={searchPanel}
				onPanelClosed={() => {
					setSearchPanel(false);
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" && <SearchPanel chat_id={id} />}
			</Panel>
			<Panel
				slot="fixed"
				id="msgInfoPanel"
				opened={msgInfoPanel}
				onPanelClosed={() => {
					setMsgInfoPanel(false);
					removeMessageContext();
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" && messageContext && (
					<MessageInfoPanel
						reactionsOpen={reactionsOpen}
						members={targetMembers}
						type={type}
						displayMenu={displayMenu}
						opened={msgInfoPanel}
					/>
				)}
			</Panel>
			<Popup
				slot="fixed"
				opened={selectedMessage !== null}
				tabletFullscreen
				className="lightbox-popup"
				backdrop={false}
				closeByBackdropClick={false}
				closeOnEscape
				onPopupClose={() =>
					f7.store.dispatch("setSelectedMessageId", null)
				}>
				<Lightbox
					chat_id={id}
					selectedMessage={selectedMessage}
					chatType={type}
					opened={selectedMessage !== null}
					closePopup={() => setSelectedMessage(null)}
					onDeleteMessage={DeleteMessage}
					onForwardPanel={() => {
						// f7.store.dispatch("setSearchDisabled", true);
						setForwardPanel(true);
					}}
				/>
			</Popup>
			<Popup
				slot="fixed"
				tabletFullscreen
				className="live-location-popup"
				backdrop={false}
				closeByBackdropClick={false}
				closeOnEscape>
				<LiveMap />
			</Popup>
			<Popover
				slot="fixed"
				verticalPosition="top"
				arrow={false}
				backdrop
				closeByBackdropClick
				closeByOutsideClick
				closeOnEscape
				onPopoverOpened={() => setReactionsOpen(true)}
				onPopoverClosed={() => {
					setReactionsOpen(false);
					removeMessageContext();
					setMyExistingReactions([]);
				}}
				className="reaction-menu absolute md:right-0">
				<EmojiPicker
					className={`${reactionsOpen && "open"} ${
						myExistingReactions?.length > 0
							? "has-my-reactions"
							: ""
					}`}
					reactionsDefaultOpen={true}
					reactions={[
						"1f44d", //like
						"2764-fe0f", //heart
						"1f603", //smile
						"1f973", //excited
						"1f923", //laugh
						"1f622", //cry
						"1f621", //angry
						"1f614", //sad
						"1f64f", //thanks
						"1f44e", //dislike
					]}
					onReactionClick={handleReaction}
				/>
			</Popover>
			<Popover
				id="reaction-popover"
				arrow={false}
				backdrop
				closeByBackdropClick
				closeByOutsideClick
				closeOnEscape
				className={`reaction-popover ${
					messageContext?.sender_id !== loginResponse.data.id
						? "received-reaction"
						: "sent-reaction"
				} absolute right-0 p-2.5`}
				onPopoverClosed={() => {
					removeMessageContext();
					setReactions(null);
					setAllReactionsCount(null);
				}}>
				<Toolbar tabbar scrollable>
					<Link
						tabLink="#reactiontab-0"
						tabLinkActive
						className="text-[16px] text-body w-[57px]">
						All {allReactionsCount || ""}
					</Link>
					{reactions?.map((reaction, index) => {
						return (
							<Link
								key={index}
								tabLink={`#reactiontab-${index + 1}`}
								tabLinkActive={index === 1}
								className="flex-row justify-around w-[57px]">
								<Emoji
									unified={REACTIONS[reaction]}
									size={16}
								/>
								{messageContext &&
									userReactions &&
									Object.keys(userReactions)?.length > 0 && (
										<span className="text-[16px] text-body ml-1">
											{userReactions?.reactions[reaction]
												?.length || ""}
										</span>
									)}
							</Link>
						);
					})}
				</Toolbar>

				<Tabs>
					<Tab
						id="reactiontab-0"
						tabActive
						className="h-[206px] overflow-auto">
						<List
							className={`${
								!reactions &&
								"skeleton-text skeleton-effect-wave"
							}`}>
							{!reactions && (
								<ListItem
									title="Placeholder account name"
									footer="placeholder footer"
									media={userimage}></ListItem>
							)}
							{messageContext &&
								reactions &&
								userReactions &&
								Object.keys(userReactions).length > 0 &&
								reactions?.map((reaction, index) => {
									return userReactions?.reactions[
										reaction
									]?.map((user, uindex) => {
										return (
											<ListItem
												key={index + uindex}
												title={user.firstname}
												// {...(parseInt(user.id) === loginResponse.data.id
												// 	? {
												// 			footer: "Click to remove",
												// 			className: "cursor-pointer",
												// 			onClick: () => {
												// 				f7.popover.close();
												// 				setTimeout(() => {
												// 					removeReaction(reaction);
												// 				}, 200);
												// 			},
												// 	  }
												// 	: {})}
												media={user.profile_image}>
												<Emoji
													slot="after"
													unified={
														REACTIONS[reaction]
													}
													size={28}
												/>
											</ListItem>
										);
									});
								})}
						</List>
					</Tab>
					{reactions?.map((reaction, index) => {
						return (
							<Tab
								id={`reactiontab-${index + 1}`}
								key={index}
								className="h-[206px] overflow-auto">
								<List>
									{userReactions &&
										Object.keys(userReactions).length > 0 &&
										userReactions?.reactions[reaction]?.map(
											(user, uindex) => {
												return (
													<ListItem
														key={index + uindex}
														title={user.firstname}
														// {...(parseInt(user.id) === loginResponse.data.id
														// 	? {
														// 			footer: "Click to remove",
														// 			className: "cursor-pointer",
														// 			onClick: () => {
														// 				f7.popover.close();
														// 				setTimeout(() => {
														// 					removeReaction(reaction);
														// 				}, 200);
														// 			},
														// 	  }
														// 	: {})}
														media={
															user.profile_image
														}>
														<Emoji
															slot="after"
															unified={
																REACTIONS[
																	reaction
																]
															}
															size={28}
														/>
													</ListItem>
												);
											}
										)}
								</List>
							</Tab>
						);
					})}
				</Tabs>
			</Popover>
			<KeepAlive
				id={`singleChat_${id}`}
				cacheKey={id}
				name={id}
				saveScrollPosition={false}
				when={false}>
				<SingleChatContent
					f7router={f7router}
					id={id}
					type={type}
					reactionsOpen={reactionsOpen}
					msgInfoPanel={msgInfoPanel || infoPanel}
					setSelectedMessage={setSelectedMessage}
					displayMenu={displayMenu}
					showScrollToBottom={showScrollToBottom.current}
					callStart={callStart}
					setMyExistingReactions={setMyExistingReactions}
				/>
			</KeepAlive>
			<Fab
				position="right-bottom"
				slot="fixed"
				className={`icon ${showScrollToBottom.current && "show"}`}
				onClick={() => f7.emit("scrollToBottom")}>
				<MaterialIcon
					icon="expand_more"
					className="text-secondary text-[24.7px]"
				/>
				{Object.keys(unseenMessages).includes(id) &&
				unseenMessages[id]?.length > 0 ? (
					<Badge color="blue">{unseenMessages[id]?.length}</Badge>
				) : null}
			</Fab>
		</Page>
	);
});

export default SingleChatPage;
