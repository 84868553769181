import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "../../../axios/axiosClient";

const initialState = {
  locationResponse: {},
  isLoading: false,
  error: false,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    saveLocationResponse: (state, action) => {
      state.locationResponse = action.payload;
    },
    resetLocationResponse: (state) => {
      state.locationResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!action.payload) return;
        state.locationResponse = action.payload;
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const fetchLocation = createAsyncThunk(
	"location/fetchLocation",
	async (values) => {
		console.log("Values : ", values);
		let res = await apiClient(values);
		console.log("DATA >>> ", res?.data);
		return res?.data;
	}
);

export const { saveLocationResponse, resetLocationResponse } =
  locationSlice.actions;
export { fetchLocation };

export default locationSlice.reducer;
