import { Button, Popover } from "framework7-react";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStoryFontSize } from "../../../redux/features/storiesSlice/userStory";
import { useTranslation } from "react-i18next";

function FontSizeMenu() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  return (
    <Popover
      className="fontSizeMenu w-[171px] ml-[-1px] bg-white overflow-hidden"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "100px" }}
      arrow={false}
      backdrop={false}
    >
      <Button
        onClick={() => dispatch(updateStoryFontSize("Small"))}
        className="text-dark h-[48px] text-[#302F36] font-bold text-[16px] rounded-none flex justify-center items-center gap-2"
        popoverClose=".fontSizeMenu"
      >
        
        {translate("Small")}
      </Button>
      <Button
        onClick={() => dispatch(updateStoryFontSize("Normal"))}
        className="text-dark h-[48px] text-[#302F36] font-bold text-[16px] rounded-none flex justify-center items-center gap-2"
        popoverClose=".fontSizeMenu"
      >
        
        {translate("Medium")}
      </Button>
      <Button
        onClick={() => dispatch(updateStoryFontSize("Large"))}
        className="text-dark h-[48px] text-[#302F36] font-bold text-[16px] rounded-none flex justify-center items-center gap-2"
        popoverClose=".fontSizeMenu"
      >
        
        {translate("Large")}
      </Button>
    </Popover>
  );
}

export default FontSizeMenu;
