import React from 'react'
import MaterialIcon from '../materialIcon';

const MsgStatusIcon = ({ status }) => {
    if (status === -1) return <MaterialIcon icon="schedule" weight={300} className="text-base" />;
    else if (status === 0) return <MaterialIcon icon="done" weight={300} />;
    else if (status === 1) return <MaterialIcon icon="done_all" weight={300} />;
    else if (status === 2)
        return <MaterialIcon icon="done_all" color="#0E99FE" weight={300} />;
    // else
    // 	return(<i className="kt done-all-blue" />) //add failed icon
};

export default MsgStatusIcon
