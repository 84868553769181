import { useEffect, useState } from "react";

const useSpeechSynthesis = (props = {}) => {
	const { onEnd = () => {} } = props;
	const [voices, setVoices] = useState([]);
	const [speaking, setSpeaking] = useState(false);
	const [supported, setSupported] = useState(false);

	const processVoices = (voiceOptions) => {
		setVoices(voiceOptions);
	};

	const getVoices = () => {
		// Firefox seems to have voices upfront and never calls the
		// voiceschanged event
		let voiceOptions = window.speechSynthesis.getVoices();
		if (voiceOptions.length > 0) {
			processVoices(voiceOptions);
			return;
		}

		window.speechSynthesis.onvoiceschanged = (event) => {
			voiceOptions = event.target.getVoices();
			processVoices(voiceOptions);
		};
	};

	const handleEnd = () => {
		setSpeaking(false);
		onEnd();
	};

	useEffect(() => {
		if (typeof window !== "undefined" && window.speechSynthesis) {
			setSupported(true);
			getVoices();
		}
	}, []);

	const speak = (args = {}) => {
		const {
			voice = null,
			text = "",
			rate = 1,
			pitch = 1,
			volume = 1,
			id = null,
			lang = "en",
		} = args;
		if (!supported) return;
        setSpeaking(id);
        console.log("utterance", text, lang)
		
		const utterance = new window.SpeechSynthesisUtterance();
		utterance.text = text;
		utterance.voice = voice;
		utterance.onend = handleEnd;
		utterance.rate = rate;
		utterance.pitch = pitch;
        utterance.volume = volume;
        utterance.lang = lang;
		window.speechSynthesis.speak(utterance);
	};

	const cancel = () => {
		if (!supported) return;
		setSpeaking(false);
		window.speechSynthesis.cancel();
	};

	return {
		supported,
		speak,
		speaking,
		cancel,
		voices,
	};
};

export default useSpeechSynthesis;
