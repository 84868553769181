import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";

const initialState = {
	signupResponse: {},
	updatePinResponse: {},
	isLoading: false,
	error: false,
};

export const signupSlice = createSlice({
	name: "signup",
	initialState,
	reducers: {
		saveSignupResponse: (state, action) => {
			state.signupResponse = action.payload;
		},
		resetSignupResponse: (state) => {
			state.signupResponse = {};
		},
		savePinResponse: (state, action) => {
			state.updatePinResponse = action.payload;
		},
		resetPinResponse: (state) => {
			state.updatePinResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
		     .addCase(updatePinService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updatePinService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.updatePinResponse = action.payload;
			})
			.addCase(updatePinService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});


const updatePinService = createAsyncThunk(
	"auth/updatePinService",
	async (values) => {
    if(!values) return;
	const auth = values.token || null;
	if(values.token) delete values.token;

    return post('/user/update_pin', {type: 'multipart/form-data', data: values}, auth);
  }
);

export const { saveSignupResponse, resetSignupResponse } = signupSlice.actions;
export {updatePinService };

export default signupSlice.reducer;
