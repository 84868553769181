import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { f7 } from "framework7-react";
import { post } from "../../../axios/axiosClient";

export const getUserStories = createAsyncThunk("get/user/stories", async () => {
	const res = await post("/chat/get_stories", {});
	if (res?.code === 200) {
		return res?.data;
	}
	// else {
	//   f7.toast.create({ text: "Something went wrong" }).open();
	//   persistor.purge();
	//   localStorage.clear();
	//   db.delete()
	//     .then(() => f7.toast.create({ text: "Please SignIn" }).open())
	//     .finally(() =>
	//       f7.views.main.router.navigate("/login/", { reloadAll: true })
	//     );
	// }
});

export const postStory = createAsyncThunk(
  "post/user/story",
  async ({ file, thumbnail, type, identifier, message, color }) => {
    let formData = new FormData();
    formData.append("type", type);
    formData.append("file", file);
    formData.append("identifier", identifier);
    formData.append("thumbnail", thumbnail);
    if (message) {
      formData.append("message", message);
    }
    if (color) {
      formData.append("color", color);
    }
    const res = await post("chat/uploadstory", {
      type: "application/x-www-form-urlencoded",
      data: formData,
    });
    if (res?.data) {
			f7.tab.show("#userStoriesTab");
		}
    f7.toast.close();
    return res?.data;
  }
);

const userStories = createSlice({
  name: "userStories",
  initialState: { stories: [], isPosting: false, shareStoryURL: "" },
  reducers: {
    updateIspostingStatus: (state, action) => {
      return {
        ...state,
        isPosting: action.payload,
      };
    },
    updateSingleStoryStatus: (state, action) => {
      return {
        ...state,
        stories: state.stories.map((story) => {
          if (story?.id === action.payload) {
            return {
              ...story,
              is_read: 1,
            };
          } else {
            return story;
          }
        }),
      };
    },
    deleteStoryByID: (state, action) => {
      const { id } = action.payload;
      const filteredStories = state.stories.filter((story) => story?.id !== id);
      return {
        ...state,
        stories: filteredStories,
      };
    },
    updateShareURL: (state, action) => {
      const { id } = action.payload;
      let storyURL = "";
      state?.stories?.forEach((story) => {
        if (story?.id === id) {
          storyURL = story.file_url;
        }
      });
      return {
        ...state,
        shareStoryURL: storyURL,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserStories.fulfilled, (state, action) => {
      return {
        ...state,
        stories: action.payload,
        isPosting: false,
      };
    });
    builder.addCase(postStory.pending, (state, action) => {
      return {
        ...state,
        isPosting: true,
      };
    });
    builder.addCase(postStory.fulfilled, (state, action) => {
      return {
        ...state,
        stories: [action?.payload, ...state.stories],
        isPosting: false,
      };
    });
  },
});

export const {
  updateIspostingStatus,
  updateSingleStoryStatus,
  updateShareURL,
  deleteStoryByID,
} = userStories.actions;

export default userStories.reducer;
