import { Block, Button, Popover } from "framework7-react";
import { Add } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  defaultProfileIcon,
  searchIcon
} from "../../../utils/icons";

function AddStickerMenu({ onStickerSelect }) {
  const { t: translate } = useTranslation();
  const demiImageURL =
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg";
  return (
    <Popover
      className="addStickerMenuOs4 min-w-[409px]  w-[auto] ml-[200px] bg-[#F1F1F1]   p-4"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "409px" }}
      arrow={false}
      backdrop={true}
    >
      <Block className="flex flex-col w-[auto] gap-4 p-0 m-0">
        <Block className="flex justify-between items-center w-[100%] m-0 p-  ">
          <p className="text-[18px] font-medium m-0">{translate("Stickers")}</p>
          <Button
            className="w-[50px] h-[50px]"
            popoverClose=".addStickerMenuOs4"
          >
             <Add size={40} color="#5D6980" className="transform rotate-45" />
          </Button>
        </Block>
        <Block className="w-[100%] m-0 py-0 gap-2 px-[1rem] border bg-[#F6F6F6] rounded-[60px] h-[45px] flex items-center ">
          <img className="w-[20px] h-[20px]" src={searchIcon} alt="" />
          <input className="w-[80%]" placeholder="Search..." type="search" />
        </Block>
        <Block className="p-0 m-0 justify-between flex flex-wrap gap-3 max-h-[200px] overflow-scroll">
          {[...new Array(7)].map((_, index) => {
            return (
              <img
                key={index}
                src={defaultProfileIcon}
                className="w-[100px] h-[100px] cursor-pointer"
                alt=""
                // onClick={() => onStickerSelect(demiImageURL)}
              />
            );
          })}
        </Block>
      </Block>
    </Popover>
  );
}

export default AddStickerMenu;
