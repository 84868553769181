import {
	Button,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	PageContent,
	Preloader,
	Searchbar,
	Subnavbar,
	Tab,
	f7,
} from "framework7-react";
import { ProfileAdd, ProfileDelete } from "iconsax-react";
import React, { useCallback, useContext, useRef, useState } from "react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useDispatch, useSelector } from "react-redux";
import user from "../../../../assets/images/placeholders/user.png";
import { STATUS_CODE } from "../../../../constants";
import { ENDPOINTS } from "../../../../constants/socket";
import {
	findFriendsService,
	resetFindFriendsResponse,
} from "../../../../redux/features/userSlice/findFriends";
import { SocketContext } from "../../../../socket";
import { useTranslation } from "react-i18next";
import { userProfileService } from "../../../../redux/features/userSlice/profile";
import MaterialIcon from "../../../../components/misc/materialIcon";
import "./style.css";
import { useUpdateEffect } from "react-use";

const FindFriends = ({ f7router }) => {
	const dispatch = useDispatch();
	const [cachedResponse, setCachedResponse] = useState([]);
	const { findFriendsResponse, isLoading } = useSelector(
		(state) => state.findFriendsSlice
	);
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const [showLoader, setShowLoader] = useState(true);
	const socket = useContext(SocketContext);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const userId = loginResponse?.data?.user_id;
	const typingTimer = useRef(null);
	const doneTypingInterval = useRef(700);
	const [requestQueue, setRequestQueue] = useState({});
	const [cancelationQueue, setCancelationQueue] = useState({});

	useUpdateEffect(() => {
		if (findFriendsResponse?.code === STATUS_CODE.SUCCESS) {
			setCachedResponse(findFriendsResponse?.data);
			setShowLoader(false);
			setRequestQueue((prev) =>
				Object.keys(prev)
					.filter((key) => prev[key] !== "complete")
					.reduce((acc, key) => ({ ...acc, [key]: prev[key] }), {})
			);
			setCancelationQueue((prev) =>
				Object.keys(prev)
					.filter((key) => prev[key] !== "complete")
					.reduce((acc, key) => ({ ...acc, [key]: prev[key] }), {})
			);
		}
	}, [findFriendsResponse]);

	const doneTyping = (value) => {
		if (value.length >= 2) {
			dispatch(
				findFriendsService({
					query: value,
					currentPage: findFriendsResponse?.extra?.currentPage,
				})
			);
		} else {
			resetFindFriendsResponse();
		}
	};

	const onSearch = (e) => {
		clearTimeout(typingTimer.current);
		setCachedResponse([]);
		setQuery(e.target.value);
		setShowLoader(true);
		typingTimer.current = setTimeout(
			() => doneTyping(e.target.value),
			doneTypingInterval.current
		);
	};

	const sendRequest = (id) => {
		setRequestQueue({ ...requestQueue, [id]: "pending" });
		socket.emit(
			ENDPOINTS.SEND_REQUEST,
			JSON.stringify({
				receiver_id: id,
				user_id: userId,
			}),
			(response) => {
				setRequestQueue({ ...requestQueue, [id]: "complete" });
				if (response === "Friend Request sent successfully") {
					dispatch(
						findFriendsService({
							query: query,
							currentPage: findFriendsResponse?.extra?.currentPage,
						})
					);
				}
			}
		);
	};
	const cancelRequest = (reqId, id) => {
		setCancelationQueue({ ...cancelationQueue, [id]: "pending" });
		socket.emit(
			ENDPOINTS.CANCEL_FRIEND_REQUEST,
			JSON.stringify({
				request_id: reqId,
			}),
			(response) => {
				setCancelationQueue({ ...cancelationQueue, [id]: "complete" });
				if (response?.status) {
					dispatch(
						findFriendsService({
							query: query,
							currentPage: findFriendsResponse?.extra?.currentPage,
						})
					);
				}
			}
		);
	};

	const showRequestBtn = useCallback(
		(id) => {
			console.log(
				"queue",
				showLoader,
				isLoading,
				requestQueue,
				cancelationQueue
			);
			if (!showLoader && !isLoading) return true;
			if (!showLoader && isLoading) {
				if (requestQueue[id] || cancelationQueue[id]) return false;
				else return true;
			}
		},
		[showLoader, isLoading, requestQueue, cancelationQueue]
	);

	return (
		<Tab
			id="find-friends"
			className="h-full"
			onTabShow={() => {
				if (cachedResponse.length > 0) setShowLoader(false);
			}}
			onTabHide={() => {
				setShowLoader(true);
			}}>
			<Page name="findFriends" pageContent={false}>
				{/* <HomeNavbar f7router={f7router} /> */}
				<Navbar>
					<NavLeft>
						<Link tabLink="#contacts">
							<MaterialIcon
								icon="arrow_back"
								fill
								color="--f7-chat-heading-color"
							/>
						</Link>
					</NavLeft>
					<NavTitle className="text-[--f7-heading-color] font-bold">
						{t("Invite Contacts")}
					</NavTitle>
				</Navbar>
				<Subnavbar>
					<Searchbar
						init={false}
						inline
						backdrop={false}
						disableButton={false}
						onChange={onSearch}
						onSubmit={(e) => {
							e.preventDefault();
						}}
						searchbarClear={() => {
							setQuery("");
							resetFindFriendsResponse();
						}}
						onClickClear={() => {
							setQuery("");
							resetFindFriendsResponse();
						}}
						inner={false}
						placeholder={t("Enter name, phone or email")}
						className="w-full"
					/>
				</Subnavbar>
				<PageContent className="extra-height">
					{/* <p className="text-[--f7-heading-color] font-bold text-[14px] mt-[120px] px-5 md:text-[16px] ">
						{t("Invite Contacts")}
					</p> */}
					{showLoader && isLoading && (
						<div className="flex flex-col justify-center items-center h-4/5">
							<Preloader color="#0e99fe" />
						</div>
					)}
					{cachedResponse?.length > 0 ? (
						<List className="search-list contact-list mt-[0px] searchbar-found">
							{cachedResponse?.map((contact, index) => (
								<ListItem
									onClick={(e) => {
										console.log(e.target.id);
										if (e.target.id === "requestBtn") return;
										dispatch(
											userProfileService({
												user_id: contact.id,
											})
										);
										f7.store.dispatch("setSelectedUserId", contact.id);
									}}
									key={contact.id}
									className="cursor-pointer friends-li align-center">
									<LazyLoaderWraper
										slot="media"
										src={
											!contact.hide_profile_picture
												? contact.profile_image
												: user
										}
										placeholder={user}
										height={55}
										width={55}
										alt=""
										className="rounded-full align-bottom"
										wrapperclassname="rounded-full align-bottom"
									/>
									<p
										slot="title"
										className="pt-1 text-[--f7-heading-color] font-bold text-[14px] md:text-[16px]">
										{contact.firstname}
									</p>
									{showRequestBtn(contact?.id) && (
										<Button
											slot="after"
											id="requestBtn"
											rounded
											className="h-[40px]"
											onClick={() => {
												if (contact?.id === userId) return;
												if (contact?.request_id === 0) {
													sendRequest(contact?.id);
												} else {
													cancelRequest(contact?.request_id, contact?.id);
												}
											}}>
											{contact?.request_id === 0 ? (
												<ProfileAdd size="24" color="#0e99fe" variant="Bold" />
											) : (
												<ProfileDelete
													size="24"
													color="#e05047"
													variant="Bold"
												/>
											)}
										</Button>
									)}
									{!showRequestBtn(contact?.id) && (
										<Preloader slot="after" size={24} />
									)}
								</ListItem>
							))}
						</List>
					) : null}
					<List
						strongIos
						outlineIos
						dividersIos
						className="searchbar-not-found">
						<ListItem title="No results found" />
					</List>
				</PageContent>
			</Page>
		</Tab>
	);
};

export default FindFriends;
