import RtcHelpers from "./rtc_helpers";
import Helpers from "./helpers";
import Participant from "./participant";
import Stream from "./stream";
import Track from "./track";

class RtcClient {
    get socket() {
        return this.sockett;
    }

    set socket(socket) {
        this.sockett = socket;
        this.rtc_helpers = new RtcHelpers({ socket });
        this.helpers = new Helpers();
        
        this.attachSocketListeners();
    }
    
    constructor() {
        this.have_joined = false;
        this.server_url = '';
        this.sockett = false;
        this.connections = {};
        this.participants = {};
        // w.r.t participants join order
        this.participants_ids = [];
        this.host_ids = [];

        this.stream = false;
        this.screen_stream = false;
        // audio stream to do extra stuff like detect speaking, stopspeaking even if audio is off
        this.extra_audio_stream = false;

        this.user_media_constraints = {
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true
            }
        };

        this.initial_user_media_constraints = this.user_media_constraints;

        this.display_media_constraints = {
            video: true
        };

        this.user = {
            id: ''
        };

        this.room_type = '';

        this.room = {
            id: ''
        }

        // receiving bandwidth
        this.bandwidth = {};

        // RTCConfiguration object
        this.rtc_config = {
            iceServers: [
                {
                    urls: "stun:stun.l.google.com:19302"
                }
            ]
        }

        // local video/audio
        this.is_video_on = false;
        this.is_audio_on = false;

        this.is_disconnected = false;
        this.reconnecting_timeout_id = false;
        this.reconnection_failed_timeout_id = false;
        this.http_ping_interval_id = false;
        
        // to enable multiple callbacks, see @function enableMultipleCallbacks 
        this.callbacks = [
            'onConnection',
            'onJoined',
            'onLeft',
            'onUserStream',
            'onUserVideoStream',
            'onUserAudioStream',
            'onScreenStream',
            'onPotentialUserVideo',
            'onPotentialUserAudio',
            'onPotentialScreenVideo',
            'onUserVideoStreamEnded',
            'onUserAudioStreamEnded',
            'onUserVideoTrackAdded',
            'onScreenStreamEnded',
            'onStreamReconnecting',
            'onStreamReconnected',
            'onLocalUserVideoStream',
            'onLocalUserAudioStream',
            'onScreenShared',
            'onShareScreenStopped',
            'onVideoSwitched',
            'onAudioSwitched',
            'onCallStarted',
            'onCallEnded',
            'onUsersAdded',
            'onHandRaised',
            'onHandLowered',
            'onReaction',
            'onSpeaking',
            'onStoppedSpeaking',
            'onHostAdded',
            'onHostRemoved',
            'onVideoError',
            'onAudioError',
            'onShareScreenError',
            'onRemoved',
            'onInitPeer',
            'onUserVideoSwitched',
            'onUserAudioSwitched',
            'onRecordingStarted',
            'onRecordingStopped',
            'onUserShareScreenStopped',
            'onReconnected',
            'onReconnecting',
            'onReconnectionFailed',
            'onConnectionReconnecting',
            'onConnectionReconnected',
            'onSocketReceived',
            'onSocketSent',
            'onSocketDisconnected',
            'onSocketConnected',
            'onLeaveCall',
            'onPostJoinData'
        ]

        this.enableMultipleCallbacks();
    }

    // to enable attaching same callback multiple times for ease of access 
    enableMultipleCallbacks() {
        this.callbacks.forEach((name) => {
            Object.defineProperty(
                this, 
                name, 
                {
                    enumerable: true,
                    // callback accessed, returning array of callbacks to be called
                    get: function () {
                        return this[`__${name}`]; 
                    },
                    // callback attached
                    // making array of callbacks, later on, on access will call each on of these
                    set: function (callback) {
                        callback.callback_name = name;

                        this[`__${name}`] = this[`__${name}`] || [];
                        this[`__${name}`].push(callback); 
                        
                        console.log('setter: ' + name); 
                    }
                }
            );
        });
    }

    attachSocketListeners() {
        this.removeSocketListeners();

        this.socket.onAny((event, data) => {
            console.log('socket received:', event, data);

            this._onSocketReceived({ event, data });
        });

        this.socket.on('disconnect', (reason) => {
            console.log('socket disconnected: ', reason);
        
            this._onSocketDisconnected({ reason, socket_id: this.socket.id });
        });

        this.socket.on('connect', () => {
            // all socket events emitted during disconnection are saved in sendBuffer
            // when socket is reconnected then all events saved in sendBuffer are emitted automatically
            console.log('socket connected', JSON.parse(JSON.stringify(this.socket.sendBuffer)));

            this._onSocketConnected();
        });

        this.socket.on('room:request-join', (params) => {
            this._onRequestJoin(params);
        });

        this.socket.on('room:joined', (params) => {
            this._onJoined(params);
        });

        this.socket.on('room:left', (params) => {
            this._onLeft(params);
        });

        this.socket.on('room:removed', (params) => {
            this.callCallback({
                callback: this.onRemoved,
                data: params
            });
        });

        this.socket.on('room:video-switched', (params) => {
            var { user_id } = params;
            if(user_id == this.user.id) return;

            this._onVideoSwitched(params);
        });

        this.socket.on('room:audio-switched', (params) => {
            var { user_id } = params;
            if(user_id == this.user.id) return;

            this._onAudioSwitched(params);
        });

        this.socket.on('room:can-make-connection', (params) => {
            this.initiateConnection(params);
        });

        this.socket.on('room:screen-shared', (params) => {
            this._onScreenShared(params);
        });

        this.socket.on('room:share-screen-stopped', (params) => {
            this._onShareScreenStopped(params);
        });

        this.socket.on('room:created', (params) => {
            this.callCallback({
                callback: this.onCallStarted, 
                data: params
            });
        });

        this.socket.on('room:deleted', (params) => {
            this._onRoomDeleted(params);
        });

        this.socket.on('room:users-added', (params) => {
            this.callCallback({
                callback: this.onUsersAdded, 
                data: params
            });
        });

        this.socket.on('room:hand-raised', (params) => {
            this._onHandRaised(params);
        });

        this.socket.on('room:hand-lowered', (params) => {
            this._onHandLowered(params);
        });

        this.socket.on('room:recording-started', (params) => {
            this._onRecordingStarted(params);
        });

        this.socket.on('room:recording-stopped', (params) => {
            this._onRecordingStopped(params);
        });

        this.socket.on('room:reaction', (params) => {
            this._onReaction(params);
        });

        this.socket.on('room:host-added', (params) => {
            this._onHostAdded(params);
        });

        this.socket.on('room:host-removed', (params) => {
            this._onHostRemoved(params);
        });

        this.socket.on('room:switch-user-audio', () => {
            this._onSwitchUserAudio();
        });

        this.socket.on('room:switch-user-video', () => {
            this._onSwitchUserVideo();
        });

        this.socket.on('room:stop-user-share-screen', () => {
            this._onStopUserShareScreen();
        });

        this.socket.on('room:reconnecting', (params) => {
            this._onReconnecting(params);
        });

        this.socket.on('room:reconnected', (params) => {
            this._onReconnected(params);
        });

        this.socket.on('room:connection-reconnecting', (params) => {
            this._onConnectionReconnecting && this._onConnectionReconnecting(params);
        });

        this.socket.on('room:connection-reconnected', (params) => {
            this._onConnectionReconnected && this._onConnectionReconnected(params);
        });
    }

    _onSocketReceived(params) {
        this.callCallback({
            callback: this.onSocketReceived, 
            data: params
        });
    }

    _onScreenShared(params) {
        var { user_id } = params;

        this.participants[user_id] && this.participants[user_id].onScreenShared();

        this.callCallback({
            callback: this.onScreenShared,
            data: { user_id }
        });
    }

    _onShareScreenStopped(params) {
        var { user_id } = params;

        this.participants[user_id] && this.participants[user_id].onShareScreenStopped();

        this.callCallback({
            callback: this.onShareScreenStopped,
            data: { user_id }
        });
    }

    _onRecordingStarted(params) {
        var { user_id } = params;

        this.participants[user_id] && this.participants[user_id].onRecordingStarted();
        
        this.callCallback({
            callback: this.onRecordingStarted,
            data: { user_id }
        });
    }

    _onRecordingStopped(params) {
        var { user_id } = params;

        this.participants[user_id] && this.participants[user_id].onRecordingStopped();

        this.callCallback({
            callback: this.onRecordingStopped,
            data: { user_id }
        });
    }

    _onJoined(params) {
        var { user, participant, participants, participants_ids, host_ids } = params;

        if(user.id == this.user.id) this.have_joined = true;

        this.loadParticipants({ participants });
        this.addParticipant({ participant });

        this.participants_ids = participants_ids;
        this.host_ids = host_ids;

        this.callCallback({
            callback: this.onJoined, 
            data: { ...params, ...{ user_id: user.id } }
        });
    }

    _onLeft(params) {
        var { user_id, participants_ids } = params;

        this.removeParticipant({ user_id });
        this.closeConnection({ user_id });

        this.participants_ids = participants_ids;

        if(user_id == this.user.id) {
            this.have_joined = false;
            // needed when call is ended by someone else
            this.closeConnections();
            this.closeOutgoingStreams();
            this.removeSocketListeners();
            clearInterval(this.http_ping_interval_id);
            clearTimeout(this.reconnecting_timeout_id);
            clearTimeout(this.reconnection_failed_timeout_id);
        }

        this.callCallback({ 
            callback: this.onLeft, 
            data: params
        });
    }

    _onStopUserShareScreen() {
        if(!this.screen_stream) return;
        
        this.stopShareScreen();

        this.callCallback({
            callback: this.onUserShareScreenStopped
        });
    }

    _onSwitchUserVideo() {
        this.turnOffVideo();

        this.callCallback({
            callback: this.onUserVideoSwitched
        });
    }

    _onSwitchUserAudio() {
        this.pauseAudio();

        this.callCallback({
            callback: this.onUserAudioSwitched
        });
    }

    _onSpeaking({ user_id }) {
        this.participants[user_id] && this.participants[user_id].onSpeaking();

        this.callCallback({
            callback: this.onSpeaking,
            data: { user_id }
        });
    }

    _onStoppedSpeaking({ user_id }) {
        this.participants[user_id] && this.participants[user_id].onStoppedSpeaking();

        this.callCallback({
            callback: this.onStoppedSpeaking,
            data: { user_id }
        });
    }

    _onRequestJoin(params) {
        var { user_id } = params;

        // need to close peer connection if already opened
        this.closeConnection({ user_id });
        
        // notify peer to make connection
        this.socketEmit({
            event: 'room:can-make-connection', 
            data: { to_user_id: user_id }
        });
    }

    _onRoomDeleted(params) {
        this.leaveCall({ reason: 'room-deleted' });
        
        this.callCallback({
            callback: this.onCallEnded, 
            data: params
        });
    }

    _onHandRaised(params) {
        var { user_id } = params;

        this.participants[user_id] && this.participants[user_id].onHandRaised();
        
        this.callCallback({
            callback: this.onHandRaised, 
            data: { user_id }
        });
    }

    _onHandLowered(params) {
        var { user_id } = params;
        
        this.participants[user_id] && this.participants[user_id].onHandLowered();

        this.callCallback({
            callback: this.onHandLowered, 
            data: { user_id }
        });
    }

    _onReaction(params) {
        this.callCallback({
            callback: this.onReaction,
            data: params
        });
    }

    _onHostAdded(params) {
        var { user_id } = params;

        if (this.host_ids.indexOf(user_id) == -1) {
            this.host_ids.push(user_id);
        }

        this.callCallback({
            callback: this.onHostAdded,
            data: params
        });
    }

    _onHostRemoved(params) {
        var { user_id } = params;

        var index = this.host_ids.indexOf(user_id);
        if (index > -1) {
            this.host_ids.splice(index, 1);
        }

        this.callCallback({
            callback: this.onHostRemoved,
            data: params
        });
    }

    _onUserMediaError(params) {
        var { error, constraints, stream } = params;

        console.log('_onUserMediaError', 'error', error, 'constraints', constraints);

        var is_video_required = constraints.video;
        var is_audio_required = constraints.audio;
        var video_track = stream && stream.getVideoTracks().length;
        var audio_track = stream && stream.getAudioTracks().length;

        is_video_required && !video_track && this.callCallback({ callback: this.onVideoError, data: { error } });
        is_audio_required && !audio_track && this.callCallback({ callback: this.onAudioError, data: { error } });
    }

    _onDisplayMediaError(params) {
        var { error, constraints, stream } = params;

        console.log('_onDisplayMediaError', 'error', error, 'constraints', constraints);

        var is_video_required = constraints.video;
        var video_track = stream && stream.getVideoTracks().length;

        is_video_required && !video_track && this.callCallback({ callback: this.onShareScreenError, data: { error } });
    }

    async _onSocketDisconnected(params) {
        console.log('_onSocketDisconnected', 'is_disconnected', this.is_disconnected);

        this.is_disconnected = true;
        
        clearTimeout(this.reconnecting_timeout_id);
        clearTimeout(this.reconnection_failed_timeout_id);
        this.startSendingHttpPing();

        this._onReconnecting({ user_id: this.user.id });
        this.reconnection_failed_timeout_id = setTimeout(this._onReconnectionFailed.bind(this), 60000);
        
        this.callCallback({ callback: this.onSocketDisconnected, data: params });
    }

    _onSocketConnected() {
        console.log('_onSocketConnected', 'is_disconnected', this.is_disconnected);
        
        this._onMeReconnected();
        this.stopSendingHttpPing();
        this.socketEmit({
            event: 'room:reconnected',
            data: { user: this.user }
        });

        if(this.have_joined) {
            this.getPostJoinData();
        }

        this.callCallback({ callback: this.onSocketConnected });
    }

    _onReconnecting({ user_id }) {
        this.participants[user_id] && this.participants[user_id].onReconnecting();

        this.callCallback({ 
            callback: this.onReconnecting, 
            data: { user_id } 
        });
    }

    _onReconnected({ user_id }) {
        this.participants[user_id] && this.participants[user_id].onReconnected();

        this.callCallback({ 
            callback: this.onReconnected, 
            data: { user_id } 
        });
    }

    _onMeReconnected() {
        if(!this.is_disconnected) return;

        this.is_disconnected = false;
        clearTimeout(this.reconnecting_timeout_id);
        clearTimeout(this.reconnection_failed_timeout_id);

        this._onReconnected({ user_id: this.user.id });
    }

    _onReconnectionFailed() {
        console.log('_onReconnectionFailed', 'is_disconnected', this.is_disconnected);

        if(!this.is_disconnected) return;

        this.leaveCall();
        this.callCallback({ callback: this.onReconnectionFailed });
    }

    _onHttpPingSuccess() {
        this._onMeReconnected();
    }

    connectionClosed(params) {
        var { connection } = params;

        delete this.connections[connection.id];
    }

    startSendingHttpPing() {
        console.log('startSendingHttpPing');

        this.sendHttpPing();
        clearInterval(this.http_ping_interval_id);
        this.http_ping_interval_id = setInterval(this.sendHttpPing.bind(this), 20000);
    }

    sendHttpPing() {
        var ping_url = `${this.server_url}/room/ping?room_id=${this.room.id}&user_id=${this.user.id}`;
        console.log('sendHttpPing ping_url', ping_url);
        
        return new Promise((resolve, reject) => {
            fetch(ping_url)
            .then(() => {
                this._onHttpPingSuccess();
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    stopSendingHttpPing() {
        console.log('stopSendingHttpPing');
        clearInterval(this.http_ping_interval_id);
    }

    async getPostJoinData() {
        var post_join_data = await this.socketEmit({
            event: 'room:get-post-join-data',
            return_response: true
        });

        post_join_data = post_join_data || {};
        var { participants, participants_ids, host_ids } = post_join_data;

        this.loadParticipants({ participants });
        this.participants_ids = participants_ids;
        this.host_ids = host_ids;

        this.callCallback({ 
            callback: this.onPostJoinData,
            data: post_join_data
        });

        return post_join_data;
    }

    onLocalStream(params) {
        var { stream, stream_type = 'user' } = params;

        console.log('tttttt onLocalStream', params);

        if(!stream) return;

        stream.getTracks().forEach((track) => {
            var track_type_capital = stream_type.charAt(0).toUpperCase() + stream_type.slice(1);
            var track_kind_capital = track.kind.charAt(0).toUpperCase() + track.kind.slice(1);

            // onLocalUserVideoStream, onLocalUserAudioStream, onLocalScreenVideoStream
            this.callCallback({
                callback: this[`onLocal${track_type_capital}${track_kind_capital}Stream`],
                data: { stream, track }
            });
        });

        return this.prepareStream({ stream, stream_type, user_id: this.user.id });
    }
    
    onRemoteStream(params) {
        var { stream, stream_type, user_id } = params;

        console.log('tttttt onRemoteStream', params);
        console.log('onRemoteStream', params);

        stream = this.prepareStream(params);

        //screen stream
        if(stream_type == 'screen'){
            
            this.callCallback({
                callback: this.onScreenStream, 
                data: {
                    stream, 
                    user_id
                }
            });
        }

        //camera stream
        if(stream_type == 'user'){
            this._onUserStream({ 
                stream, 
                user_id 
            });
        }

        return stream;
    }

    _onUserStream(params) {
        var { stream, user_id } = params;
        console.log('_onUserStream',params,user_id,this.user.id);
        stream = stream || {};

        if(user_id != this.user.id) {
            this.registerSpeechEvents(params);
        }

        var audio_track = stream.getAudioTracks && stream.getAudioTracks()[0];
        var video_track = stream.getVideoTracks && stream.getVideoTracks()[0];
        
        if(audio_track) {
            this.callCallback({
                callback: this.onUserAudioStream, 
                data: params
            });
        }
        console.log('_onUserStream',video_track);
        if(video_track){
            this.callCallback({
                callback: this.onUserVideoStream, 
                data: params
            });
        }
    }

    removeSocketListeners() {
        this.socket.removeAllListeners();
        this.socket.offAny();
    }

    addStream(params) {}
    
    replaceUserTrack(params) {
        var { track } = params;

        this.prepareTrack({ track, track_type: 'user', stream: this.stream, user_id: this.user.id });
        this.replaceTrack({ track, stream: this.stream });
    }

    // to access camera/mic
    getUserMedia(params = {}) {
        var { constraints, enable_errors = true } = params;

        constraints = constraints || this.user_media_constraints;

        return navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
            return stream;
        })
        .catch(error => {
            this._onUserMediaError({ error, constraints, enable_errors });
        });
    }

    getUserMediaVideo() {
        return this.getUserMedia({
            constraints: {
              video: this.user_media_constraints.video,
            }
        });
    }

    getUserMediaAudio() {
        return this.getUserMedia({
            constraints: {
                audio: this.user_media_constraints.audio || true
            }
        });
    }

    // to access screen share
    getDisplayMedia(params = {}) {
        var { constraints, enable_errors = true } = params;

        constraints = constraints || this.display_media_constraints;
        
        return navigator.mediaDevices.getDisplayMedia(constraints)
                .then(stream => {
                    return stream;
                })
                .catch(error => {
                    this._onDisplayMediaError({ error, constraints, enable_errors });
                });
    }

    getInitialMedia() {
        return this.getUserMedia({ constraints: this.initial_user_media_constraints });
    }

    // Get user stream and make connection with peer
    async initiateConnection() {
        // stream may have been fetched already
        this.stream = this.stream != false ? this.stream : await this.getInitialMedia();
        
        this.is_video_on = (this.stream && this.stream.getVideoTracks().length) ? true : false;
        this.is_audio_on = (this.stream && this.stream.getAudioTracks().length) ? true : false;
        console.log('tttttt before joined');
        this.joined();
        console.log('tttttt after joined');
        this.onLocalStream({ stream: this.stream });

        if(this.is_audio_on) this.getExtraAudioStream();

        return this.stream;
    }

    joined() {
        return this.socketEmit({ 
            event: 'room:joined',
            return_response: true,
            simple_response: true,
            data: {
                user: this.user,
                is_video_on: this.is_video_on, 
                is_audio_on: this.is_audio_on 
            }
        });
    }

    joinCall() {
        this.rtc_helpers.user = this.user;
        
        this.socketEmit({ 
            event: 'room:request-join', 
            data: { room: this.room } 
        });
    }

    leaveCall(params = {}) {
        this.socketEmit({ event: 'room:left' });
        this._onLeft({ ...params, ...{user_id: this.user.id} });

        this.callCallback({ 
            callback: this.onLeaveCall, 
            data: params
        });
    }

    endCall() {
        this.socketEmit({ event: 'room:ended' });
        this._onLeft({ user_id: this.user.id });
    }

    turnOffVideo() {
        if(!this.stream) return;
        
        this.stream.stopVideoTracks();
        this.stream.removeVideoTracks();

        this.is_video_on = false;
        this.socketEmit({ 
            event: 'room:video-switched',
            local_callback: this._onVideoSwitched,
            data: { is_video_on: false }
        });

        return true;
    }

    async turnOnVideo() {
        var stream = await this.getUserMedia({ 
            constraints: { 
                video: this.user_media_constraints.video || true 
            }
        });

        if(!stream) return;

        if(!this.stream || !this.stream.id) {
            this.stream = this.onLocalStream({ stream });
        
        }else {
            var track = stream.getVideoTracks()[0];
            this.stream.addNewTrack({ track });
        }

        this.is_video_on = true;
        this.socketEmit({ 
            event: 'room:video-switched',
            local_callback: this._onVideoSwitched,
            data: { is_video_on: true }
        });
    }

    async turnOnAudio() {
        var stream = await this.getUserMediaAudio();
        if(!stream) return;
        
        var track = stream.getAudioTracks()[0];

        if(!this.stream || !this.stream.id) {
            this.stream = this.onLocalStream({ stream });
        
        }else {
            this.stream.addNewTrack({ track });
        }

        this.is_audio_on = true;
        this.socketEmit({ 
            event: 'room:audio-switched', 
            local_callback: this._onAudioSwitched,
            data: { is_audio_on: true }
        });
        
        this.getExtraAudioStream();

        return true;
    }

    pauseAudio() {
        if(!this.stream || !this.stream.getAudioTracks().length) return;

        this.stream.pauseAudioTracks();
        this.is_audio_on = false;

        this.socketEmit({ 
            event: 'room:audio-switched', 
            local_callback: this._onAudioSwitched,
            data: { is_audio_on: false }
        });

        return true;
    }
    
    resumeAudio() {
        if(!this.stream || !this.stream.getAudioTracks().length) {
            this.turnOnAudio();
            return;
        };

        this.stream.resumeAudioTracks();
        this.is_audio_on = true;

        this.socketEmit({ 
            event: 'room:audio-switched', 
            local_callback: this._onAudioSwitched,
            data: { is_audio_on: true }
        });

        return true;
    }

    async getExtraAudioStream() {
        console.log('ggggg getExtraAudioStream', this.extra_audio_stream);
        if(this.extra_audio_stream) this.extra_audio_stream.stopStream();

        var stream = await this.getUserMediaAudio();
        this.extra_audio_stream = new Stream({ stream });
        this.registerSpeechEvents({ stream: this.extra_audio_stream, user_id: this.user.id });
    }

    async switchMicrophone() {
        console.log('ggggg switchMicrophone');
        this.stream.removeAudioTracks();
        
        var stream = await this.getUserMediaAudio();
        if(!stream) return;

        stream = this.onLocalStream({ stream });

        var track = stream.getAudioTracks()[0];
        this.stream.addNewTrack({ track });

        this.replaceUserTrack({ track });
        this.getExtraAudioStream();

        console.log('ggggg switchMicrophone track', track);
    }

    _onVideoSwitched(params) {
        var { user_id, is_video_on } = params;
        
        this.participants[user_id] && this.participants[user_id].onVideoSwitched({ is_video_on });

        this.callCallback({
            callback: this.onVideoSwitched, 
            data: { user_id, is_video_on }
        });
    }

    _onAudioSwitched(params) {
        var { user_id, is_audio_on } = params;
        
        this.participants[user_id] && this.participants[user_id].onAudioSwitched({ is_audio_on });

        this.callCallback({
            callback: this.onAudioSwitched, 
            data: { user_id, is_audio_on }
        });
    }

    // gets screen stream and shares it
    async shareScreen(params) {
        var { stream } = params;

        var stream = stream || await this.getDisplayMedia();
        if(!stream) return;

        this.shareScreenStream({ stream });
    }

    // share screen with given stream
    shareScreenStream(params) {
        var { stream } = params;

        this.screen_stream = this.onLocalStream({ stream, stream_type: 'screen' });

        this.socketEmit({ event: 'room:screen-shared' });
        this.callCallback({ callback: this.onScreenShared, data: { user_id: this.user.id } });
    }

    stopShareScreen() {
        if(!this.screen_stream) return;

        this.screen_stream.stopStream();
        this.screen_stream = false;

        this.socketEmit({ event: 'room:share-screen-stopped' });
    }

    stopUserShareScreen(params) {
        this.socketEmit({ 
            event: 'room:stop-user-share-screen',
            data: params
        });
    }

    stopAllUsersShareScreen(params) {
        this.notifyOtherUsers({ 
            event: 'room:stop-user-share-screen',
            data: params
        });
    }

    switchScreen(params) {
        var { user_id } = params;
        
        this.socketEmit({ 
            event: 'room:screen-switched', 
            data: { screen_user_id: user_id }
        });
    }

    registerSpeechEvents(params) {
        var { user_id } = params;

        params.on_speaking_callback = () => {
            this._onSpeaking({ user_id });
        };

        params.on_stopped_speaking_callback = () => {
            this._onStoppedSpeaking({ user_id });
        };
        
        this.helpers.registerSpeechEvents(params);
    }

    raiseHand() {
        this.socketEmit({ 
            event: 'room:hand-raised'
        });
    }

    lowerHand() {
        this.socketEmit({ 
            event: 'room:hand-lowered'
        });
    }

    startRecording() {
        this.socketEmit({ 
            event: 'room:recording-started'
        });
    }

    stopRecording() {
        this.socketEmit({ 
            event: 'room:recording-stopped'
        });
    }

    async addHost(params) {
        var result = await this.socketEmit({ 
            event: 'room:add-host',
            data: params,
            return_response: true,
            simple_response: true
        });

        if(result) this._onHostAdded({ user_id: params.target_user_id });

        return result;
    }

    async removeHost(params) {
        var result = await this.socketEmit({ 
            event: 'room:remove-host',
            data: params,
            return_response: true,
            simple_response: true
        });

        if(result) this._onHostRemoved({ user_id: params.target_user_id });

        return result;
    }

    removeUser(params) {
        return this.socketEmit({ 
            event: 'room:remove-user',
            data: params,
            return_response: true,
            simple_response: true
        });
    }

    sendReaction(params) {
        this.socketEmit({ 
            event: 'room:reaction',
            local_callback: this._onReaction,
            data: params
        });
    }

    switchUserAudio(params) {
        this.socketEmit({ 
            event: 'room:switch-user-audio',
            data: params
        });
    }

    switchUserVideo(params) {
        this.socketEmit({ 
            event: 'room:switch-user-video',
            data: params
        });
    }

    switchAllUsersAudio(params) {
        this.notifyOtherUsers({ 
            event: 'room:switch-user-audio',
            data: params
        });
    }

    switchAllUsersVideo(params) {
        this.notifyOtherUsers({ 
            event: 'room:switch-user-video',
            data: params
        });
    }

    updateAppData(params) {
        this.socketEmit({ 
            event: 'room:update-app-data',
            data: params
        });
    }

    getRoom() {
        return this.rtc_helpers.getRoom({ room_id: this.room.id });
    }

    async getRoundTripTime() {
        var total_rtt = 0;

        for(var connection_id in this.connections) {
            var rtt = await this.connections[connection_id].getRoundTripTime();
            rtt = rtt > -1 ? rtt : 0.2;

            total_rtt += rtt;

            console.log('rtc_client getRoundTripTime', connection_id, rtt, total_rtt);
        }

        var avg_rtt = total_rtt / Object.keys(this.connections).length;
        avg_rtt = avg_rtt >= 0 ? avg_rtt : 0;

        console.log('rtc_client getRoundTripTime avg_rtt', avg_rtt);

        return avg_rtt;
    }

    // Send event to signaling server
    async socketEmit(params) {
        var { event, data = {}, return_response = false, simple_response = false, response_channel = 'callback', local_callback } = params;

        if(data.socket_response_event) {
            event = 'room:socket-response-event'
        }

        if(!event) return;

        // attaching common data
        data.room_id = this.room.id;
        data.room_type = this.room_type;
        data.user_id = this.user.id;

        console.log('socket emit', event, data);

        this.callCallback({
            callback: this.onSocketSent, 
            data: {
                event,
                data
            }
        });

        if(local_callback) {
            this.callCallback({
                callback: local_callback.bind(this),
                data
            });
        }
        
        if(!return_response) return this.socket.emit(event, data);

        if(response_channel == 'callback') {
            return await new Promise((resolve, reject) => {
                var timeout = false;
    
                this.socket.emit(event, data, (data) => {
                    clearTimeout(timeout);

                    if(simple_response) data = data && data.result;
                    resolve(data);
                });
    
                timeout = setTimeout(() => {
                    resolve();
                }, 10000);
            });
        }
        
        var socket_response_event = `${event}_${this.room.id}_${Date.now()}`;
        data.socket_response_event = socket_response_event;

        return await new Promise((resolve, reject) => {
            var timeout = false;

            this.socket.once(socket_response_event, (data) => {
                clearTimeout(timeout);
                resolve(data);
            });

            this.socket.emit(event, data);

            timeout = setTimeout(() => {
                resolve();
            }, 10000);
        });
    }

    // Calls callback
    callCallback(params) {
        var { callback, data = {} } = params;

        if(!callback) return;
        
        // use attached multiple callbacks so calling them all
        if(Array.isArray(callback)) {
            callback.forEach(call => {
                call(data);
            });
        
        }else {
            callback(data);
        }

        var first_callback = Array.isArray(callback) ? callback[0] : callback;
        var callback_name = first_callback.callback_name;

        this.onCallback && this.onCallback({ callback_name, data });
    }

    // Notify peer that he is allowed to make connection
    canMakeConnection(params) {
        var { user_id } = params;
        
        this.socketEmit({ 
            event: 'room:can-make-connection', 
            data: { to_user_id: user_id }
        });
    }

    // prepares stream, attach common things to it
    prepareStream(params) {
        var { stream, stream_type, user_id } = params;

        console.log('tttttt prepareStream', params);

        if(!stream || stream.is_prepared) return stream;

        var track_type = stream_type;

        stream = new Stream({ stream });
        stream.is_prepared = true;

        stream.getTracks().forEach(track => {
            this.prepareTrack({ track, track_type, stream, user_id });
        });

        stream.onTrackAdded = ({ track }) => {
            this.prepareTrack({ track, track_type, stream, user_id });
        }

        stream.onStreamEnded = () => {
            this.onStreamEnded({ stream });
        }

        stream.onTrackEnded = ({ track }) => {
            this.onTrackEnded({ track, stream });
        }

        return stream;
    }

    prepareTrack(params) {
        var { track, track_type, stream, user_id } = params;

        console.log('tttttt prepareTrack', params);

        if(!track.is_custom) 
            track = new Track({ track });

        track.is_prepared = true;

        track.track_info = {
            type: track_type,
            kind: track.kind,
            name: this.makeTrackName({ user_id, track_type, track_kind: track.kind }),
            user_id,
            stream_id: stream.id,
            is_remote: (user_id != this.user.id)
        }
    }

    makeTrackName({ user_id, track_type, track_kind }) {
        return `${user_id}_${track_type}_${track_kind}`;
    }

    onTrackEnded(params) {
        var { track } = params;

        console.log('tttttt onTrackEnded', params);

        var connection = this.connections[track.track_info.connection_id];
        connection && connection.onTrackEnded(params);

        if(!track.track_info.is_remote) this.onLocalTrackEnded(params);
    }

    onLocalTrackEnded({ track }) {
        console.log('tttttt onLocalTrackEnded', track);

        // if audio source is removed then we need to get new source and no need to close connection
        if(track.kind == 'audio' && !track.is_stopped) {
            this.switchMicrophone();
            return;
        }

        var connection = this.connections[track.track_info.connection_id];
        connection && connection.close();
    }

    onStreamEnded(params) {
        var { stream } = params;

        console.log('tttttt onStreamEnded', params);

        if(stream.getStreamInfo().is_remote) 
            this.onRemoteStreamEnded({ stream });
        else 
            this.onLocalStreamEnded({ stream });
    }

    onRemoteStreamEnded(params) {
        var { stream } = params;

        console.log('tttttt onRemoteStreamEnded', params);

        var stream_info = stream.getStreamInfo();

        var user_id = stream_info.user_id;
        var stream_type = stream_info.type;
        var stream_kind = stream_info.kind;


        if(stream_type == 'user'){
            var stream_kind_capital = stream_kind.charAt(0).toUpperCase() + stream_kind.slice(1);

            // onUserVideoStreamEnded, onUserAudioStreamEnded
            var callback_name = `onUser${stream_kind_capital}StreamEnded`;

            this.callCallback({
                callback: this[callback_name],
                data: { stream, user_id }
            });
        
        }else if(stream_type == 'screen'){
            this.callCallback({
                callback: this.onScreenStreamEnded, 
                data: { stream, user_id }
            });
        }
    }

    onLocalStreamEnded(params) {
        var { stream } = params;

        console.log('tttttt onLocalStreamEnded', params);

        var stream_info = stream.getStreamInfo();
        var stream_type = stream_info.type;

        if(stream_type == 'screen') {
            this.stopShareScreen();
        }
    }

    onTrackAdded(params) {
        var { track } = params;
        
        var track_type = track.track_info.type;
        var track_kind = track.track_info.kind;
        var track_type_capital = track_type.charAt(0).toUpperCase() + track_type.slice(1);
        var track_kind_capital = track_kind.charAt(0).toUpperCase() + track_kind.slice(1);

        var callback_name = `on${track_type_capital}${track_kind_capital}TrackAdded`;

        // onUserVideoTrackAdded, onUserAudioTrackAdded, onScreenVideoTrackAdded
        this.callCallback({
            callback: this[callback_name],
            data: { track }
        });
    }

    addParticipant(params) {
        var { participant } = params;
        
        if(!participant) return;

        participant.user = participant.user || {};
        var user = participant.user;

        if(user.is_media_server) return;

        this.participants[user.id] = new Participant({ participant });
    }

    removeParticipant(params) {
        var { user_id } = params;
        
        delete this.participants[user_id];
    }

    loadParticipants(params) {
        var { participants } = params;

        Object.keys(participants).forEach((user_id) => {
            if(this.participants[user_id]) {
                
                this.participants[user_id].loadParticipant({ participant: participants[user_id] });

                return;
            }

            this.addParticipant({ participant: participants[user_id] });
        });
    }

    notifyTheseUsers(params) {
        var { event, user_ids, data = {} } = params;

        data.event = event;
        data.user_ids = user_ids;

        this.socketEmit({ 
            event: 'room:notify-these-users',
            data
        });
    }

    notifyAllUsers(params) {
        var { event, data = {} } = params;

        data.event = event;

        this.socketEmit({ 
            event: 'room:notify-all-users',
            data
        });
    }

    notifyOtherUsers(params) {
        var { event, data = {} } = params;

        data.event = event;

        this.socketEmit({ 
            event: 'room:notify-other-users',
            data
        });
    }

    // closes all peer connections
    closeConnections() {
        for (var connection_id in this.connections) {
            this.connections[connection_id] && this.connections[connection_id].close();
        }
    }

    closeOutgoingStreams() {
        console.log('closeOutgoingStreams',this.stream, typeof this.stream);
        if(this.stream && this.stream.id) {
            this.stream.stopStream();
        }

        if(this.screen_stream && this.screen_stream.id) {
            this.screen_stream.stopStream();
        }

        if(this.extra_audio_stream) {
            this.extra_audio_stream.stopStream();
        }
    }

    // add users to call
    addUsers(params) {
        var { users } = params;

        this.socketEmit({
            event: 'room:users-added',
            data: { users }
        })
    }
}

export default RtcClient