import React, { useCallback, useMemo, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./style.css";
import { Button, Preloader, f7 } from "framework7-react";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { useWindowSize } from "react-use";

const PdfViewer = ({ file }) => {
	const { width } = useWindowSize();
	const desktop = useMemo(() => width >= 775, [width]);
	const [PDFnumPages, setPDFNumPages] = useState(0);
	const [PDFcontainerRef, setPDFContainerRef] = useState(null);
	const [PDFcontainerHeight, setPDFContainerHeight] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	// const blob = useMemo(() => ({ url: url }), [url]);
	const data = useMemo(() => file, [file]);

	const onPdfResize = useCallback((entries) => {
		const [entry] = entries;

		if (entry) {
			setPDFContainerHeight(entry.contentRect.height);
		}
	}, []);

	useResizeObserver(PDFcontainerRef, {}, onPdfResize);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setPDFNumPages(numPages);
		setPageNumber(1);
	};

	const opt = useMemo(() => {
		return {
			cMapUrl: "/cmaps/",
			standardFontDataUrl: "/standard_fonts/",
		};
	}, []);

	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	return (
		<div className="relative" ref={setPDFContainerRef}>
			<Document
				file={data}
				onLoadSuccess={onDocumentLoadSuccess}
				options={opt}
				loading={<Preloader size={32} color="primary" />}
				onPassword={(callback) => {
					f7.dialog.prompt(
						"This Document is password protected. To view the document please enter the password",
						"Password",
						(password) => {
							callback(password);
						}
					);
				}}>
				<Page
					pageNumber={pageNumber}
					height={PDFcontainerHeight ? Math.min(PDFcontainerHeight, 600) : 600}
					{...(!desktop
						? {
							width: 320,
						  }
						: {})}
				/>
			</Document>
			<div className="page-controls flex items-center justify-around z-[10]">
				<Button
					disabled={pageNumber <= 1}
					onClick={previousPage}
					className="h-[40px]">
					<ArrowLeft2 size={22} color="#0E99FE" />
				</Button>
				<span className="text-black font-base ">
					{pageNumber || (PDFnumPages ? 1 : "--")} of {PDFnumPages || "--"}
				</span>
				<Button
					disabled={pageNumber >= PDFnumPages}
					onClick={nextPage}
					className="h-[40px]">
					<ArrowRight2 size={22} color="#0E99FE" />
				</Button>
			</div>
		</div>
	);
};

export default PdfViewer;
