import { createSlice } from "@reduxjs/toolkit";

const currentStories = createSlice({
  name: "currentStories",
  initialState: { status: false, stories: [], users: [], allStories: [] },
  reducers: {
    updateStoriesById: (state, action) => {
      const { userId, stories } = action.payload;
      if (userId && stories) {
        state.status = true;
        state.stories = stories.filter(
          (story) => Number(story?.user_id) === Number(userId)
        );
      } else {
        state.stories = [];
      }
    },
    updateAllStories: (state, action) => {
      state.allStories = action.payload;
    },
    updateStoriesUsers: (state, action) => {
      const uniqueArray = state.allStories?.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t?.user_id === obj?.id)
      );
      state.users = uniqueArray;
    },
    removeStoryByID: (state, action) => {
      const { id } = action.payload;
      const filteredStories = state.stories.filter((story) => story?.id !== id);
      return {
        ...state,
        stories: filteredStories,
      };
    },
  },
});

export const {
  updateStoriesById,
  updateAllStories,
  updateStoriesUsers,
  removeStoryByID,
} = currentStories.actions;

export default currentStories.reducer;
