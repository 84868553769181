import React, { useState, useEffect, useRef } from "react";
import { List, ListInput, ListItem, Icon } from "framework7-react";
import {
	isValidPhoneNumber,
	AsYouType,
} from "libphonenumber-js/mobile";
import "flag-icons/css/flag-icons.min.css";
import "./style.css";
import { countries } from "../../../constants/countries.json";
import timezones from "../../../constants/timezones.json";
import { ArrowDown2 } from "iconsax-react";
import { useTranslation } from "react-i18next";

const PhoneInput = (props) => {
	const { t, i18n } = useTranslation();
	const [phone, setPhone] = useState("");
	const [country, setCountry] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [phoneValidationError, setPhoneValidationError] = useState(false);
	const [validLength, setValidLength] = useState(false);
	const phoneInput = useRef(null);
	const countryFlag = useRef(null);

	useEffect(() => {
		// Fetch geolocation data and set initial country and country code
		// async function fetchGeoLocation() {
		//   try {
		//     const response = await fetch(`${PROTOCOL}//geolocation-db.com/json/`);
		//     const data = await response.json();

		//     if (response.ok) {
		//       const country = countries.find((c) => c.code === data.country_code);
		//       setCountry(data.country_code);
		//       setCountryCode(country.dial_code);
		//     } else {
		//       countryFlag.current.el.innerText = "globe";
		//     }
		//   } catch (e) {
		//     countryFlag.current.el.innerText = "globe";
		//   }
		// }
		// fetchGeoLocation();
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (timezone === "" || !timezone) {
			countryFlag.current.el.innerText = "globe";
			return;
		}

		const tz = timezones.find((t) => t.zone === timezone);

		if (!tz) {
			countryFlag.current.el.innerText = "globe";
			return;
		}

		setCountry(tz.country_code);
		const country = countries.find((c) => c.code === tz.country_code);

		if (!country) {
			countryFlag.current.el.innerText = "globe";
			return;
		}

		setCountryCode(country.dial_code);
	}, []);

	const handlePhoneChange = (e) => {
		const inputNumber = e.target.value.replace(/\D/g, "");
		const newPhone = new AsYouType(country).input(inputNumber);
		setPhone(newPhone);

		const isValid = isValidPhoneNumber(newPhone, country);
		setPhoneValidationError(!isValid && newPhone.length > 0);
	};

	const countryChanged = (e) => {
		const [selectedCountryCode, selectedDialCode] = e.target.value.split("/");
		setCountry(selectedCountryCode);
		setCountryCode(selectedDialCode);

		// Re-validate the phone number with the new country code
		const formattedPhone = new AsYouType(selectedCountryCode).input(
			phone.replace(/\D/g, "")
		);
		const isValid = isValidPhoneNumber(formattedPhone, selectedCountryCode);
		setPhoneValidationError(!isValid);
		setPhone(formattedPhone);
	};

	useEffect(() => {
		props.onChange({
			phone: phone.replace(/\D/g, ""),
			country,
			countryCode,
			validationError: phoneValidationError,
		});
	}, [phone]);

	return (
		<div>
			<ListInput
				ref={phoneInput}
				type="tel"
				name="phone"
				maxLength={10}
				autoFocus
				autoComplete="off"
				onChange={handlePhoneChange}
				placeholder={t("Your Phone Number")}
				errorMessageForce={phoneValidationError}
				className="input-field-normal-phone w-full"
				inputMode="numeric"
				onInput={(e) => {
					const value = e.target.value;
					e.target.value = value.replace(/[^0-9]/g, "");
				}}>
				<List strongIos outlineIos dividersIos slot="media">
					<ListItem
						smartSelect
						smartSelectParams={{
							pageTitle: t("Select Country"),
							searchbar: true,
							appendSearchbarNotFound: true,
							closeOnSelect: true,
							openIn: "popup",
						}}
						className="m-0 phone-smart-select">
						<div slot="media" className="flex items-center gap-2">
							<Icon
								ref={countryFlag}
								className={`f7-icons p-0 fi fi-${
									country && country.toLowerCase()
								}`}
							/>
							<p className="text-[14px] text-[--f7-chat-heading-colo]">
								{countryCode}
							</p>
							<div>
								<ArrowDown2 variant="Bold" color="#5d6980" size={22} />
							</div>
						</div>
						<select
							name="country"
							value={`${country}/${countryCode}`}
							onChange={countryChanged}>
							{countries.map((country) => (
								<option
									key={country.code}
									value={`${country.code}/${country.dial_code}`}
									data-option-icon={`fi fi-${country.code.toLowerCase()}`}
									className="flex-space-between">
									{`${country.name} (${country.dial_code})`}
								</option>
							))}
						</select>
					</ListItem>
				</List>
			</ListInput>
			{phoneValidationError && phone && (
				<p className=" ml-[40px] mt-[10px] error-text">
					{t("Please enter a valid phone number")}
				</p>
			)}
		</div>
	);
};

export default PhoneInput;
