import BandwidthHandler from "./libraries/BandwidthHandler";

class RtcHelpers {
    constructor(options) {
        this.socket = options.socket;
        this.user = options.user;

        this.bandwidth_handler = BandwidthHandler();
    }

    getRoom(params) {
        return new Promise((resolve) => {
            this.socket.emit('get-room', params, (room) => {
                resolve(room);
            });
        });
    }

    getUserRooms(params) {
        return new Promise((resolve) => {
            this.socket.emit('get-user-rooms', params, (rooms) => {
                resolve(rooms);
            });
        });
    }

    leaveAllRooms(params) {
        return new Promise((resolve) => {
            this.socket.emit('leave-all-rooms', params, () => {
                resolve();
            });
        });
    }

    deleteRoom(params) {
        this.socket.emit('delete-room', params);
    }

    saveToCache(params) {
        return new Promise((resolve) => {
            this.socket.emit('save-to-cache', params, () => {
                resolve();
            });
        });
    }

    saveToCacheTemporarily(params) {
        return this.saveToCache(params);
    }

    getFromCache(params) {
        return new Promise((resolve) => {
            this.socket.emit('get-from-cache', params, (data_json) => {
                resolve(data_json);
            });
        });
    }

    // Send event to signaling server
    socketEmit(params) {
        var { event, data = {}, return_response = false } = params;

        // attaching common data
        data.user_id = this.user.id;
        
        console.log('socket emit', event, data);

        if(!return_response)
            return this.socket.emit(event, data);

        return new Promise((resolve, reject) => {
            this.socket.emit(event, data, (data) => {
                resolve(data);
            });
        });
    }

    notifyTheseUsers(params) {
        var { event, user_ids, data = {} } = params;

        data.event = event;
        data.user_ids = user_ids;

        this.socketEmit({ event: 'notify-these-users', data });
    }
}

export default RtcHelpers