import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Tab,
	List,
	ListItem,
	PageContent,
	Preloader,
} from "framework7-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import { Chrome,  Apple, Android } from "iconsax-react";
import { LoggedDeviceService } from "../../../../../redux/features/userSlice/devices";

const LoggedDevices = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { LoggedDeviceResponse, isLoading, error } = useSelector(
		(state) => state.LoggedDeviceSlice
	);
	const handleGetLoggedDevices = () => {
		dispatch(LoggedDeviceService({}));
	};

	return (
		<Tab
			id="LoggedDevices"
			onTabShow={handleGetLoggedDevices}>
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Logged in Devices")}
				</NavTitle>
			</Navbar>
			<PageContent className="pt-0.5 flex flex-col">
				{Object.keys(LoggedDeviceResponse).length > 0 ? (
					<List className="m-[16px]">
						{LoggedDeviceResponse?.data?.map((device) => (
							<ListItem key={device.id}>
								<div className="flex">
									{device.platform === "Microsoft Windows" &&
									device.os === "Chrome" ? (
										<Chrome
											color={"#0e99fe"}
											variant="Bold"
											icon="windows"
											className="mr-[15px]"
										/>
									) : device.platform === "Apple" ? (
										<Apple
											color={"#0e99fe"}
											variant="Bold"
											icon="apple"
											className="mr-[15px]"
										/>
									) : device.platform === "Android" ? (
										<Android
											color={"#0e99fe"}
											variant="Bold"
											icon="Android"
											className="mr-[15px]"
										/>
									) : device.platform === "Microsoft Windows" &&
									  device.os === "Firefox" ? (
										<MaterialIcon
											size={24}
											icon="local_fire_department"
											color={"#0e99fe"}
											className="mr-[15px]"
										/>
									) : (
										<Chrome
											color={"#0e99fe"}
											variant="Bold"
											icon="mobile"
											className="mr-[15px]"
										/>
									)}
									{device.platform}
								</div>
							</ListItem>
						))}
					</List>
				) : isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<Preloader color="blue" size={40} />
					</div>
				) : (
					<div className="flex w-full h-full items-center justify-center">
						{t("No devices found")}
					</div>
				)}
			</PageContent>
		</Tab>
	);
};

export default LoggedDevices;
