import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	getReactionDetailResponse: {},
	isLoading: false,
	error: false,
};

export const reactionDetailSlice = createSlice({
	name: "getReactionDetail",
	initialState,
	reducers: {
		saveGetReactionDetailResponse: (state, action) => {
			state.getReactionDetailResponse = action.payload;
		},
		resetGetReactionDetailResponse: (state) => {
			state.getReactionDetailResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getReactionDetailService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getReactionDetailService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload?.data) return;
				state.getReactionDetailResponse = action.payload;

				try {
					db.reactions
						.put(action?.payload?.data, {
							message_id: action?.payload?.data?.message_id,
						})
						.then(async function (lastKey) {
							console.log("Dexie updated : ", lastKey);
						})
						.catch(function (err) {
							console.log("Dexie error while saving reactions : ", err);
						});
				} catch (error) {
					console.log("Dexie error while saving reactions : ", error);
				}
			})
			.addCase(getReactionDetailService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const getReactionDetailService = createAsyncThunk(
	"chat/getReactionDetailService",
	async (values) => {
		if (!values) return;
		
		return post("/chat/get_reactions", {
			type: "application/json",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveGetReactionDetailResponse, resetGetReactionDetailResponse } =
	reactionDetailSlice.actions;
export { getReactionDetailService };

export default reactionDetailSlice.reducer;
