import {
	Button,
	List,
	ListItem,
	NavLeft,
	NavRight,
	Navbar,
	Page,
	SkeletonBlock,
	f7,
	useStore,
} from "framework7-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LayerGroup, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import { db } from "../../../js/db";
import userimage from "../../../assets/images/placeholders/user.png";
import { useLiveQuery } from "dexie-react-hooks";
import MaterialIcon from "../../misc/materialIcon";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../../socket";

const LiveMap = () => {
	const { t } = useTranslation();
	const socket = useContext(SocketContext);
	const map = useRef(null);
	const selectedLocationMessage = useStore("selectedLocationMessage");
	const [coordinates, setCoordinates] = useState(
		selectedLocationMessage
			? selectedLocationMessage.message
					.split("...")
					.map((coord) => parseFloat(coord.trim()))
			: []
	);
	const centerCache = useRef(coordinates ? coordinates : [0, 0]);

	const profile = useLiveQuery(
		() =>
			selectedLocationMessage
				? db.users.get(parseInt(selectedLocationMessage.sender_id))
				: null,
		[selectedLocationMessage],
		null
	);

	useEffect(() => {
		if (selectedLocationMessage) {
			setCoordinates(
				selectedLocationMessage.message
					.split("...")
					.map((coord) => parseFloat(coord.trim()))
			);
			if (centerCache.current[0] === 0 && centerCache.current[1] === 0) {
				centerCache.current = selectedLocationMessage.message
					.split("...")
					.map((coord) => parseFloat(coord.trim()));
			}
		} else {
			setCoordinates([]);
			if (centerCache.current[0] === 0 && centerCache.current[1] === 0) {
				centerCache.current = [0, 0];
			}
		}
	}, [selectedLocationMessage]);

	useEffect(() => {
		socket?.on("sendlocation", (data) => {
			map.current?.setView([data.lat, data.long], 18);
			setCoordinates([data.lat, data.long]);
		});

		return () => {
			socket?.off("location");
		};
	});

	return selectedLocationMessage ? (
		<Page
			className="bg-black/80 backdrop-blur md:backdrop-blur-none"
			pageContent={false}>
			{profile && (
				<Navbar transparent className="bg-[#ffffff]/30 backdrop-blur-xl">
					<NavLeft className="ml-[10px] md:ml-[30px]">
						<LazyLoaderWraper
							src={
								profile &&
								!profile?.hide_profile_picture &&
								profile?.profile_image?.length
									? profile.profile_image
									: userimage
							}
							placeholder={userimage}
							height={45}
							width={45}
							alt=""
							className="rounded-full align-bottom"
							wrapperclassname="rounded-full align-bottom"
						/>
						<div className="flex flex-col justify-center items-start ml-[10px]">
							<span className="text-body text-2xl font-medium text-ellipsis max-w-[335px]">
								{profile && profile?.nickname
									? profile.nickname
									: profile.kalam_name ||
									  `${profile?.firstname} ${profile?.lastname}`}
							</span>
						</div>
					</NavLeft>
					<NavRight className="me-[10px] md:me-[30px]">
						<Button
							popupClose
							onClick={() =>
								f7.store.dispatch("setSelectedLocationMessage", null)
							}>
							<MaterialIcon
								icon="close"
								className="m-[10px] text-2xl cursor-pointer"
								size={24}
								weight={70}
								height={70}
								color="#A6A9AF"
							/>
						</Button>
					</NavRight>
				</Navbar>
			)}
			{coordinates?.length > 0 && (
				<MapContainer
					ref={map}
					className="w-full h-full"
					center={coordinates}
					zoom={20}
					placeholder={<SkeletonBlock effect="wave"></SkeletonBlock>}>
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={coordinates}>
						<Popup>
							<List className="m-0 cursor-pointer">
								<ListItem
									title={t("Direction")}
									onClick={() => {
										const googleMapsLink = `https://maps.google.com/?q=${coordinates[0]},${coordinates[1]}`;
										window.open(googleMapsLink, "_blank");
									}}>
									<MaterialIcon slot="after" icon="near_me" size={20} />
								</ListItem>
							</List>
						</Popup>
					</Marker>
				</MapContainer>
			)}
		</Page>
	) : (
		<Page></Page>
	);
};

export default LiveMap;
