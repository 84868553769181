import {
	Button,
	List,
	ListInput,
	ListItem,
	LoginScreenTitle,
	Page,
	Preloader,
	f7
} from "framework7-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const PinPage = () => {
	const { signupResponse, isLoading, error } = useSelector(
		(state) => state.signupSlice
	);
	const user_id = signupResponse?.extra?.user_id
	const [pin, setPin] = useState("");

	const onSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};


	return (
		<Page name="pinPage" noToolbar noNavbar noSwipeback loginScreen>
			<LoginScreenTitle>{f7.name}</LoginScreenTitle>
			<List form onSubmit={onSubmit}>
				<ListInput
					label="Pin"
					type="number"
					placeholder="Enter Your pin"
					value={pin}
					onInput={(e) => {
						setPin(e.target.value);
					}}
					clearButton
					required
				/>
				<ListItem>
					<Button
						type="submit"
						fill
						className="w-full"
						disabled={
							!pin.length || isLoading
						}>
						{isLoading ? <Preloader /> : "Done"}
					</Button>
				</ListItem>
			</List>
		</Page>
	);
};

export default PinPage;
