import { Block } from "framework7-react";
import { Gallery } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";

function MakeStory({ onTextStory, onPhotoStory }) {
  	const { t, i18n } = useTranslation();
  const MakeStoryCard = ({ image, text, onClick, onCardClick }) => {
    return (
      <div
        style={{
          background: `linear-gradient(0deg, #FFFFFF, #0E99FE)`,
        }}
        className="w-[290px] h-[444px] cursor-pointer rounded-[8px] flex justify-center items-center flex-col"
        onClick={onCardClick}
      >
        <div
          onClick={() => null}
          className="w-[70px] h-[70px] rounded-[50%] m-0 mb-[16px] bg-[#1F2634] flex justify-center items-center cursor-pointer"
        >
          {image}
        </div>
        <p className="text-[24px] font-bold leading-8 text-[#FFF] text-center">
          {text}
        </p>
      </div>
    );
  };

  return (
    <Block className="flex justify-center m-0 bg-[#2C2C2C] items-center w-[100%] min-h-[100%] flex-wrap gap-4">
      {" "}
      <MakeStoryCard
        image={<Gallery size="37" color="#FFF" variant="Bold" />}
        text={t("Create a photo story")}
        onClick={onPhotoStory}
        onCardClick={onPhotoStory}
      />
      <MakeStoryCard
        image={
          <p className="text-[33px] font-extrabold leading-10 text-white">Aa</p>
        }
        text={t("Create a text story")}
        onClick={onTextStory}
        onCardClick={onTextStory}
      />
    </Block>
  );
}

export default MakeStory;
