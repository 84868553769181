import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Panel,
	Tab,
	Tabs,
	Toolbar,
	f7
} from "framework7-react";
import React, { useContext } from "react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import FriendRequests from "../../tabs/friend-requests";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Mentions from "../../tabs/mentions";
import "./style.css";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";

const Notifications = ({ onPanelClosed }) => {
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { t, i18n } = useTranslation();
	const socket = useContext(SocketContext);

	return (
		<Panel
			id="notification_panel"
			onPanelClosed={onPanelClosed}
			containerEl="#chats"
			left
			cover
			className="w-full rounded-none z-[998]"
		>
			<Page
				name="notifications"
				className="pl-[var(--f7-toolbar-height)]"
				pageContent={false}
			>
				<Navbar className="notification-navbar">
					<NavLeft>
						<Link panelClose>
							<MaterialIcon icon="arrow_back" />
						</Link>
					</NavLeft>
					<NavTitle className="text-[--f7-heading-color] font-bold">
						{t("Notifications")}
					</NavTitle>
				</Navbar>
				<Toolbar
					tabbar
					className="mt-[60px] notification-toolbar pb-4 bg-[--f7-navbar-bg-color] notifications-toolbar">
					<Link tabLink="#friend_requests" tabLinkActive>
						{" "}
						{t("Friend Requests")}{" "}
					</Link>
					<Link tabLink="#mentions_tab" onClick={() => {
						socket?.emit(
							ENDPOINTS.CLEAR_ALL_TAGS,
							JSON.stringify({
								user_id: loginResponse?.data?.user_id,
							}),
							(response) => {
								console.log("CLEAR_ALL_TAGS", response);
								if (response) {
									f7.emit("clearMentions", response);
								}
							}
						);
					}}>{t("Mentions")}</Link>
				</Toolbar>
				<Tabs className="mt-[100px]">
					<Tab tabActive id="friend_requests">
						<FriendRequests />
					</Tab>
					<Tab id="mentions_tab">
						<Mentions />
					</Tab>
				</Tabs>
			</Page>
		</Panel>
	);
};

export default Notifications;
