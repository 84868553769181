import React from "react";

const Titlebar = () => {
    const [title, setTitle] = React.useState(document.title);
    
    React.useEffect(() => {
        const titleObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    setTitle(document.title);
                }
            });
        });
        titleObserver.observe(document.querySelector("title"), {
            childList: true,
        });
        return () => titleObserver.disconnect();
    }, []);

    return (
			<div id="titlebar">
				<div className="titlebar-inner">
					<div className="titlebar-title">
						<div className="titlebar-title-icon">
							<img src="icons/favicon.png" />
						</div>
						<h1 className="titlebar-title-text">{title}</h1>
					</div>
				</div>
			</div>
		);
};

export default Titlebar;
