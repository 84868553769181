import { createStore } from "framework7/lite-bundle";

let typingTimer;

const f7store = createStore({
	state: {
		socketConnected: false,
		socketIteration: 0,
		uploadIdentifier: null,
		uploadProgress: -1,
		selectedMessageId: null,
		selectedLocationMessage: null,
		selectedUserId: null,
		callState: null,
		typing: {},
		userStatus: {},
		callState: null,
		callChatId: null,
		callType: null,
		callDirection: null,
		isCallStarted: false,
		showCallScreen: false,
		callChatType: null,
		inCommingCall: null,
		callRoomId: null,
		unseenMessages: {},
		edit: null,
		reply: null,
		selectedMessages: [],
		chatPageMounted: null,
		savedScrollPos: {},
		AISessionId: null,
		activeConversasion: null,
		storyFile: null,
		canvas: null,
	},
	getters: {
		socketConnected({ state }) {
			return state.socketConnected;
		},
		socketIteration({ state }) {
			return state.socketIteration;
		},
		uploadIdentifier({ state }) {
			return state.uploadIdentifier;
		},
		uploadProgress({ state }) {
			return state.uploadProgress;
		},
		selectedMessageId({ state }) {
			return state.selectedMessageId;
		},
		selectedLocationMessage({ state }) {
			return state.selectedLocationMessage;
		},
		selectedUserId({ state }) {
			return state.selectedUserId;
		},
		callState({ state }) {
			return state.callState;
		},
		typing({ state }) {
			return state.typing;
		},
		userStatus({ state }) {
			return state.userStatus;
		},
		callChatId({ state }) {
			return state.callChatId;
		},
		callType({ state }) {
			return state.callType;
		},
		callDirection({ state }) {
			return state.callDirection;
		},
		showCallScreen({ state }) {
			return state.showCallScreen;
		},
		isCallStarted({ state }) {
			return state.isCallStarted;
		},
		callChatType({ state }) {
			return state.callChatType;
		},
		inCommingCall({ state }) {
			return state.inCommingCall;
		},
		callRoomId({ state }) {
			return state.callRoomId;
		},
		unseenMessages({ state }) {
			return state.unseenMessages;
		},
		edit({ state }) {
			return state.edit;
		},
		reply({ state }) {
			return state.reply;
		},
		selectedMessages({ state }) {
			return state.selectedMessages;
		},
		chatPageMounted({ state, payload }) {
			return state.chatPageMounted?.[payload] || false;
		},
		savedScrollPos({ state }) {
			return state.savedScrollPos;
		},
		AISessionId({ state }) {
			return state.AISessionId;
		},
		activeConversasion({ state }) {
			return state.activeConversasion;
		},
		storyFile({ state }) {
			return state.storyFile;
		},
		canvas({ state }) {
			return state.canvas;
		},
	},
	actions: {
		setSocketConnection({ state }, isConnected) {
			state.socketConnected = isConnected;
			if (isConnected) {
				state.socketIteration = state.socketIteration + 1;
			}
		},
		setUploadIdentifier({ state }, identifier) {
			state.uploadIdentifier = identifier;
		},
		setUploadProgress({ state }, progress) {
			state.uploadProgress = progress;
		},
		setSelectedMessageId({ state }, id) {
			state.selectedMessageId = id;
		},
		setSelectedLocationMessage({ state }, message) {
			state.selectedLocationMessage = message;
		},
		setSelectedUserId({ state }, id) {
			state.selectedUserId = id;
		},
		setCallState({ state }, callState) {
			state.callState = callState;
		},
		setTyping({ state }, { chat_id, user, user_id, type }) {
			chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
			user_id = user_id !== "posh_ai" ? parseInt(user_id) : user_id;
			if (type === "single") {
				if (!state.typing[chat_id])
					state.typing = {
						...state.typing,
						[chat_id]: { user, user_id, chat_id },
					};
			} else {
				let prevData = state.typing[chat_id] ? state.typing[chat_id] : [];
				if (prevData.findIndex((item) => item.user_id === user_id) === -1) {
					prevData.push({ user, user_id, chat_id });
					state.typing = { ...state.typing, [chat_id]: prevData };
				}
			}

			if (typingTimer) clearTimeout(typingTimer);
			typingTimer = setTimeout(() => {
				if (
					state.typing[chat_id] &&
					state.typing[chat_id]?.findIndex(
						(item) => item.user_id === user_id
					) !== -1
				) {
					if (type === "single") {
						if (state.typing[chat_id]) {
							const { [chat_id]: chatId, ...tempObj } = state.typing;
							state.typing = { ...tempObj };
						}
					} else {
						let prevData = state.typing[chat_id] ? state.typing[chat_id] : [];
						const index = prevData?.findIndex(
							(item) => item.user_id === user_id
						);
						if (index > -1) {
							prevData.splice(index, 1);
							if (prevData.length === 0) {
								const { [chat_id]: chatId, ...tempObj } = state.typing;
								state.typing = { ...tempObj };
							} else {
								state.typing = { ...state.typing, [chat_id]: prevData };
							}
						}
					}
				}
			}, 10000);
		},
		removeTyping({ state }, { chat_id, user, user_id, type }) {
			chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
			user_id = user_id !== "posh_ai" ? parseInt(user_id) : user_id;
			if (type === "single") {
				if (state.typing[chat_id]) {
					const { [chat_id]: chatId, ...tempObj } = state.typing;
					state.typing = { ...tempObj };
				}
			} else {
				let prevData = state.typing[chat_id] ? state.typing[chat_id] : [];
				const index = prevData?.findIndex((item) => item.user_id === user_id);
				if (index > -1) {
					prevData.splice(index, 1);
					if (prevData.length === 0) {
						const { [chat_id]: chatId, ...tempObj } = state.typing;
						state.typing = { ...tempObj };
					} else {
						state.typing = { ...state.typing, [chat_id]: prevData };
					}
				}
			}
		},
		setUserStatus({ state }, { user_id, status, last_online }) {
			user_id = user_id !== "posh_ai" ? parseInt(user_id) : user_id;
			state.userStatus = {
				...state.userStatus,
				[user_id]: { status, last_online },
			};
		},
		setCallState({ state }, callState) {
			state.callState = callState;
		},
		setCallChatId({ state }, callChatId) {
			console.log("setCallChatId", callChatId);
			state.callChatId = callChatId;
		},
		setCallType({ state }, callType) {
			state.callType = callType;
		},
		setCallDirection({ state }, callDirection) {
			state.callDirection = callDirection;
		},
		setShowCallScreen({ state }, showCallScreen) {
			state.showCallScreen = showCallScreen;
		},
		setIsCallStarted({ state }, isCallStarted) {
			state.isCallStarted = isCallStarted;
		},
		setCallChatType({ state }, callChatType) {
			state.callChatType = callChatType;
		},
		setInCommingCall({ state }, inCommingCall) {
			console.log("setInCommingCall", inCommingCall);
			state.inCommingCall = inCommingCall;
		},
		setCallRoomId({ state }, callRoomId) {
			console.log("setCallRoomId", callRoomId);
			state.callRoomId = callRoomId;
		},
		resetCall({ state }) {
			state.callState = null;
			state.callChatId = null;
			state.callType = null;
			state.showCallScreen = false;
			state.isCallStarted = false;
			state.callChatType = null;
			state.inCommingCall = null;
			state.callRoomId = null;
		},
		addUnseenMessages({ state }, { messageId, chatId }) {
			state.unseenMessages = {
				...state.unseenMessages,
				[chatId]: Array.isArray(state.unseenMessages[chatId])
					? [...state.unseenMessages[chatId], messageId]
					: [messageId],
			};
		},
		removeUnseenMessages({ state }, { messageId, chatId }) {
			const index = state.unseenMessages[chatId]?.findIndex(
				(id) => id === messageId
			);
			if (index > -1) {
				state.unseenMessages = {
					...state.unseenMessages,
					[chatId]: [
						...state.unseenMessages[chatId].slice(0, index),
						...state.unseenMessages[chatId].slice(index + 1),
					],
				};
			}
		},
		removeUnseenMessagesForChat({ state }, chatId) {
			const { [chatId]: chat, ...tempObj } = state.unseenMessages;
			state.unseenMessages = { ...tempObj };
		},
		resetUnseenMessages({ state }) {
			state.unseenMessages = {};
		},
		setEdit({ state }, edit) {
			state.edit = edit;
		},
		setReply({ state }, reply) {
			state.reply = reply;
		},
		addSelectedMessages({ state }, message) {
			if (
				state.selectedMessages.findIndex((item) => item.id === message.id) ===
				-1
			)
				state.selectedMessages = [...state.selectedMessages, message];
		},
		removeSelectedMessages({ state }, messageId) {
			const index = state.selectedMessages.findIndex(
				(item) => item.id === messageId
			);
			if (index > -1) {
				state.selectedMessages = [
					...state.selectedMessages.slice(0, index),
					...state.selectedMessages.slice(index + 1),
				];
			}
		},
		resetSelectedMessages({ state }) {
			state.selectedMessages = [];
		},
		setChatPageMounted({ state }, chatPageMounted) {
			state.chatPageMounted = {
				...state.chatPageMounted,
				[chatPageMounted]: true,
			};
		},
		removeChatPageMounted({ state }, chatPageMounted) {
			const { [chatPageMounted]: chat, ...tempObj } = state.chatPageMounted;
			state.chatPageMounted = { ...tempObj };
		},
		setSavedScrollPos({ state }, { chatId, pos }) {
			state.savedScrollPos = { ...state.savedScrollPos, [chatId]: pos };
		},
		removeSavedScrollPos({ state }, chatId) {
			const { [chatId]: chat, ...tempObj } = state.savedScrollPos;
			state.savedScrollPos = { ...tempObj };
		},
		setAISessionId({ state }, sessionId) {
			state.AISessionId = sessionId;
		},
		setActiveConversasion({ state }, activeConversasion) {
			state.activeConversasion = activeConversasion;
		},
		setStoryFile({ state }, file) {
			state.storyFile = file;
		},
		setCanvas({ state }, canvas) {
			state.canvas = canvas;
		},
	},
});
export default f7store;
