import { Block, Button, List, ListGroup, Popover } from "framework7-react";
import { Add, Play } from "iconsax-react";
import React, { useState } from "react";
import {
  defaultProfileIcon,
  searchIcon
} from "../../../utils/icons";
import Story from "../Story";

function AddAudioMenu() {
  const [isFYselected, setIsFYselected] = useState(false);
  return (
    <Popover
      className="addAudioMenuOs5 min-w-[409px] ml-[10%] mt-[100px] bg-[#F1F1F1] p-4"
      bgColor="rgba(128, 128, 128, 0.3)"
      verticalPosition="auto"
      style={{ width: "409px" }}
      arrow={false}
      backdrop={true}
    >
      <Block className="flex flex-col w-[auto] p-0 m-0">
        <Block className="flex justify-between items-center w-[100%] m-0 p-0 mb-2 ">
          <p className="text-[18px] font-medium m-0">Audio</p>
          <Button className="w-[50px] h-[50px]" popoverClose=".addAudioMenuOs5">
            <Add size={40} color="#5D6980" className="transform rotate-45" />
          </Button>
        </Block>
        <Block className="w-[100%] m-0 py-0 gap-2 mb-2 px-[1rem] border bg-[#F6F6F6] border-[#E0E7EC] rounded-[20px] h-[45px] flex items-center ">
          <img className="w-[20px] h-[20px]" src={searchIcon} alt="" />
          <input className="w-[80%]" placeholder="Search..." type="search" />
        </Block>
        <Block className="p-0 m-0 flex max-h-[200px] overflow-scroll">
          <Block className="flex-1 p-0 m-0">
            <p
              style={{
                color: isFYselected ? "#0E99FE" : "#5D6980",
                borderBottom: `2px solid ${
                  isFYselected ? "#0E99FE" : "#5D6980"
                }`,
              }}
              className=" text-[16px] text-center py-[0.5rem] cursor-pointer font-semibold"
              onClick={() => setIsFYselected(true)}
            >
              For you
            </p>
          </Block>
          <Block className="flex-1 p-0 m-0">
            <p
              style={{
                color: !isFYselected ? "#0E99FE" : "#5D6980",
                borderBottom: `2px solid ${
                  !isFYselected ? "#0E99FE" : "#5D6980"
                }`,
              }}
              onClick={() => setIsFYselected(false)}
              className=" text-[16px] text-center py-[0.5rem] cursor-pointer font-semibold"
            >
              Browse
            </p>
          </Block>
        </Block>
        <Block className="p-0 m-0 max-h-[200px] overflow-scroll">
          {[...new Array(6)].map((_, index) => {
            return (
              <Block key={index} className="p-0 m-0 w-full">
                <List mediaList className="storyParent">
                  <ListGroup>
                    <Story
                      title="Audio Name"
                      subTitle="Audio Description"
                      avatar={defaultProfileIcon}
                      avatarRedius="10px"
                      after={
                        <div
                          style={{
                            border: "2px solid #5D6980",
                            borderRadius: "50%",
                          }}
                          className="w-[30px] h-[30px] flex justify-center items-center"
                        >
                          <Play size="15" color="#5D6980" variant="Bold" />
                        </div>
                      }
                    />
                  </ListGroup>
                </List>
              </Block>
            );
          })}
        </Block>
      </Block>
    </Popover>
  );
}

export default AddAudioMenu;
