import { fabric } from "fabric";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDoodleColor,
  updateMode,
  updateStoryFontColor,
  updateStoryFontSize,
  updateStorytextAlign,
} from "../redux/features/storiesSlice/userStory";
import { useStore } from "framework7-react";

function useCanvasHelper() {
  const defaultContols = {
    tl: true,
    tr: true,
    bl: true,
    br: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
  };

  const imageSetup = {
    selectable: true,
    hasBorders: true,
    borderColor: "#FFF",
    cornerColor: "#FFF",
    cornerSize: 8,
    cornerStyle: "circle",
    transparentCorners: false,
    cornerStrokeColor: "#FFF",
    padding: 10,
  };
  const textSetup = {
    editable: true,
    fontWeight: 700,
    fill: "#FFF",
    selectable: true,
    width: 500,
    hasBorders: true,
    borderColor: "#FFF",
    cornerColor: "#FFF",
    cornerSize: 8,
    cornerStyle: "circle",
    transparentCorners: false,
    cornerStrokeColor: "#FFF",
    padding: 10,
    fontSize: 24,
    top: 100,
    left: 100,
  };
  const canvas = useStore("canvas");
  const [elem, setElem] = useState();
  const dispatch = useDispatch();
  const addtext = () => {
    dispatch(updateStoryFontSize("Normal"));
    dispatch(updateStoryFontColor("#FFF"));
    dispatch(updateStorytextAlign("c"));
    const txt = new fabric.IText("Type here...", textSetup);
    setElem(txt);
    txt.setControlsVisibility(defaultContols);
    canvas.add(txt);
    canvas.setActiveObject(txt);
    canvas.bringToFront(txt);
  };
  const { curserSize, doodleColor, mode, textColor, textAlign, fontSize } =
    useSelector((store) => store?.userStory);

  useEffect(() => {
    if (canvas) {
      elem?.set("fill", textColor);
      canvas.renderAll();
    }
  }, [textColor, canvas]);
  useEffect(() => {
    if (canvas) {
      elem?.set("left", textAlign === "c" ? 80 : textAlign === "l" ? 0 : 175);
      canvas.renderAll();
    }
  }, [textAlign, canvas]);
  useEffect(() => {
    if (canvas) {
      elem?.set(
        "fontSize",
        fontSize === "Normal" ? 24 : fontSize === "Small" ? 16 : 32
      );
      canvas.renderAll();
    }
  }, [fontSize, canvas]);

  const addImage = async (url) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      fabric.Image.fromURL(url, (img) => {
        img.set({
          left: 0,
          top: 0,
          scaleX: 100 / img.width,
          scaleY: 100 / img.height,
          selectable: true,
        });

        img.setControlsVisibility(defaultContols);
        img.setOptions(imageSetup);

        canvas.add(img);
      });
    };
    const response = await fetch(url);
    const imageBlob = await response.blob();
    reader.readAsDataURL(imageBlob);
  };

  const enableDrawingMode = () => {
    dispatch(updateMode("D"));
    dispatch(updateDoodleColor("black"));
  };
  const enableEraserMode = () => {
    dispatch(updateMode("E"));
    dispatch(updateDoodleColor("white"));
  };
  const disableDrawingMode = () => {
    dispatch(updateMode(null));
  };

  useEffect(() => {
    if (canvas) {
      if (mode) {
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = curserSize;
        canvas.freeDrawingBrush.color = doodleColor;
        canvas.on("path:created", (event) => {
          event.path.set("selectable", false);
        });
      } else {
        canvas.isDrawingMode = false;
      }
    }
  }, [mode, canvas, curserSize, doodleColor]);

  useEffect(() => {
    if (canvas) {
      canvas.on("selection:updated", (e) => {
        if (e.selected?.[0]) {
          setElem(e?.selected?.[0]);
          canvas.renderAll();
        }
      });
    }
  }, [canvas]);

  return {
    addtext,
    addImage,
    enableDrawingMode,
    disableDrawingMode,
    enableEraserMode,
    canvas
  };
}

export default useCanvasHelper;
